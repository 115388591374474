import React from 'react'
import { connect } from 'react-redux'


import OrderDelayTime from '../components/UI/OrderDelayTime';

// Actions
import { storeOrderDelayTime } from '../actions/UI';


class OrderDelayContainer extends React.Component {
	render() {
		return (
			<OrderDelayTime
				value={this.props.orderDelayTime}
				onChange={this.handleOrderDelayTime}
			/>
		);
	}

	handleOrderDelayTime = (delayTime) => {
		console.log(`Selected order delay time: ${JSON.stringify(delayTime)}`);

		this.props.storeOrderDelayTime(delayTime);
	}
}

function mapStateToProps(state) {
	return {
		orderDelayTime: state.viewUI.orderDelayTime,
	}
}



export default connect(mapStateToProps, { storeOrderDelayTime })(OrderDelayContainer)
