import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Button, Grid, Row, Panel, Col } from 'react-bootstrap';

import * as actionCreators from '../../actions/auth';
import { validateEmail } from '../../utils/misc';
import FieldInput from './FieldInput';


function mapStateToProps(state) {
    return {
        registerStatusText: state.auth.registerStatusText,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}


// ----- form validators

const PASSWORD_MIN_LENGTH = 6;

const requiredValidator = (value) => value ? undefined : 'Obavezno polje';

const  emailValidator = (value) => {
    if (!validateEmail(value)) {
        return 'Neispravna email adresa';
    }
}

const passwordValidator = (value) => {
    if (value.length < PASSWORD_MIN_LENGTH) {
        return `Lozinka mora imati barem ${PASSWORD_MIN_LENGTH} znakova`;
    }
}

const repeatedPasswordValidator = (value, allValues) => {
    if (value !== allValues.password) {
        return `Lozinke moraju biti jednake`;
    }
}


class UserForm extends React.Component {

    render() {
        return (
            <Panel>
                <Panel.Heading>
                    <Panel.Title componentClass="h3">Novi korisnik</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                        <Grid fluid>
                            {this.props.registerStatusText && (
                                <Row className="alert alert-info">
                                    {this.props.registerStatusText}
                                </Row>
                            )}

                            <Row>
                                <Col>
                                    <Field
                                        name="email"
                                        type="email"
                                        label="E-mail"
                                        placeholder="Email"
                                        validate={[requiredValidator, emailValidator]}

                                        component={FieldInput}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        name="password"
                                        type="password"
                                        label="Lozinka"
                                        placeholder="Lozinka"
                                        validate={[requiredValidator, passwordValidator]}

                                        component={FieldInput}
                                    />

                                    <Field
                                        name="repeatedPassword"
                                        type="password"
                                        label="Ponovljena lozinka"
                                        placeholder="Ponovljena lozinka"
                                        validate={[requiredValidator, repeatedPasswordValidator]}

                                        component={FieldInput}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <br />
                                    <Button type="reset" id="reset_button" bsStyle="info" onClick={this.props.reset} disabled={this.props.pristine || this.props.submitting}>Poništi</Button>
                                    &nbsp;
                                    <Button type="submit" id="submit_button" bsStyle="success" disabled={this.props.pristine || this.props.submitting || this.props.invalid}>Dodaj</Button>
                                </Col>
                            </Row>

                        </Grid>
                    </form>
                </Panel.Body>
            </Panel>
        );
    }

    handleSubmit = (values) => {
        this.props.registerUser(values.email, values.password);
    }
}

UserForm.propTypes = {
    registerUser: PropTypes.func,
    registerStatusText: PropTypes.string,
};


export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'UserForm' })(UserForm))
