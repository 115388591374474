import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// UI components
import { Table, Glyphicon } from 'react-bootstrap'


const DeliveryManTable = props => {
    if (props.deliveryman === undefined) {
        return 'Loading...'
    } 

    const { deliveryman } = props;

    return (
        <Table striped bordered hover condensed>
            <thead>
                <tr>
                    <th>Ime i prezime</th>
                    <th className="text-center">Vozilo</th>
                    <th className="text-center">Bar kod</th>
                    <th className="text-center">Aktivan</th>
                </tr>
            </thead>
            <tbody>
            {_.map(deliveryman, (driver) => (
                <tr key={driver.id}>
                    <td>
                        <Link to={`/deliveryman/edit/${driver.id}`}>
                            {driver.name}
                        </Link>
                    </td>
                    <td className="text-center">{driver.vehicle}</td>
                    <td className="text-center">{driver.barcode}</td>
                    <td className="text-center">{driver.active && <Glyphicon glyph="check" />}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    )
}

DeliveryManTable.propTypes = {
    deliveryman: PropTypes.objectOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired, 
        vehicle: PropTypes.string,
        active: PropTypes.bool.isRequired,
    })).isRequired,
}

export default DeliveryManTable