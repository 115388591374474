import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

// Actions
import { createNewDeliveryMan, printDeliveryManCard } from '../actions/deliveryman'

// UI components
import { Grid, Row, Col, Panel, } from 'react-bootstrap'

// Form components
import DeliveryManForm from '../components/Form/DeliveryManForm'


class DeliveryManCreate extends Component {
    render() {
        const { createNewDeliveryMan, AgentDestinations, printDeliveryManCard } = this.props

        const initData = { 
            "active": true, 
        }
        
        return (
            <Grid>
                <Row className="justify-content-md-center">
                    <Col sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Novi dostavljač</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <DeliveryManForm 
                                    initData={initData} 
                                    submit_action={createNewDeliveryMan} 
                                    print_action={printDeliveryManCard}
                                    destinations={AgentDestinations} 
                                />
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        AgentDestinations: state.AgentLocations,
    }
}

export default (connect(mapStateToProps, { createNewDeliveryMan, printDeliveryManCard })(DeliveryManCreate));
