import { createSelector } from 'reselect'

export const getMenuItems = state => state.MenuItems;
export const getMenuGroups = state => state.MenuGroups;
export const getMenuGroupMembers = state => state.MenuItemGroupMembers

/** Find the first group this item is a memebr of and return group object. */
export const getItemGroup = (itemId, groups, groupMembers) => {
	return Object.keys(groupMembers)
		.filter((groupId) => {
			const members = groupMembers[groupId];
			return (members.indexOf(itemId) > -1);
		})
		.map(groupId => {
			const group = groups[groupId];
			if (group == null) console.error(`Unknown member group ID ${groupId}`)

			return group;
		})
		.shift();
}
