import axios from "axios";

export const CREATE_GROUP = "[API] MenuGroup POST";
export const DELETE_GROUP = "[API] MenuGroup DELETE";
export const UPDATE_GROUP = "[API] MenuGroup PUT";
export const FETCH_GROUPS = "[API] MenuGroups GET";
export const FETCH_GROUP  = "[API] MenuGroup GET";


const ROOT_URL = "/api";

export function createMenuGroup(values) {
    const request = axios.post(`${ROOT_URL}/menu/group`, values);
    return {
        type: CREATE_GROUP,
        payload: request
    }
}

export function createMenuGroupAsComplementToGroup(values, group_id) {
    const request = axios.post(`${ROOT_URL}/menu/group/complements/group/${group_id}`, values);
    return {
        type: CREATE_GROUP,
        payload: request
    }
}

export function createMenuGroupAsComplementToItem(values, item_id) {
    const request = axios.post(`${ROOT_URL}/menu/group/complements/item/${item_id}`, values);
    return {
        type: CREATE_GROUP,
        payload: request
    }
}

export function deleteMenuGroup(id) {
    const request = axios.delete(`${ROOT_URL}/menu/group/${id}`);
    return {
        type: DELETE_GROUP,
        payload: request
    }
}

export function updateMenuGroup(values) {
    const request = axios.put(`${ROOT_URL}/menu/group/${values.id}`, values);
    return {
        type: UPDATE_GROUP,
        payload: request
    }
}

export function fetchMenuGroups() {
    const request = axios.get(`${ROOT_URL}/menu/group`);
    return {
        type: FETCH_GROUPS,
        payload: request
    }
}

export function fetchMenuGroup(id) {
    const request = axios.get(`${ROOT_URL}/menu/group/${id}`);
    return {
        type: FETCH_GROUP,
        payload: request
    }
}