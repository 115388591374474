import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { getHandoffDeliveries, getHandoffDeliveryman, getHandoffSelectedLocation, getHandoffLocations } from '../selectors/handoff';
import { HandoffLocationTabs } from '../components/UI/HandoffLocationTabs';
import { storeHandoffLocation } from '../actions/UI';
import { removeDeliveryFromHandoff } from '../actions/handoff';


class HandoffContainer extends React.Component {
	componentDidMount() {
		if (this.props.selectedLocationId == null) {
			this.selectDefaultLocationId();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			const defaultLocationId = this.findDefaultLocationId();
			if (this.props.selectedLocationId == null && this.props.selectDefaultLocationId !== prevProps.selectDefaultLocationId
					|| this.props.selectedLocationId !== defaultLocationId) {
				this.selectDefaultLocationId();
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				{this.props.locations.length > 0 ? (
					<HandoffLocationTabs
						selectedLocationId={this.props.selectedLocationId}
						locations={this.props.locations}
						deliveryman={this.props.deliveryman}
						deliveries={this.props.deliveries || []}
	
						onRemoveDelivery={this.handleRemoveDelivery}
						onLocationChange={this.handleLocationChange}
					/>
				) : (
					<p className="text-center">Trenutno nema niti jednog prijavljenog dostavljača.</p>
				)}
			</React.Fragment>
		);
	}


	handleRemoveDelivery = (deliveryId) => {
		this.props.removeDeliveryFromHandoff(deliveryId);
	}

	handleLocationChange = (location) => {
		this.props.storeHandoffLocation(location.id);
	}

	selectDefaultLocationId() {
		const defaultLocationId = this.findDefaultLocationId();
		if (defaultLocationId != null) {
			this.props.storeHandoffLocation(defaultLocationId);
		}
	}


	findDefaultLocationId() {
		const defaultLocation = this.props.locations.find(location => location.id === this.props.selectedLocationId);
		if (defaultLocation) {
			return defaultLocation.id;
		}

		return this.props.locations.map(location => location.id).shift();
	}
}

function mapStateToProps(state, props) {
	return {
		selectedLocationId: getHandoffSelectedLocation(state),
		locations: getHandoffLocations(state),
		deliveryman: getHandoffDeliveryman(state),
		deliveries: getHandoffDeliveries(state),
	}
}

function mapDispatchToProps(dispatch) {
	return {
		storeHandoffLocation: (location) => dispatch(storeHandoffLocation(location)),
		removeDeliveryFromHandoff: (deliveryId) => dispatch(removeDeliveryFromHandoff(deliveryId))
	};
}



export default connect(mapStateToProps, mapDispatchToProps)(HandoffContainer)
