import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';
import moment from 'moment';
import ReactTimeout from 'react-timeout'
import { Modal, Button, Row, Col, Glyphicon, Grid } from 'react-bootstrap'

import DeliveryTicket from './UI/DeliveryTicket/index';
import * as actionCreators from '../actions/delivery';
import DeliveryCancelModal from './DeliveryCancelModal';
import { RecordingPlayer } from './common/RecordingPlayer';
import * as AppConfig from "../common/config/appConfig";

const DISPLAY_DATE_FORMAT = 'HH:mm:ss DD.MM.YYYY.';
const BUTTON_DISABLE_TIMEOUT = AppConfig.getValue('component.common.buttonDisableTimeout');

const mapStateToProps = (state) => ({
    menuItems: state.MenuItems
})

const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch)


class DeliveryTicketModal extends Component {
    state = {
        showDeliveryCancelModal: false,
        // fake printing status - we don't have real printing progress so we use timeout to prevent users' accidental multiple clicks and force it to wait a 
        isFakePrinting: false
    };

    render() {
        const { delivery, menuItems, agentName } = this.props;
        const isPrinting = !this.state.isFakePrinting;

        return (
            <React.Fragment>
                <Modal show={true} onHide={this.handleModalHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Narudžba: #{delivery.id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {delivery.canceled && (
                            <React.Fragment>
                                <Grid fluid={true}>
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <label>Otkazano:</label> {delivery.canceled_at && moment(delivery.canceled_at).format(DISPLAY_DATE_FORMAT)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <label>Razlog:</label> {delivery.canceled_reason}
                                        </Col>
                                    </Row>
                                </Grid>
                                <br />
                            </React.Fragment>
                        )}

                        {/* Time of call, Agent who answered call, Player to play the call */}
                        {delivery.call && delivery.call.answered_at && (
                            <React.Fragment>
                                <Grid fluid={true}>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <label>Agent:</label> {agentName}
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <label>Vrijeme poziva:</label> {delivery.call.incoming_at && moment(delivery.call.incoming_at).format(DISPLAY_DATE_FORMAT)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <label>Snimka:</label> <RecordingPlayer recordingUrl={delivery.call.recording_url} />
                                        </Col>
                                    </Row>
                                </Grid>
                                <br />
                            </React.Fragment>
                        )}

                        <div className="hcf-delivery__ticketContainer">
                            <DeliveryTicket MenuItems={menuItems} business_name={"FooBar"} data={delivery} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col xs={6} style={{ textAlign: "left" }}>
                                <Button onClick={this.handleDeliveryPrintButtonClick} bsStyle={'success'} disabled={!isPrinting}>
                                    PRINT
                                    {!isPrinting && (
                                        <React.Fragment>
                                            &nbsp;<Glyphicon glyph="refresh" className="glyphicon-spin" />
                                        </React.Fragment>
                                    )}
                                </Button>
                                <Button onClick={this.handleDeliveryCancelButtonClick} bsStyle={'danger'}>STORNIRAJ</Button>
                            </Col>
                            <Col xs={6}>
                                <Button onClick={this.handleModalHide}>ZATVORI</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>

                {/* modal is shown by default, it's the caller's responsibility to close it on "hide" or other business events */}
                {this.state.showDeliveryCancelModal && (
                    <DeliveryCancelModal
                        delivery={delivery}
                        onDeliveryCancel={this.handleDeliveryCancel}
                        onHide={this.handleDeliveryCancelDialogHide}
                    />
                )}
            </React.Fragment>
        )
    }

    handleDeliveryPrintButtonClick = () => {
        // set printing status
        this.togglePrintingStatus(true);

        this.props.printDeliveryTicket(this.props.delivery.id);

        // unset printing status
        console.log(this.props.setTimeout(() => {
            this.togglePrintingStatus(false)
        }, BUTTON_DISABLE_TIMEOUT));
    }

    handleDeliveryCancel = (canceledReason) => {
        // close cancel dialog on cancelation
        this.hideDeliveryCancelDialog();

        // propagate cancel event to caller
        this.props.cancelDelivery(this.props.delivery.id, canceledReason);

        // we want to close this dialog too
        // propagate "hide" event to caller
        this.props.onHide();
    }

    handleModalHide = () => {
        // propagate "hide" event to caller
        this.props.onHide();
    }

    handleDeliveryCancelButtonClick = () => {
        this.showDeliveryCancelDialog();
    }

    handleDeliveryCancelDialogHide = () => {
        this.hideDeliveryCancelDialog();
    }

    showDeliveryCancelDialog() {
        this.setState({
            showDeliveryCancelModal: true,
        });
    }

    hideDeliveryCancelDialog() {
        this.setState({
            showDeliveryCancelModal: false,
        });
    }

    togglePrintingStatus = (isPrinting) => {
        this.setState({ isFakePrinting: isPrinting })
    }
}

PropTypes.DeliveryTicketModal = {
    delivery: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        uuid: PropTypes.string.isRequired,
        created_at: PropTypes.instanceOf(Date),
        dispatched_at: PropTypes.instanceOf(Date),
        canceled_at: PropTypes.instanceOf(Date),
        agent: PropTypes.number,
        customer_address: PropTypes.isRequired,
        location: PropTypes.isRequired,
    })).isRequired,
    onHide: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactTimeout(DeliveryTicketModal));
