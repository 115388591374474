
import { push } from 'react-router-redux'
import jwtDecode from 'jwt-decode'

import {
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGIN_USER_REQUEST,
    LOGOUT_USER,
    REGISTER_USER_FAILURE,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
} from '../constants/index';

import { parseJSON } from '../utils/misc';
import { get_token, create_user } from '../utils/http_functions';


export function loginUserSuccess(token) {
    localStorage.setItem('token', token);
    return {
        type: LOGIN_USER_SUCCESS,
        payload: {
            token,
        },
    };
}


export function loginUserFailure(error) {
    localStorage.removeItem('token');
    return {
        type: LOGIN_USER_FAILURE,
        payload: {
            status: error.response.status,
            statusText: error.response.statusText,
        },
    };
}

export function loginUserRequest() {
    return {
        type: LOGIN_USER_REQUEST,
    };
}

export function logout() {
    var user_token = localStorage.getItem('token')
    var agent_key = localStorage.getItem('agent_key')
    var user_public_id = jwtDecode(user_token).public_id
    localStorage.removeItem('token')
    localStorage.removeItem('agent_key')
    return {
        type: LOGOUT_USER,
        payload: { 
            public_id: user_public_id,
            agent_key: agent_key
        }
    };
}

export function logoutAndRedirect() {
    return (dispatch) => {
        dispatch(logout());
        dispatch(push('/'));
    };
}

export function redirectToRoute(route) {
    return () => {
        dispatch(push(route));
    };
}

export function loginUser(email, password) {
    return function (dispatch) {
        dispatch(loginUserRequest());
        return get_token(email, password)
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(loginUserSuccess(response.token));
                    dispatch(push('/main'));
                } catch (e) {
                    alert(e);
                    dispatch(loginUserFailure({
                        response: {
                            status: 403,
                            statusText: 'Neispravan autorizacijski token',
                        },
                    }));
                }
            })
            .catch(error => {
                dispatch(loginUserFailure({
                    response: {
                        status: 403,
                        statusText: 'Neispravan e-mail ili lozinka',
                    },
                }));
            });
    };
}


export function registerUserRequest() {
    return {
        type: REGISTER_USER_REQUEST,
    };
}

export function registerUserSuccess() {
    return {
        type: REGISTER_USER_SUCCESS,
    };
}

export function registerUserFailure(error) {
    return {
        type: REGISTER_USER_FAILURE,
        payload: {
            status: error.response.status,
            statusText: error.response.statusText,
        },
    };
}

export function registerUser(email, password) {
    return function (dispatch) {
        dispatch(registerUserRequest());
        return create_user(email, password)
            .then(parseJSON)
            .then(response => {
                dispatch(registerUserSuccess());
            })
            .catch(error => {
                dispatch(registerUserFailure({
                    response: {
                        status: 409,
                        statusText: 'Korisnik sa tom e-mail adresom već postoji',
                    },
                }
                ));
            });
    };
}
