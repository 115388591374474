import axios from "axios"
import { UI_VIEW_STORE_DELIVERY_ADDRESS_DISTANCE, UI_VIEW_CLEAR_DELIVERY_ADDRESS_DISTANCE, UI_RESET_CUSTOMER } from "../UI";

export const CREATE_DELIVERY = "/POST/delivery";
export const WS_UPDATE_DELIVERY = "/PUSH/delivery";
export const GET_TODAYS_DELIVERIES = "/GET/delivery/today";
export const STORE_DELIVERIES = "/STORE/delivery";
export const SUBMIT_DELIVERY = "[UI] Process and enhance delivery";
export const CALCULATE_DELIVERY_ADDRESS_DISTANCE = "[UI] Calculate customer's address distance";
export const FETCH_DELIVERIES = "[API] Fetch deliveries for given date";
export const CANCEL_DELIVERY = "[API] Cancel delivery";
export const PRINT_DELIVERY_TICKET = "[API] Print delivery ticket";

const ROOT_URL = "/api"

export function createNewDelivery(values) {
    return {
        type: CREATE_DELIVERY,
        payload: values
    }
}

export function submitDelivery(values) {
    return {
        type: SUBMIT_DELIVERY,
        payload: values
    }
}

export function resetDelivery() {
    return {
        type: UI_RESET_CUSTOMER
    }
}

export function calculateDeliveryAddressDistance(address) {
    return {
        type: CALCULATE_DELIVERY_ADDRESS_DISTANCE,
        payload: address
    }
}

export function storeDeliveryAddressDistance(distance) {
    return {
        type: UI_VIEW_STORE_DELIVERY_ADDRESS_DISTANCE,
        payload: distance,
    };
}

export function clearDeliveryAddressDistance() {
    return {
        type: UI_VIEW_CLEAR_DELIVERY_ADDRESS_DISTANCE,
    };
}

export function fetchDeliveries(target_date) {
    const formated_target_date = target_date.format('YYYY/MM/DD')
    const request = axios.get(`${ROOT_URL}/delivery/date/${formated_target_date}`)
    return {
        type: FETCH_DELIVERIES,
        payload: request
    }
}

export function cancelDelivery(delivery_id, canceled_reason) {
    const request = axios.put(`${ROOT_URL}/delivery/${delivery_id}/cancel`, {
        canceled_reason,
    })
    return {
        type: CANCEL_DELIVERY,
        payload: request
    }
}

export function printDeliveryTicket(delivery_id) {
    const request = axios.put(`${ROOT_URL}/delivery/${delivery_id}/printTicket`)
    return {
        type: PRINT_DELIVERY_TICKET,
        payload: request
    }
}
