import _ from 'lodash';

import { GET_AGENT_LOCATIONS } from '../../actions/websocket';

export default function (state = [], action) {
	switch (action.type) {
		case GET_AGENT_LOCATIONS:
			const locations = action.payload.data
			return locations;
			
		default:
			return state;
	}
}
