import React from 'react';
import withPathResolver from '../../utils/withPathResolver';


// path starts with protocol prefix
const FULL_URI_MATCHER = new RegExp('^(?:[A-Za-z]+:)?//');
// path starts with relative path delimiters (. or /)
const RELATIVE_PATH_MATCHER = new RegExp('^[\./]');


// -- Component
// ----------

/**
 * Component for displaying images using HTML "img" element. Also, component provides "onError" event handler in case image cannot be loaded.
 *
 * Image path resolving:
 * * path starts with protocol prefix (eg. https://<path> or //<path>) - literal path is used
 * * path starts with absolute or relative path delimiters (eg. ./ or / or ../) - path is resolved as application URL
 * * path without relative path delimiters (eg. only filename) - path is resolved against image root
 *
 * See "withPathResolver" HOC for more info.
 */
class HcfImage extends React.Component {

  render = () => {
    return (
      <React.Fragment>
        <img
          className={this.props.className || ''}
          src={this.resolveImagePath(this.props.path)}
          title={this.props.title}
          onError={this.imageLoadErrorHandler}
        />
      </React.Fragment>
    );
  }

  imageLoadErrorHandler = () => {
    if (this.props.onError) {
      this.props.onError();
    }
  }

  resolveImagePath(imagePath) {
    // URL contains protocol delimiter and maybe schema, no need to resolve
    if (FULL_URI_MATCHER.test(imagePath)) {
      return imagePath;
    }
    // url contains path delimiters (/) or relative
    else if (RELATIVE_PATH_MATCHER.test(imagePath)) {
      return this.props.resolveApiPath(imagePath);
    }
    else {
      return this.props.resolveImagePath(imagePath);
    }
  }
}

export default withPathResolver(HcfImage);
