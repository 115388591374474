import { createSelector } from 'reselect'
import _ from 'lodash'

// Slices all groups from the state
const getMenuGroups = (state, props) => state.MenuGroups
const getSelectedCartItemUUID = (state, props) => state.cart.selectedItem
const getCartItems = (state, props) => state.cart.cartItems
const getMenuGroupGroupComplements = (state, props) => state.MenuGroupGroupComplements
const getMenuItemGroupComplements = (state, props) => state.MenuItemGroupComplements
const getCartPartGroupKey = (state, props) => state.cart.partialItems.group_key
const getCartPartItems = (state, props) => state.cart.partialItems.cartItems


// Derives list of complement groups for item and group of last/selected cartItem
const deriveComplementGroupList = (selectedCartItemUUID, cartItems, menuGroupGroupcomplements, menuItemGroupComplements, cartPartGroupKey, cartPartItems ) => {
    var selectedCartItem = null
    var lastCartItem = null

    /*
     * If multi-part assembly is in progress cartPartGroupKey is set, use this key to render only that group
     * and check if anything is in 'cartPartItems' and use last one there as reference MenuItem 
    */
    if (cartPartGroupKey) {
        var lastPartCartItemUUID = Object.keys(cartPartItems)[Object.keys(cartPartItems).length -1 ]
        var lastPartCartItem = cartPartItems[lastPartCartItemUUID]

        const groupComplementList = menuGroupGroupcomplements[cartPartGroupKey] || []
        const itemComplementList = menuItemGroupComplements[lastPartCartItem] || []

        return groupComplementList.concat(itemComplementList)
   }


    // Normal cases with no multi-part
    if (selectedCartItemUUID in cartItems) {
        // Selected item is among cart items (something valid is selected)
        //console.log('Selected item is in the cart, using this info to decide complement groups')
        var selectedCartItem = cartItems[selectedCartItemUUID]
    } else {
        // nothing is selected, we are referring to last item in cart (if any)
        // console.log('Selected item is not set or not in the cart')
        if(Object.keys(cartItems).length > 0) {
            // console.log('Cart is not empty, consider last item in cart as selectedCartItem')
            var lastCartItemUUID = Object.keys(cartItems)[Object.keys(cartItems).length -1 ]
            var lastCartItem = cartItems[lastCartItemUUID]
        } else {
            // console.log('Cart is empty, just return base groups')
            return []
        }
    }
    
    const referenceItem = selectedCartItem || lastCartItem
    const referenceGroupID = referenceItem.group_id
    const referenceItemID = referenceItem.id

    // console.log('Reference ITEM ID: ', referenceItemID)
    // console.log('Reference Group ID: ', referenceGroupID)

    const groupComplementList = menuGroupGroupcomplements[referenceGroupID] || []
    const itemComplementList = menuItemGroupComplements[referenceItemID] || []

    // console.log('DEBUG THE SELECTOR : ', groupComplementList)

    return groupComplementList.concat(itemComplementList)
}


/**
 * This selector returns list of groups to be rendered in MenuTabs while ordering
 */

const getDisplayGroups = createSelector(
    [getSelectedCartItemUUID, getCartItems, getMenuGroupGroupComplements, getMenuItemGroupComplements, getMenuGroups, getCartPartGroupKey, getCartPartItems ], 
    (selectedCartItemUUID, cartItems, menuGroupGroupcomplements, menuItemGroupComplements, menuGroups, cartPartGroupKey, cartPartItems ) => {
        const complementGroupList = deriveComplementGroupList(selectedCartItemUUID, cartItems, menuGroupGroupcomplements, menuItemGroupComplements, cartPartGroupKey, cartPartItems)
        return _.filter(
            menuGroups,
            /**
             * Group will be included in tab list in one of the following cases:
             *  - group is in complementGroupList (as complement to item in cart, or last part in multi-part cart)
             *  - group is base and no multi-part assembly is in progress
             *  - multi-part assembly is in progress from items in group with cartPartGroupKey ID
             * 
             *  AND
             *  - group is active
             */
            group => (_.includes(complementGroupList, group.id) || (group.base && !cartPartGroupKey) || (cartPartGroupKey === group.id))  && group.active
        )
    }

)

export default getDisplayGroups
