
/** Timeout promise if it's not resolved in given time. */
export function promiseTimeout(promise, time) {
  return new Promise(function (fulfill, reject) {
    let resolved = false;
    // race promise against delay
    promise
      .then(
          (resp) => {
            if (!resolved) {
              resolved = true;
              fulfill(resp);
            }
          },
          (err) => {
            if (!resolved) {
              resolved = true;
              reject(err);
            }
          });

    createDelayedPromise(time)
      .then(function () {
        if (!resolved) {
          resolved = true;
          reject();
        }
      });
  });
}

function createDelayedPromise(time) {
  return new Promise(function (fulfill) {
    setTimeout(fulfill, time);
  });
}
