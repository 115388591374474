import { 
    LOAD_NEW_CALL_DATA, 
    STORE_NEW_CALL_DATA,
    } from '../actions/events'

const EVENTS = {
    receiveCustomer: 'receiveCustomer',
    newCall: 'newCall',
    updateCall: 'updateCall',
};

export default function(state = {'currentCallData': null}, action) {
  switch (action.type) {
    case EVENTS.receiveCustomer:
        console.log('REDUCER : events.receiveCustomer >> ', action.payload )
        return action.payload;
    case EVENTS.newCall:
        console.log('REDUCER : events.newCall >> ', action.payload )
        return action.payload;
    case EVENTS.updateCall:
        console.log('REDUCER : events.updateCall >> ', action.payload )
        return action.payload;
    case LOAD_NEW_CALL_DATA:
        console.log('REDUCER : LOAD_NEW_CALL_DATA ')
        //return { 'CURRENT_CALL_DATA': action.payload };

        // Here we should do some logic and decide if we can dispatch new action
        break;
    case STORE_NEW_CALL_DATA:
        console.log('REDUCER : STORE_NEW_CALL_DATA ', action.payload)
        return { 'currentCallData': action.payload };
    default:
      return state;
  }
}