import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Row, Col, FormControl, FormGroup, ControlLabel, Form } from 'react-bootstrap';
import _ from 'lodash'

import { viewUIdeliveriesFrom } from '../../actions/UI'

// agents
import { getAgentMap } from '../../selectors/agent'

// selectors
import { getDeliveriesForUiviewDeliveryDate, getViewUIDeliveryDate } from '../../selectors/delivery'

// UI
import DeliveriesTable from '../../components/UI/DeliveriesTable'
import DatePickerUI from '../../components/UI/DatePickerUI'
import { getLocationsMap } from '../../selectors/location';
import { getDeliverymenMap } from '../../selectors/deliveryman';


const mapStateToProps = (state, props) => ({
	deliveryDate: getViewUIDeliveryDate(state),
	deliveryList: getDeliveriesForUiviewDeliveryDate(state),
	locationsMap: getLocationsMap(state),
	deliverymenMap: getDeliverymenMap(state),
	agentMap: getAgentMap(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ viewUIdeliveriesFrom }, dispatch)

class DeliveryList extends Component {
	state = {
		filteredDeliveries: [],
		deliveriesLocations: [],
		deliveriesDeliverymen: [],
	};

	componentDidMount() {
		this.setState({
			filteredDeliveries: this.filterDeliveries(),
			deliveriesLocations: this.getDeliveriesLocations(),
			deliveriesDeliverymen: this.getDeliveriesDeliverymen()
		});
	}

	componentDidUpdate(prevProps, prevState) {
		// on new deliveries
		if (this.props !== prevProps && this.props.deliveryList !== prevProps.deliveryList) {
			this.setState({
				filteredDeliveries: this.filterDeliveries(),
				deliveriesLocations: this.getDeliveriesLocations(),
				deliveriesDeliverymen: this.getDeliveriesDeliverymen(),
				selectedLocationId: undefined,
				selectedDeliverymanId: undefined,
			});
		}

		// on filter change
		if (this.state.selectedLocationId !== prevState.selectedLocationId
			|| this.state.selectedDeliverymanId !== prevState.selectedDeliverymanId) {
			this.setState({
				filteredDeliveries: this.filterDeliveries(),
			});
		}
	}

	render() {
		return (
			<React.Fragment>
				<Grid>
					<Row>
						<Col>
							<div className="hcf-containers__commandContainer">
								<Form inline onSubmit={(e) => e.preventDefault()}>
									{/* ----- date filter ----- */}
									<FormGroup>
										<ControlLabel>Narudžbe dana:&nbsp;</ControlLabel>
										<FormControl componentClass={DatePickerUI} value={this.props.deliveryDate} onChange={this.props.viewUIdeliveriesFrom} />
									</FormGroup>

									&nbsp;

									{/* ----- location filter ----- */}
									<FormGroup>
										<ControlLabel>Lokacije:&nbsp;</ControlLabel>
										<FormControl componentClass="select" value={this.state.selectedLocationId} onChange={this.handleLocationSelect}>
											<option value="">sve lokacije</option>
											{this.state.deliveriesLocations.map((location) => (
												<option key={location.id} value={location.id}>{location.name}</option>
											))}
										</FormControl>
									</FormGroup>

									&nbsp;

									{/* ----- delivermen filter ----- */}
									<FormGroup>
										<ControlLabel>Dostavljači:&nbsp;</ControlLabel>
										<FormControl componentClass="select" onChange={this.handleDeliverymanSelect} value={this.props.selectedDeliverymanId}>
											<option value="">svi dostavljači</option>
											{this.state.deliveriesDeliverymen.map((deliveryman) => (
												<option key={deliveryman.id} value={deliveryman.id}>{deliveryman.name}</option>
											))}
										</FormControl>
									</FormGroup>
								</Form>
							</div>
						</Col>
					</Row>
				</Grid>

				<div>&nbsp;</div>

				{this.state.filteredDeliveries && this.props.locationsMap && this.props.deliverymenMap && this.props.agentMap && (
					<DeliveriesTable deliveryList={this.state.filteredDeliveries} locationsMap={this.props.locationsMap} deliverymenMap={this.props.deliverymenMap} agentMap={this.props.agentMap} />
				)}
			</React.Fragment>
		);
	}

	handleLocationSelect = (event) => {
		const value = event.target.value;
		this.setState({
			selectedLocationId: value != '' ? Number.parseInt(value) : undefined
		});
	}

	handleDeliverymanSelect = (event) => {
		const value = event.target.value;
		this.setState({
			selectedDeliverymanId: value != '' ? Number.parseInt(value) : undefined
		});
	}

	/**
	 * Filter deliveries list by seleced filters.
	 * Date filter used with store selector but location and deliveryman filters applied locally on delivery list in component props.
	 */
	filterDeliveries = () => {
		return (this.props.deliveryList || [])
			// filter by location
			.filter((delivery) => {
				return this.state.selectedLocationId == null || delivery.location != null && this.state.selectedLocationId === delivery.location.id;
			})
			// filter by deliveryman
			.filter((delivery) => {
				return this.state.selectedDeliverymanId == null || this.state.selectedDeliverymanId === delivery.deliveryman;
			});
	}

	/** Get unique list of locations from available delivery list. */
	getDeliveriesLocations = () => {
		if (_.isEmpty(this.props.locationsMap)) {
			return []
		}
		return _.uniqBy(
			// convert to list of non-empty locations
			(this.props.deliveryList || []).filter((delivery) => delivery.location != null).map(delivery => this.props.locationsMap[delivery.location.id]),
			// extract uniqness property
			(location) => location.id
		);
	}

	/** Get unique list of delivermen from available delivery list. */
	getDeliveriesDeliverymen = () => {
		if (_.isEmpty(this.props.deliverymenMap)) {
			return []
		}
		return _.uniqBy(
			// convert to list of non-empty locations
			(this.props.deliveryList || []).filter((delivery) => delivery.deliveryman != null).map(delivery => this.props.deliverymenMap[delivery.deliveryman])
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryList);
