import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/auth';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { resolveToken } from './util';

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}


export function requireAuthentication(Component) {

    class AuthenticatedComponent extends React.Component {

        componentDidMount() {
            this.checkAuth();
        }

        componentUpdate(nextProps) {
            this.checkAuth(nextProps);
        }

        checkAuth(props = this.props) {
            if (!props.isAuthenticated) {
                const token = localStorage.getItem('token');
                if (!token) {
                    this.props.history.push('/home');
                } else {
                    resolveToken(token)
                    .then(res => {
                        if (res.status === 200) {
                            this.props.loginUserSuccess(token);
                        } else {
                            this.props.history.push('/home');
                        }
                    },
                    (err) => {
                        console.error('Error verifying token.');
                        this.props.history.push('/home');
                    });

                }
            }
        }

        render() {
            return (
                <div>
                    {   this.props.isAuthenticated
                        ? <Component {...this.props} />
                        : null
                    }
                </div>
            );
        }
    }

    AuthenticatedComponent.propTypes = {
        loginUserSuccess: PropTypes.func,
        isAuthenticated: PropTypes.bool,
    };

    return withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent));
}
