import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { closeModalCustomerDataLoad, fetchCustomerData } from '../../actions/UI'
import { connect } from 'react-redux'


class ModalCustomerDataLoad extends Component {
    render() {
        const { showModal, closeModal, loadNewCallData, } = this.props;
        return (
            <div>
                <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Javljanje na novi poziv</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Javili ste se na novi poziv prije nego što ste završili staru narudžbu.
                           Želite li dovršiti trenutnu, staru narudžbu ili započeti novu s podatcima 
                           trenutnog pozivatelja?

                           Odabirom "Dovrši narudžbu" podaci trenutnog poziva učitat će se naknadno
                           kada završite s trenutnom narudžbom.
                        </p>
                                    <Button onClick={closeModal}>Dovrši narudžbu</Button>
                                    <Button onClick={() => loadNewCallData()}>Nova narudžba</Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={closeModal}>Zatvori</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    showModal: state.viewUI.modalCustomerDataLoad,
})

const mapDispatchToProps = dispatch => ({
    loadNewCallData() {
        dispatch( fetchCustomerData() );
        dispatch( closeModalCustomerDataLoad() );
    },
    closeModal() {
        dispatch( closeModalCustomerDataLoad() );
    }
});



export default connect(mapStateToProps, mapDispatchToProps)(ModalCustomerDataLoad)