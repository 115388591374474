import { removeMessage, UI_FEEDBACK_ADD_MESSAGE } from "../actions/feedback"


/**
 *  When autoremovable message is added, schedule remove timeout
 */
const uiFeedbackMessage = ({dispatch}) => next => action => {
	// forward action
	next(action);

    if (action.type == UI_FEEDBACK_ADD_MESSAGE) {
		const msg = action.payload
		if (msg.timeout >= 0 && (msg.autoRemove == null || msg.autoRemove)) {
			setTimeout(() => {
				dispatch(removeMessage(msg.id));
			}, msg.timeout);
		}
    }
}


export const uiFeedbackMdl = [uiFeedbackMessage];
