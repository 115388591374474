import React from 'react'
import { connect } from 'react-redux'

import TabletOrderView from '../components/ViewsUI/TabletOrderView';


const TabletOrderViewContainer = (props) => {
	const { uiVisibleComponents } = props;

	return (
		<TabletOrderView uiVisibleComponents={uiVisibleComponents} />
	);
}

function mapStateToProps(state) {
	return {
		uiVisibleComponents: state.viewUI.uiVisibleComponents,
	}
}

export default connect(mapStateToProps)(TabletOrderViewContainer)
