// Websocket base events
export const WS_CONNECT = "[WS] Connected"
export const WS_DISCONNECT = "[WS] Disconnected"
export const WS_RECONNECT = "[WS] Re-connected"


// MenuItem
export const PUSH_MENU_ITEM = "/PUSH/menu_item"
export const GET_MENU_ITEM = "/GET/menu_item"

// MenuGroup
export const PUSH_MENU_GROUP = "/PUSH/menu_group"
export const GET_MENU_GROUP = "/GET/menu_group"

// MenuItemGroupMember
export const PUSH_MENU_ITEM_GROUP_MEMBER = "/PUSH/menu_item_group_member"
export const DELETE_MENU_ITEM_GROUP_MEMBER = "/DELETE/menu_item_group_member"
export const GET_MENU_ITEM_GROUP_MEMBER = "/GET/menu_item_group_member"

// MenuGroupGroupComplement
export const PUSH_MENU_GROUP_GROUP_COMPLEMENT = "/PUSH/menu_group_group_complement"
export const DELETE_MENU_GROUP_GROUP_COMPLEMENT = "/DELETE/menu_group_group_complement"
export const GET_MENU_GROUP_GROUP_COMPLEMENT = "/GET/menu_group_group_complement"

// MenuItemGroupComplement
export const PUSH_MENU_ITEM_GROUP_COMPLEMENT = "/PUSH/menu_item_group_complement"
export const DELETE_MENU_ITEM_GROUP_COMPLEMENT = "/DELETE/menu_item_group_complement"
export const GET_MENU_ITEM_GROUP_COMPLEMENT = "/GET/menu_item_group_complement"

// Delivery
export const PUSH_DELIVERY = "/PUSH/delivery"
export const GET_DELIVERY_DATE = "/GET/delivery/date"
export const GET_DELIVERY_TODAY = "/GET/delivery/today"

// Call
export const PUSH_CALL = "/PUSH/call"
export const GET_CALL_DATE = "/GET/call/date"
export const GET_CALL_TODAY = "/GET/call/today"

// Location
export const PUSH_LOCATION = "/PUSH/location"
export const DELETE_LOCATION = "/DELETE/location"
export const GET_LOCATION = "/GET/location"

// Checkin
export const PUSH_CHECKIN = "/PUSH/checkin"
export const GET_CHECKIN = "/GET/checkin/latest"

// Delivery Man
export const PUSH_DELIVERYMAN = "/PUSH/deliveryman"
export const DELETE_DELIVERYMAN = "/DELETE/deliveryman"
export const GET_DELIVERYMAN = "/GET/deliveryman"

// Agent Locations (derived view)
export const GET_AGENT_LOCATIONS = "/GET/location/agent"

// Maps and distances
export const GET_ADDRESS_DISTANCE = "/GET/address_distance"