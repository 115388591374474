import _ from 'lodash'
import {
    CART_ADD_ITEM,
    CART_ADD_ADDON,
    CART_REMOVE_ITEM,
    CART_REMOVE_ITEM_BY_UUID,
    CART_REMOVE_ADDON,
    CART_SELECT_ITEM,
    CART_CHECKOUT_REQUEST,
    CART_CHECKOUT_FAILURE,
    CART_ADD_PART,
    CART_ADD_LAST_PART,
    CART_ADD_PART_ADDON,
    CART_REMOVE_PART_ADDON,
    CART_REMOVE_PART,
    CART_REMOVE_LAST_PART,
    CART_RESET_PART
} from '../../actions/cart'
import { UI_RESET_CUSTOMER } from '../../actions/UI'

const initialState = {
    cartItems: {},
    selectedItem: '',
    partialItems: {
        cartItems: {},
        group_key: null,
        price_algo_max: false,
        number_of_pieces: null,
    }
}

// Generic function that will add addon to provided cartItems, used in cart and POLA/POLA cart
const addAddon = (targetUUID, addonItem, state,) => {
    /**
     * If we get valid UUID to append addon to we should do so
     * if there is no such base item with right UUID we will
     * append addon to last Item in cart
     */
    if (targetUUID in state) {
        var parentUUID = targetUUID
    } else {
        if (Object.keys(state).length === 0) {
            // Cart is empty, we have nothing to append this addon to just return state / do nothing
            return state
        }
        // Nothing is selected so addon goes to last base item we added to cart
        var parentUUID = Object.keys(state)[Object.keys(state).length - 1]
    }
    var updateItem = Object.assign({ 'addons': [] }, state[parentUUID])
    updateItem.addons.push(addonItem)
    return { ...state, [parentUUID]: updateItem }
}

// Generic function that will remove addon from provided cartItems, used in cart and POLA/POLA cart
const removeAddon = (parentUUID, addonUUID, state) => {
    var updateItem = state[parentUUID]
    const someAddonItem = _.find(updateItem.addons, function (addon) { return addon.uuid === addonUUID })
    const addon_index = updateItem.addons.indexOf(someAddonItem)
    const newUpdateItemAddonList = [
        ...updateItem.addons.slice(0, addon_index),
        ...updateItem.addons.slice(addon_index + 1)]
    const updatedItem = Object.assign(updateItem, { 'addons': newUpdateItemAddonList })
    return { ...state, [parentUUID]: updatedItem }
}


// Narucene stavke
const cartItemsReducer = (state = initialState.cartItems, action) => {
    switch (action.type) {
        case CART_ADD_ITEM:
            return { ...state, [action.payload.uuid]: action.payload };

        case CART_ADD_ADDON:
            const targetUUID = action.meta.target
            const addonItem = action.payload
            return addAddon(targetUUID, addonItem, state)
            break

        case CART_REMOVE_ITEM:
            return _.omit(state, action.payload.uuid);
            break;

        case CART_REMOVE_ITEM_BY_UUID:
            return _.omit(state, action.payload);
            break;

        case CART_REMOVE_ADDON:
            const parentUUID = action.meta.parent
            const addonUUID = action.payload.uuid
            return removeAddon(parentUUID, addonUUID, state)
            break

        default:
            return state
    }
}


const selectedItemFoo = (state = initialState.selectedItem, action) => {
    switch (action.type) {
        case CART_REMOVE_ITEM:
            if (state === action.payload.uuid) {
                return ''
            }
            return state

        case CART_REMOVE_ITEM_BY_UUID:
            if (state === action.payload) {
                return ''
            }
            return state

        case CART_SELECT_ITEM:
            if (state === action.payload) {
                return ''
            }
            return action.payload
        case CART_ADD_ITEM:
            return ''
        default:
            return state
    }
}

// POLA POLA REDUCERS START

const partialItemCartReducer = (state = initialState.partialItems.cartItems, action) => {
    switch (action.type) {
        case CART_ADD_PART:
            return { ...state, [action.payload.uuid]: action.payload }
            break

        case CART_REMOVE_PART:
            return _.omit(state, action.payload.uuid)
            break

        case CART_ADD_PART_ADDON:
            const targetUUID = action.meta.target
            const addonItem = action.payload
            return addAddon(targetUUID, addonItem, state)
            break

        case CART_REMOVE_PART_ADDON:
            const parentUUID = action.meta.parent
            const addonUUID = action.payload.uuid
            return removeAddon(parentUUID, addonUUID, state)

        case CART_REMOVE_LAST_PART:
            return initialState.partialItems.cartItems
            break

        case CART_RESET_PART:
            return initialState.partialItems.cartItems
            break

        default:
            return state
    }
}

const groupKeyReducer = (state = initialState.partialItems.group_key, action) => {
    switch (action.type) {
        case CART_ADD_PART:
            return action.payload.group_id
            break
        case CART_REMOVE_LAST_PART:
            return initialState.partialItems.group_key
            break
        case CART_RESET_PART:
            return initialState.partialItems.group_key
            break
        default:
            return state
    }
}

const priceAlgoMaxReducer = (state = initialState.partialItems.price_algo_max, action) => {
    switch (action.type) {
        case CART_ADD_PART:
            return action.payload.price_algo_max
            break
        case CART_REMOVE_LAST_PART:
            return initialState.partialItems.price_algo_max
            break
        case CART_RESET_PART:
            return initialState.partialItems.price_algo_max
            break
        default:
            return state
    }
}

const numberOfPartsReducer = (state = initialState.partialItems.number_of_pieces, action) => {
    switch (action.type) {
        case CART_ADD_PART:
            return action.payload.number_of_pieces
            break
        case CART_REMOVE_LAST_PART:
            return initialState.partialItems.number_of_pieces
            break
        case CART_RESET_PART:
            return initialState.partialItems.number_of_pieces
            break
        default:
            return state
    }
}

const partialItemsReducer = (state = initialState.partialItems, action) => ({
    cartItems: partialItemCartReducer(state.cartItems, action),
    group_key: groupKeyReducer(state.group_key, action),
    price_algo_max: priceAlgoMaxReducer(state.price_algo_max, action),
    number_of_pieces: numberOfPartsReducer(state.number_of_pieces, action)
})


// POLA POLA REDUCERS END

const cart = (state = initialState, action) => {
    switch (action.type) {
        case CART_CHECKOUT_REQUEST:
            return initialState
        case UI_RESET_CUSTOMER:
            return initialState
        case CART_CHECKOUT_FAILURE:
            return action.cart
        default:
            return {
                cartItems: cartItemsReducer(state.cartItems, action),
                selectedItem: selectedItemFoo(state.selectedItem, action),
                partialItems: partialItemsReducer(state.partialItems, action)
            }
    }
}

export default cart
