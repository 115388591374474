import React from 'react'
import _ from 'lodash'

import { Table, Radio } from "react-bootstrap";


let initialValue;

export default class OrderLocationList extends React.Component {

	render() {
		const { locations, value } = this.props;

		return (
			<React.Fragment>
				<Table responsive hover>
					<thead>
						<tr>
							<th>
								LOKACIJA
							</th>
							<th>
								Nar.
							</th>
							<th>
								Obroci
							</th>
							<th>
								km
							</th>
						</tr>
					</thead>
					<tbody>
						{locations.map((location) => (
							<tr key={`${location.id}-${this.getLocationAddressDistance(location)}`}>
								<td>
									<Radio
										name="location_id"
										value={location.id}
										checked={value != null && value.id === location.id}
										inline
										onChange={() => this.handleRadioChange(location)}>
										{location.name}
									</Radio>
								</td>
								<td>
									{this.getLocationDeliveryCount(location)}
								</td>
								<td>
									{this.getLocationOrderCount(location)}
								</td>
								<td>
									{this.getLocationAddressDistance(location)}
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}

	handleRadioChange = (location) => {
		if (this.props.onChange) {
			this.props.onChange(location);
		}
	}

	getLocationAddressDistance = (location) => {
		const distances = this.props.deliveryAddressDistance != null && this.props.deliveryAddressDistance.distances;
		const locationDistance = distances != null ? distances[location.id] : undefined;
		if (locationDistance != null || !isNaN(locationDistance)) {
			return this.formatDistance(locationDistance);
		}
		else {
			return '-';
		}
	}

	getLocationDeliveryCount = (location) => {
		const count = this.props.deliveryCountPerLocation[location.id];
		return count != null ? count : 0;
	}

	getLocationOrderCount = (location) => {
		const count = this.props.orderCountPerLocation[location.id];
		return count != null ? count : 0;
	}

	/**
	 * Distance is expressed in meters and we will express it in kilometers rounded to 10 meters.
	 * Eg. 1100 -> 1.1, 200 -> 0.2, 50 -> 0.05
	 */
	formatDistance(distance) {
		return (distance / 1000).toFixed(2);
	}
}
