import { createSelector } from 'reselect'
import _ from 'lodash'

const getCartItems = (state, props) => state.cart.cartItems
const getComboEnabled = (state, props) => state.viewUI.comboEnabled

/**
 * If COMBO is true produce derived view of cartItems
 * Else, return cartItems as is
 * 
 * All items are enhanced with
 * - 'main_part'
 * - 'total_price'
 * 
 * 'main_part' is true in all cases except for multipart items, only one part of multi-part item set is marked as 'main_part': true
 * 'total_price' is price of item for normal items. For multipart there are 2 cases.
 *      -> when multipart price is sum of parts, 'total_price' is sum of parts
 *      -> when multipart price is price of most expensive part, 'total_price' is price of that part
 * 
 * Idea is to filter all items with 'combo' and 'main_part set to true
 * Find cheapest ones and mark them as 'gratis' and 'tainted'
 * 
 * Tainted flag helps us undo 'gratis' on change, before we re-calculate gratis
 * 
 * When some multipart item is supposed to be 'gratis' in some combo option, we want to find all siblings which are not 'gratis' yet,
 * and mark them as 'gratis' and 'tainted' by this relation. This applies in situation when price calculated as sum of parts.
 */

const getCartComboView = createSelector(
    [getCartItems, getComboEnabled ], 
    (cartItems, comboEnabled ) => {
        _.map(_.filter(cartItems, 'tainted'), item => {
            // console.log('Clear tainted and gratis from item ', item)
            item.gratis = false
            item.tainted = false
        })
        if (!(comboEnabled)) {
            return cartItems
        }
        const combo_setting = 4;
        // We only consider 'combo' and 'main_part' items
        const combo_dish_count = (
            _.filter(
                _.filter(cartItems, 'combo'), 'main_part')).length
        // console.log(' Combo dish count is : ', combo_dish_count)
        const gratis_count = (combo_dish_count / combo_setting) >> 0
        // console.log(' Gratis count is : ', gratis_count)
        _.map(_.sortBy(_.filter(_.filter(cartItems, 'combo'), 'main_part'), 'total_price').slice(0,gratis_count), item => {
            item.gratis = true
            item.tainted = true
            if (item.sibling_list) {
                // console.log('Combo item with siblings : ', item)
                _.map(item.sibling_list, uuid => {
                    // console.log('Iterrating over : ', cartItems[uuid])
                    if (!(cartItems[uuid].gratis)){
                        // console.log('Sibling is *NOT* gratis ... ', cartItems[uuid])
                        cartItems[uuid].tainted = true
                        cartItems[uuid].gratis = true
                        // console.log('Marking sibling as gratis ', uuid, ' is : ', cartItems[uuid])
                    }
                })
            }
            // console.log('Setting item as gratis : ', item)
        })
        return cartItems
    }

)

export default getCartComboView