import React from 'react'

import { Table, Panel, Glyphicon, Col, Row } from 'react-bootstrap';


class DeliveryMenStats extends React.Component {
    state = {
        openedPanels: {}
    };

    render() {
        const { data, totals } = this.props;

        // TODO: data.stats is an object when empty and array when not!?!?
        const stats = Array.isArray(data.stats) ? data.stats : [];

        return (
            <div>
                <br />
                {(stats).map(item => (
                    <Panel key={item.id} eventKey={item.id} onToggle={(opened) => this.handlePanelToggle(item.id, opened)}>
                        <Panel.Heading>
                            <Panel.Title toggle>
                                <Row>
                                    <Col md={5}>
                                        {this.state.openedPanels[item.id]
                                            ? (
                                                <a><Glyphicon glyph="menu-up" /></a>
                                            ) : (
                                                <a><Glyphicon glyph="menu-down" /></a>
                                            )
                                        }

                                        &nbsp;&nbsp;
                                        <label>{item.name}</label>
                                    </Col>
                                    <Col md={7}>
                                        <Row>
                                            <Col md={4}>
                                                <label>Total:</label>&nbsp;
                                                {totals[item.id].amountTotal}
                                            </Col>
                                            <Col md={4}>
                                                <label>Total CC:</label>&nbsp;
                                                {totals[item.id].amountCcTotal}
                                            </Col>
                                            <Col md={4}>
                                                <label>Total cash:</label>&nbsp;
                                                {totals[item.id].amountCashTotal}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Collapse>
                            <Panel.Body>
                                {item.locations.map(statLocation => (
                                    <React.Fragment key={statLocation.location_id}>
                                        <h5>{statLocation.location}</h5>
                                        <Table responsive striped condensed hover>
                                            <tbody>
                                                {statLocation.addresses.map((address) => (
                                                    <tr key={address.delivery_id} className={!address.canceled ? 'warning' : ''}>
                                                        <td wid>{address.address}</td>
                                                        <td>{address.cc_payment ? <Glyphicon glyph="credit_card" /> : <Glyphicon glyph="usd" />}</td>
                                                        <td>{address.amount.toFixed(2)} Kn</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </React.Fragment>
                                ))}
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                ))}

                {stats.length === 0 && (
                    <p className="text-center">
                        <br /><br />
                        U zadanom razdoblju nije pronađen niti jedan dostavljač.
                    </p>
                )}
            </div>
        );
    }

    handlePanelToggle(key, opened) {
        this.setState({
            openedPanels: {
                ...this.state.openedPanels,
                [key]: opened
            }
        });
        console.log(key, opened);
    }
}


export default DeliveryMenStats
