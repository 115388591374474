import _ from 'lodash';

import { 
    PUSH_DELIVERYMAN,
    DELETE_DELIVERYMAN,
    GET_DELIVERYMAN } from '../../actions/websocket'


export default function (state = {}, action) {
	switch (action.type) {
        
		case GET_DELIVERYMAN:
            return _.mapKeys(action.payload.data, 'id');
            
        case DELETE_DELIVERYMAN:
            return _.omit(state, action.payload.data.id);

		case PUSH_DELIVERYMAN:
			return { ...state, [action.payload.data.id]: action.payload.data };

		default:
			return state;
	}
}
