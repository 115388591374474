import { LOAD_CURRENT_USER } from '../constants/index';
import { parseJSON } from '../utils/misc';
import { data_about_user } from '../utils/http_functions';
import { logoutAndRedirect } from './auth';

export function loadCurrentUser(data) {
    return {
        type: LOAD_CURRENT_USER,
        payload: {
            data,
        }
    };
}

export function fetchCurrentUser(token) {
    return (dispatch) => {
        data_about_user(token)
            .then(parseJSON)
            .then(response => {
                dispatch(loadCurrentUser(response.result));
            })
            .catch(error => {
                if (error.status === 401) {
                    dispatch(logoutAndRedirect(error));
                }
            });
    };
}
