import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

// UI components
import { Grid, Row, Col, Panel, Glyphicon, ButtonGroup, } from 'react-bootstrap'

import DeliveryManTable from '../components/UI/DeliveryManTable'
import { Link } from 'react-router-dom'


class DeliveryManList extends Component {
    render() {
        const { deliveryman } = this.props

        if (deliveryman === undefined ) {
            return 'Loading ...'
        }
        
        return (
            <Grid>
                <Row>
                    <Col>
                        <Panel>
                            <Panel.Heading>
                                <Row>
                                    <Col xs={6}>
                                        <Panel.Title componentClass="h3">Pregled dostavljača</Panel.Title>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <ButtonGroup bsSize="xsmall">
                                            <Link to="/deliveryman/create" className="btn btn-default">
                                                <Glyphicon glyph="plus" /> Novi dostavljač
                                            </Link>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Panel.Heading>
                            <Panel.Body>
                                <DeliveryManTable deliveryman={deliveryman} />
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        deliveryman: state.Deliveryman,
    }
}

export default (connect(mapStateToProps, null)(DeliveryManList));
