export const LOAD_NEW_CALL_DATA = "LOAD_NEW_CALL_DATA";
export const STORE_NEW_CALL_DATA = "STORE_NEW_CALL_DATA";

export function loadNewCallData() {
    console.log('loadNewCallData action!!!')
    return {
        type: LOAD_NEW_CALL_DATA,
    }
}

export function storeNewCallData(callData) {
    return {
        type: STORE_NEW_CALL_DATA,
        payload: callData
    }
}