import React from 'react'

// Containers
import CallList from '../../containers/CallList'

// UI Components
import { Grid, Row, Col, Panel } from 'react-bootstrap'

const CallRecordsView = () => {
    return (
        <Grid >
            <Row className="show-grid">
                <Col>
                   <CallList />
                </Col>                    
            </Row>
        </Grid>
    )
}

export default CallRecordsView