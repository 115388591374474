
/** Returns agent for given ID or undefined if not found. */
export function getAgent(state, deliverymanId) {
	return state.Agent[deliverymanId];
}

/** Returns map of ALL agents. */
export function getAgentMap(state) {
	return state.Agent;
}
