import React from 'react';
import { Alert } from 'react-bootstrap';
import ReactPlayer from 'react-player';

import { MESSAGE_SEVERITY } from '../../../actions/feedback';


class FeedbackMessageList extends React.Component {
    render() {
		const { messages } = this.props;

		return (
			<React.Fragment>
			{messages && messages.length > 0 && (
				<div className="hcf-feedback__messageContainer">
					{messages.map((message) => (
						<React.Fragment key={message.id}>
							<Alert bsStyle={this.getMessageStyle(message)} className="hcf-feedback__message">{message.message}</Alert>
							{message.sound != null && (
								<ReactPlayer url={message.sound} playing />
							)}
						</React.Fragment>
					))}
				</div>
			)}
			</React.Fragment>
        );
	}
	
	getMessageStyle(message) {
		if (message.severity === MESSAGE_SEVERITY.ERROR) {
			return 'danger';
		}
		else if (message.severity === MESSAGE_SEVERITY.WARNING) {
			return 'warning';
		}
		else if (message.severity === MESSAGE_SEVERITY.SUCCESS) {
			return 'success';
		}
		else {
			return 'info';
		}
	}
}

export default FeedbackMessageList;