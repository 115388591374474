import axios from "axios";

export const WS_UPDATE_HANDOFF = "[WS] /PUSH/handoff"
export const API_REMOVE_HANDOFF_DELIVERY = "[API] /PUT/handoff"


const ROOT_URL = "/api";

export function removeDeliveryFromHandoff(deliveryId) {
    const request = axios.put(`${ROOT_URL}/delivery/undo/assign/${deliveryId}`);
	return {
		type: API_REMOVE_HANDOFF_DELIVERY,
		payload: request
	}
}
