export const GET_AGENT_RECORDS = "[WS] Agents GET"
export const GET_AGENT_RECORD = "[WS] Agent GET"
export const CREATE_AGENT_RECORD = "[WS] Agent POST"
export const UPDATE_AGENT_RECORD = "[WS] Agent PUT"
export const DELETE_AGENT_RECORD = "[WS] Agent DELETE"

export const ACTIVATE_AGENT_LISTENER = "[WS] Agent START socket.io listener"
export const DEACTIVATE_AGENT_LISTENER = "[WS] Agent STOP socket.io listener"
export const GET_MY_AGENT_KEY = "[WS] Agent GET my key"


export function createAgentRecord(values) {
    return {
        type: CREATE_AGENT_RECORD,
        payload: values
    }
}


export function updateAgentRecord(values) {
    return {
        type: UPDATE_AGENT_RECORD,
        payload: values
    }
}