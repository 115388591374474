import { createSelector } from 'reselect'
import _ from 'lodash'

const getItems = (state, props) => state.MenuItems
const getUrlItemId = (_ , props) => props.match.params.menu_item_sort_id

const getEditItem = createSelector(
    [getUrlItemId, getItems], 
    (UrlItemId, items) => {
        return _.find(items, function(obj) {
            return UrlItemId != null && obj.sort_id == UrlItemId
        });
    }
)

export default getEditItem