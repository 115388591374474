import React from 'react'
import PropTypes from 'prop-types'

import { Table } from 'react-bootstrap';


const LocationBalanceStats = (props) => {
    const { data, totals } = props;

    return (
        <div>
            <br />
            <Table responsive striped condensed hover>
                <thead>
                    <tr>
                        <th>Lokacija</th>
                        <th>Skenirano</th>
                        <th>Ne skenirano</th>
                        <th>TOTAL - Gotovina</th>
                        <th>TOTAL - kreditne kartice</th>
                    </tr>
                </thead>
                <tbody>
                    {data.stats.map((location) => (
                        <tr key={location.id}>
                            <td>{location.name}</td>
                            <td>{location.scanned}</td>
                            <td>{location.not_scanned}</td>
                            <td>{location.total.toFixed(2)}</td>
                            <td>{location.total_cc.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>


            <div>
                <label>Broj narudžbi:</label> {totals.deliveryCount}
            </div>

            <div>
                <label>Ukupno:</label> {totals.amountTotal.toFixed(2)} Kn
            </div>
        </div >
    );
}

export default LocationBalanceStats
