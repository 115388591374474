import React from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form';

import { PAYMENT_TYPE } from '../../actions/UI';
// Actions
import { submitDelivery, resetDelivery, calculateDeliveryAddressDistance, clearDeliveryAddressDistance } from '../../actions/delivery'
// Selectors
import { getLoadedCustomerAddressIndex, getLoadedCustomer } from '../../selectors/order'
// Components
import CustomerForm from '../../components/Form/CustomerForm'
import { OrderSubmitForm } from '../../components/Form/OrderSubmitForm';
import { getDefaultLocation } from '../../selectors/location';


class OrderFormContainer extends React.Component {
	state = {
		// see note in didUpdate for more info about this
		addressChanger: 0,
	};

	componentDidUpdate(prevProps) {
		// NOTE: when new customer address is loaded order form is not notified/updated because change only happens in form
		// so we'll use address index and loaded customer to trigger CustomerForm component update
		if (prevProps.customer !== this.props.customer || prevProps.customerAddressIndex !== this.props.customerAddressIndex) {
			this.setState({
				addressChanger: this.state.addressChanger + 1,
			});
		}
	}

	render() {
		const {
			calculateDeliveryAddressDistance, clearDeliveryAddressDistance, customerAddress, loadedCall, uiVisibleComponents
		} = this.props;

		/*
		 * This component holds main delivery composition (method "constructDelivery") so we need to implement both delivery types here
		 */
		return (
			<React.Fragment>
				{uiVisibleComponents.ui_tablet_view
					?
					<OrderSubmitForm onSubmit={this.handleFormSubmit} onReset={this.handleFormReset} />
					:
					<CustomerForm
						onSubmit={this.handleFormSubmit}
						onReset={this.handleFormReset}

						calculateDeliveryAddressDistance={calculateDeliveryAddressDistance}
						clearDeliveryAddressDistance={clearDeliveryAddressDistance}

						loadedCall={loadedCall}

						addressChanger={this.state.addressChanger}
						customerAddress={customerAddress}
					/>
				}
			</React.Fragment>
		);
	}

	handleFormSubmit = (customer) => {
		const delivery = this.constructDelivery(customer);

		this.props.submitDelivery(delivery);
	};

	handleFormReset = () => {
		this.props.resetDelivery();
	}

	/**
	 * Create delivery from variuos form and state parts.
	 * 
	 * This method contains main logic for creating new delivery. For now it's kept here but maybe it could be extracted to some middleware or somewhere else?
	 * There are two main ways for creating delivery:
	 *  - customer order - when we have customer and phone call. It is called by <CustomerForm> and it receives `customer` parameter from form
	 *  - manual order - we don't have customer but use delivery type instead. It is called by <OrderSubmitForm /> and there is not argument.
	 */
	constructDelivery = (customer) => {
		const { cartItems, loadedCall, selectedLocation, selectedDeliveryType, selectedPaymentType, selectedDiscountValue, orderDelayTime, loadedCustomer, gratisEnabled, defaultLocation } = this.props

		const delivery = {};

		delivery.orders = _.toArray(cartItems);

		var discountValue = 0;
		if (selectedDiscountValue !== null) {
			discountValue = selectedDiscountValue;
		}
		// gratis overrides manual discount
		if (gratisEnabled != null && gratisEnabled) {
			discountValue = 100;
		}
		delivery.discount = discountValue;

		const orderLocation = selectedLocation !== null ? selectedLocation : defaultLocation;
		if (orderLocation !== null) {
			delivery.location = { 'id': orderLocation.id }
		}
		else {
			console.warn('Order location is empty - no selected nor default location found');
		}

		if (selectedPaymentType === PAYMENT_TYPE.CC) {
			delivery.cc_payment = true;
		}

		if (orderDelayTime != null) {
			delivery.delayed_time = orderDelayTime;
			delivery.delayed = true;
		}

		// ----- 1. customer order

		delivery.customer_address = customer || {}; // at least an empty object is expected

		if (loadedCustomer != null) {
			if (delivery.customer_address.customer == null) {
				delivery.customer_address.customer = {}
			}

			delivery.customer_address.customer.id = loadedCustomer.id
		}

		if (loadedCall != null) {
			delivery.call = { 'id': loadedCall.id }

			if (delivery.customer_address.customer == null) {
				delivery.customer_address.customer = {}
			}

			if (delivery.customer_address.customer.phone_number == null) {
				delivery.customer_address.customer.phone_number = {}
			}

			delivery.customer_address.customer.phone_number.id = loadedCall.caller_number_id
		}

		delivery.note = customer && customer.orderNote;
		// orderNote is remapped outside of customer so we'll remove it from customer_address
		delete delivery.customer_address.orderNote


		// ----- 2. manual order

		// delivery type is currently only stored as delivery note
		if (selectedDeliveryType != null) {
			delivery.note = selectedDeliveryType;
		}

		return delivery;
	}
}

const fieldSelector = formValueSelector("orderform");

const mapStateToProps = (state, props) => {
	return {
		cartItems: state.cart.cartItems,
		loadedCall: state.viewUI.loadedCall,
		selectedLocation: state.viewUI.selectedLocation,
		selectedDeliveryType: state.viewUI.selectedDeliveryType,
		selectedPaymentType: state.viewUI.selectedPaymentType,
		selectedDiscountValue: state.viewUI.selectedDiscountValue,
		orderDelayTime: state.viewUI.orderDelayTime,
		loadedCustomer: state.viewUI.loadedCustomer,
		gratisEnabled: state.viewUI.gratisEnabled,
		uiVisibleComponents: state.viewUI.uiVisibleComponents,
		defaultLocation: getDefaultLocation(state),

		// see note in didUpdate for more info about this
		customer: getLoadedCustomer(state),
		customerAddressIndex: getLoadedCustomerAddressIndex(state),
		customerAddress: fieldSelector(state, 'address'),
	}
}

export default connect(mapStateToProps, { submitDelivery, resetDelivery, calculateDeliveryAddressDistance, clearDeliveryAddressDistance })(OrderFormContainer)