import React from 'react'

// Containers
import StatsContainer from '../../containers/StatsContainer'

// UI Components
import { Grid, Row, Col, Panel } from 'react-bootstrap'


const StatsView = () => {
    return (
        <Grid>
            <Row className="show-grid">
                <Col>
                    <StatsContainer />
                </Col>
            </Row>
        </Grid>
    )
}

export default StatsView;
