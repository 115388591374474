import axios from "axios";

export const CREATE_ITEM = "[API] MenuItem POST";
export const DELETE_ITEM = "[API] MenuItem DELETE";
export const UPDATE_ITEM = "[API] MenuItem PUT";
export const FETCH_ITEM = "[API] MenuItem GET";
export const FETCH_ITEMS = "[API] MenuItems GET";

const ROOT_URL = "/api";

export function createMenuItem(values) {
    const request = axios.post(`${ROOT_URL}/menu/item`, values);
    return {
        type: CREATE_ITEM,
        payload: request
    }
}

export function createMenuItemInGroup(values, group_id) {
    const request = axios.post(`${ROOT_URL}/menu/item/in/group/${group_id}`, values);
    return {
        type: CREATE_ITEM,
        payload: request
    }
}

export function fetchMenuItems() {
    const request = axios.get(`${ROOT_URL}/menu/item`);
    return {
        type: FETCH_ITEMS,
        payload: request
    }
}

export function fetchMenuItem(id) {
    const request = axios.get(`${ROOT_URL}/menu/item/${id}`);
    return {
        type: FETCH_ITEM,
        payload: request
    }
}

export function updateMenuItem(values) {
    const request = axios.put(`${ROOT_URL}/menu/item/${values.sort_id}`, values);
    return {
        type: UPDATE_ITEM,
        payload: request
    }
}