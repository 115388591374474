import _ from 'lodash';

import { 
    PUSH_CALL, 
    GET_CALL_DATE, 
    GET_CALL_TODAY} from '../../actions/websocket'


export default function(state = {}, action) {
  switch (action.type) {
    case GET_CALL_DATE:
        const getCallDate = _.mapKeys(action.payload.data, 'id')
        return { ...state, ...getCallDate }
    case GET_CALL_TODAY:
        const getCallToday = _.mapKeys(action.payload.data, 'id')
        return { ...state, ...getCallToday }
    case PUSH_CALL:
        return { ...state, [action.payload.data.id]: action.payload.data };
    default:
        return state;
  }
}