import _ from 'lodash';

import {
    PUSH_MENU_ITEM,
    GET_MENU_ITEM } from '../../actions/websocket'


export default function(state = {}, action) {
  switch (action.type) {
    case PUSH_MENU_ITEM:
        return { ...state, [action.payload.data.id]: action.payload.data };

    case GET_MENU_ITEM:
        return _.mapKeys(action.payload.data, 'id');

    default:
        return state;
  }
}