import _ from 'lodash'

/**
 * Adds support for creating and accessing named config objects.
 */

// configs store
const CONFIGS = {};

/**
 * Create named config with 1 or more arbitrary config objects.
 * 
 * Objects are queried using selector in order they were passed to create function and the first non-empty value is returned.
 */
export function createConfig(name, ...configs) {
	if (CONFIGS[name] != null) {
		throw `App config "${name}" already initialized!`;
	}

	CONFIGS[name] = [...configs];
}

/** Destroy named config. Returns true if config existed, false otherwise. */
export function destroyConfig(name) {
	return delete CONFIGS[name];
}

/**
 * Return value found under selector path. Path is a string of property names concatenated with ".", eg. "prop1.prop2.prop3".
 * Selectors are aplied using lodash.get method so see here for details https://lodash.com/docs#get
 */
export function getValue(name, selector) {
	return getConfigValue(name, selector);
}

export function isInitialized(name) {
	return CONFIGS[name] != null;
}

// ---------- private

function getConfigValue(name, selector, required = false) {
	if (!isInitialized(name)) {
		throw `Error get value from app config "${name}" because it\'s yet not initialized`;
	}

	const entry = reduceStateValue(name, selector);

	if (entry === undefined && required) {
		throw `App config value ${selector} not found in config "${name}"`;
	}

return entry;
}

function reduceStateValue(name, selector) {
	return CONFIGS[name].reduce((accum, config) => {
		return accum !== undefined ? accum : _.get(config, selector);
	}, undefined);
}
