import React from 'react'
import _ from 'lodash'

import { Table, Radio } from "react-bootstrap";
import { PAYMENT_TYPE } from '../../actions/UI/index';


export default class PaymentTypeList extends React.Component {

	render() {
		return (
			<React.Fragment>
				<h6>TIP PLAĆANJA:</h6>
				<Table responsive hover>
					<tbody>
						{(this.props.paymentTypes || []).map((paymentType) => (
							<tr key={paymentType}>
								<td>
									<Radio
										name="payment_type"
										value={paymentType}
										checked={this.props.value != null && this.props.value === paymentType}
										inline
										onChange={() => this.handleRadioChange(paymentType)}>
										{this.getPaymentTypeName(paymentType)}
									</Radio>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}

	handleRadioChange = (paymentType) => {
		if (this.props.onChange) {
			this.props.onChange(paymentType);
		}
	}

	getPaymentTypeName(paymentType) {
		if (paymentType === PAYMENT_TYPE.CACHE) {
			return 'Gotovina';
		}
		else if (paymentType === PAYMENT_TYPE.CC) {
			return 'Kartice';
		}
		// fallback
		else {
			return 'UNKNOWN';
		}
	}
}
