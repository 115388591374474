import * as Config from '../../utils/config/config';

/**
 * HCF app config initialization
 */
export const APP_CONFIG_NAME = '@HCF_APP_CONFIG';

/** Return HCF app config value for given selector. */
export function getValue(selector) {
	return Config.getValue(APP_CONFIG_NAME, selector)
}
