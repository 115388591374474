import _ from 'lodash';
import { FETCH_STATS_LOCATION_BALANCE, FETCH_STATS_DELIVERYMEN_BALANCE, FETCH_STATS_ITEMS_SOLD } from '../../actions/stats';


export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_STATS_LOCATION_BALANCE:
      return { ...state, locationBalanceStats: action.payload.data }
    case FETCH_STATS_DELIVERYMEN_BALANCE:
      return { ...state, deliveryMenStats: action.payload.data }
    case FETCH_STATS_ITEMS_SOLD:
      return { ...state, itemsSoldStats: action.payload.data }
    default:
      return state;
  }
}
