import { combineReducers } from 'redux';

import { UI_FEEDBACK_ADD_MESSAGE, UI_FEEDBACK_REMOVE_MESSAGE } from "../actions/feedback";


// -------------------- Reducers

export function messages(state = [], action) {
	// add message
	if (action.type === UI_FEEDBACK_ADD_MESSAGE) {
		let message = action.payload;

		// remove message with ID of new message (if it exists)
		let newList = state.filter((item) => {
			return item.id !== message.id;
		});

		// append new message
		newList.push(message);

		return newList;
	}

	// remove message
	else if (action.type === UI_FEEDBACK_REMOVE_MESSAGE) {
		let messageId = action.payload.id;

		// filter out message with given ID
		return state.filter((item) => {
			return item.id !== messageId;
		});
	}

	return state;
}


const feedback = combineReducers({
	messages
});

export default feedback;
