import _ from 'lodash';

import {
    PUSH_MENU_ITEM_GROUP_COMPLEMENT,
    DELETE_MENU_ITEM_GROUP_COMPLEMENT,
    GET_MENU_ITEM_GROUP_COMPLEMENT, } from '../../actions/websocket'

export default function(state = {}, action) {
  switch (action.type) {
    case DELETE_MENU_ITEM_GROUP_COMPLEMENT:
        var { complement_menu_group_id, menu_item_id } = action.payload.data
        if (menu_item_id in state) {
            if (state[menu_item_id].includes(complement_menu_group_id)) {
                const idx = state[menu_item_id].indexOf(complement_menu_group_id);
                return {
                    ...state,
                    [menu_item_id]: [
                        ...state[menu_item_id].slice(0, idx),
                        ...state[menu_item_id].slice(idx + 1)
                    ]
                };
            }
        }

        return state;


    case PUSH_MENU_ITEM_GROUP_COMPLEMENT:
        // TESTED
        //
        var { menu_item_id, complement_menu_group_id } = action.payload.data
        if (menu_item_id in state) {
            if (state[menu_item_id].includes(complement_menu_group_id)) {
                var updatedGroup = state[menu_item_id]
            } else {
                var updatedGroup = [...state[menu_item_id], complement_menu_group_id]
            }
        } else {
            var updatedGroup = [complement_menu_group_id];
        }
        return {
            ...state,
            [menu_item_id]: updatedGroup
        }
        //

    case GET_MENU_ITEM_GROUP_COMPLEMENT:
        return _.reduce(action.payload.data, function (result, kv) {
            (result[kv.menu_item_id] || (result[kv.menu_item_id] = [])).push(kv.complement_menu_group_id);
            return result;
        }, {});

    default:
        return state;
  }
}

