
/** HCF app config object */
const APP_CONFIG = {
	app: {
		environment: 'dev',
	},

	api: {
		basePath: '/api',
	},

	// ---- component configuration
	component: {
		// configs common to all/various components
		common: {
			// how much should button be disabled (eg. when preventing user's double clicks)
			buttonDisableTimeout: 3000,
		}
	},

	sentry: {
		enabled: false,
		// this is directly applied to sentry init() method
		// for details see https://docs.sentry.io/platforms/javascript/
		config: {
			dsn: '<DSN>',
		},
	},
};

export default APP_CONFIG;
