import { createSelector } from 'reselect'
import _ from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment);

/** Return call records for selected UI date. */
export const getCallRecordsForDate = createSelector(
    [getCallRecords, getUIviewCallDate],
    (callRecords, UIviewCallDate) => {
        const dayStart = moment(UIviewCallDate).startOf('day');
        const dayEnd = moment(UIviewCallDate).endOf('day');

        const callList = filterCallRecordsByIncomingRange(callRecords, dayStart, dayEnd);

        return sortCallRecordsByIncomingTime(callList);
    }
);

/** Return today's call records. */
export const getCallRecordsForToday = createSelector(
    [getCallRecords],
    (callRecords) => {
        const todayStart = moment().startOf('day');
        const todayEnd = moment().endOf('day');

        const callList = filterCallRecordsByIncomingRange(callRecords, todayStart, todayEnd);

        return sortCallRecordsByIncomingTime(callList);
    }
);

/** Get currently selected call list date filter. */
export function getUIviewCallDate(state) {
    return state.viewUI.callRecordsDate;
}


// ---------- private

function getCallRecords(state) {
    return state.Call;
}


/** Filter records and return only those incoming between given time moments. */
function filterCallRecordsByIncomingRange(callRecords, startTime, endTime) {
    const target_range = moment.range(startTime, endTime)
    //console.log('Filtering call between', startTime.toString(), endTime.toString(), callRecords || null);

    return _.filter(callRecords, record => {
        return target_range.contains(moment(record.incoming_at))
    })
}

/** Sorts call records by incoming time. */
function sortCallRecordsByIncomingTime(callRecords) {
    return _.orderBy(callRecords, [record => new moment(record.incoming_at)], ['desc']);
}
