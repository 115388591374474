import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import memoize from "memoize-one";
import { Tabs, Tab } from 'react-bootstrap';

// selectors
import * as selectors from '../../selectors/stats'

// actions
import * as actions from '../../actions/stats';

// UI
import StatsFilter from '../../components/UI/stats/StatsFilter'
import LocationBalanceStats from '../../components/UI/stats/LocationBalanceStats'
import DeliveryMenStats from '../../components/UI/stats/DeliveryMenStats';
import ItemsSoldStats from '../../components/UI/stats/ItemsSoldStats';


const TabsKeys = {
    LOCATION_BALANCE_KEY: 'LOCATION_BALANCE_KEY',
    DELIVERYMEN_KEY: 'DELIVERYMEN_KEY',
    ITEMS_SOLD_KEY: 'ITEMS_SOLD_KEY',
};

const mapStateToProps = (state, props) => {
    return {
        locationBalanceStats: selectors.getLocationBalanceStats(state),
        locationBalanceTotals: selectors.getLocationBalanceTotals(state),

        deliveryMenStats: selectors.getDeliveryMenStats(state),
        deliveryMenStatsTotals: selectors.getDeliveryMenStatsTotals(state),

        itemsSoldStats: selectors.getGroupItemsSold(state),

    }
}

class StatsContainer extends React.Component {
    state = {
        // init dates to current date
        startDate: moment().startOf('date'),
        endDate: moment().endOf('date'),
    };

    componentDidMount() {
        this.fetchStats(this.state.startDate, this.state.endDate);
    }

    componentDidUpdate() {
        this.fetchStats(this.state.startDate, this.state.endDate);
    }

    render() {
        const { locationBalanceStats, locationBalanceTotals, deliveryMenStats, deliveryMenStatsTotals, itemsSoldStats, menuGroups } = this.props;
        return (
            <div>
                <StatsFilter filter={{ startDate: this.state.startDate, endDate: this.state.endDate }} onChange={this.handleFilterChange} />

                <div>&nbsp;</div>

                <Tabs defaultActiveKey={TabsKeys.LOCATION_BALANCE_KEY} id="stats-navigation">
                    <Tab eventKey={TabsKeys.LOCATION_BALANCE_KEY} title="Promet">
                        {locationBalanceStats != null && (
                            <LocationBalanceStats data={locationBalanceStats} totals={locationBalanceTotals} />
                        )}
                    </Tab>

                    <Tab eventKey={TabsKeys.DELIVERYMEN_KEY} title="Dostavljači račun">
                        {deliveryMenStats != null && (
                            <DeliveryMenStats data={deliveryMenStats} totals={deliveryMenStatsTotals} />
                        )}
                    </Tab>

                    <Tab eventKey={TabsKeys.ITEMS_SOLD_KEY} title="Prodano">
                        {itemsSoldStats != null && (
                            <ItemsSoldStats data={itemsSoldStats} menuGroups={menuGroups} />
                        )}
                    </Tab>
                </Tabs>
            </div>
        );
    }

    handleFilterChange = (filter) => {
        console.log(`Stats filter changed: ${filter}`);
        this.updateDates(filter.startDate, filter.endDate);
    }

    updateDates(startDate, endDate) {
        this.setState({
            startDate, endDate,
        });
    }

    fetchStats = memoize((startDate, endDate) => {
        this.props.fetchLocationBalanceStats(startDate, endDate);
        this.props.fetchDeliveryMenStats(startDate, endDate);
        this.props.fetchItemsSoldStats(startDate, endDate);
    });
}

export default connect(
    mapStateToProps, actions
)(StatsContainer);
