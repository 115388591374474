import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

// Actions
import { 
    createMenuGroupAsComplementToGroup,
    createMenuGroupAsComplementToItem
 } from '../../actions/menu/group'

// Selectors
import groupSelected from '../../selectors/group_edit'
import itemSelected from '../../selectors/item_edit'

// UI components
import { Grid, Row, Col, Panel, } from 'react-bootstrap'

// Form components
import MenuGroupForm from '../../components/Form/MenuGroupForm'

class MenuGroupCreateAsComplement extends Component {
    render() {
        const { 
            parent_item,
            parent_group, 
            createMenuGroupAsComplementToGroup, 
            createMenuGroupAsComplementToItem } = this.props

        const initData = { "active": true }

        /**
         * Need to figure out who are we creating complement for
         * is it 
         * - group
         * - item
         * 
         * detect based on selectors which examine props (URL)
         * then set corresponding 
         * - parent_target
         * - submit_action
         */

        console.log('DEBUG MenuGroupCreateAsComplement ', this.props)
        if (parent_item !== undefined) {
            var parent_target = Object.assign(parent_item);
            var submit_action = Object.assign(createMenuGroupAsComplementToItem);
        } else if (parent_group !== undefined) {
            var parent_target = Object.assign(parent_group);
            var submit_action = Object.assign(createMenuGroupAsComplementToGroup);

        } else {
            return 'Problem loading MenuGroupCreateAsComplement ...'
        }

        return (
            <Grid>
                <Row className="show-grid">
                    <Col sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Nova menu grupa</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <MenuGroupForm initData={initData} parent_target={parent_target} submit_action={submit_action} />
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        parent_group: groupSelected(state, props),
        parent_item: itemSelected(state, props)
    }
}

const mapDispatchToProps = {
    createMenuGroupAsComplementToGroup,
    createMenuGroupAsComplementToItem,
}

/*
    // Not really sure why this is discouraged... 
    // https://github.com/jaysoo/todomvc-redux-react-typescript/issues/7
    const mapDispatchToProps = (dispatch) => {
        return bindActionCreators({object with actions}, dispatch)
    }
*/

export default (connect(mapStateToProps, mapDispatchToProps)(MenuGroupCreateAsComplement));
