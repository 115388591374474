
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';

import FieldInput from './FieldInput'

const isNumber = input => {
    return !isNaN( input );
}
const validate = values => {
    const errors = {}
    if(!values.name)  {
        errors.name = 'Unesite naziv stavke'
    }
    if(values.price === undefined)  {
        errors.price = 'Unesite brojčanu cijenu!'
    }
    if (values.price && !isNumber(values.price)) {
        errors.price = 'Unesite brojčanu cijenu';
    }

    return errors
}
class MenuItemForm extends Component {
    componentDidMount() {
        this.handleInitialize()
    }

    handleInitialize(providedValues = null) {
        const { initData } = this.props;
        const form_data = providedValues || initData
        this.props.initialize(form_data);
    }

    onSubmit(values) {
        const { parent_target } = this.props;
        if (parent_target === undefined) {
            this.props.submit_action(values)
            this.props.reset()
            if ('id' in values) {
                // Initialize values only if we are updating existing item; and indicator if it exists is 'id' 
                this.handleInitialize(values)
            }
        } else {
            /* 
                Block handles action which will include reference to existing record
                e.g. Create MenuItem and add MenuItem connection to some MenuGroup
             */
            this.props.submit_action(values, parent_target.id)
            this.props.reset()
            if ('id' in values) {
                this.handleInitialize(values)
            }
        }

        // go back to wherever it is you came from
        this.props.history.goBack();
    }

    render() {
        const { handleSubmit, load, pristine, submitting } = this.props;
        return (
            <form onSubmit={handleSubmit(this.onSubmit.bind(this))} >
                <Field component={FieldInput} name="name"
                        label="Naziv stavke"
                        type="string"
                        glyphIcon="home"/>
                <Field component={FieldInput} name="short_name" 
                        label="Naziv stavke za ispis:"
                        type="string" 
                        glyphIcon="home"/>
                <Field component={FieldInput} name="price"
                        parse={value => Number(value)}
                        label="Cijena stavke:"
                        type="number" 
                        glyphIcon="home"/>
                <Field component={FieldInput} name="description" 
                        label="Opis stavke:"
                        type="string" 
                        glyphIcon="home"/>
                <Field component={FieldInput} name="combo" 
                        label="Ulazi u kombinaciju"
                        type="checkbox" />
                <Field component={FieldInput} name="active"
                        label="Aktivno"
                        type="checkbox" />
                <Field component={FieldInput} name="visible_online"
                        label="Dostupno online"
                        type="checkbox" />
                <Field component={FieldInput} name="img_url"
                        label="URL slike:" 
                        type="string"
                        glyphIcon="home"/>

                <Button 
                    type="button" 
                    disabled={submitting} 
                    onClick={this.handleFormCancel}
                    bsStyle="info"
                > PONIŠTI </Button>
                <Button type="submit"
                    disabled={pristine || submitting} 
                    bsStyle="success"
                >{this.props.initData.id ? 'SNIMI' : 'DODAJ'} </Button>
            </form>
        )
    }


    handleFormCancel = (e) => {
        e.preventDefault();

        this.props.reset();
        this.props.history.goBack();
    }
}

export default reduxForm({
    form: 'menu_item_form',
    destroyOnUnmount: true,
    validate
})(withRouter(MenuItemForm));
