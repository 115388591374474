import React from 'react';
import { withRouter } from 'react-router-dom';
import { isAdmin } from './util';

export function requireAdminAuth(Component) {

    class AdminAuth extends React.Component {

        componentDidMount() {
            this.checkAuth();
        }

        componentDidUpdate() {
            this.checkAuth();
        }

        checkAuth() {
            if (!isAdmin()) {
                this.props.history.push('/home');
            }
        }

        render() {
            return (
                <Component {...this.props} />
            );
        }
    }

    return withRouter(AdminAuth);
}
