import React from 'react'

// Containers
import DeliveryList from '../../containers/DeliveryList'

// UI Components
import { Grid, Row, Col } from 'react-bootstrap'

const DeliveriesView = () => {
	return (
        <Grid>
            <Row className="show-grid">
				<Col>
					<DeliveryList />
				</Col>
			</Row>
		</Grid>
	)
}

export default DeliveriesView;
