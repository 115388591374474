
export const UI_FEEDBACK_ADD_MESSAGE = "[UI] Add feedback message";
export const UI_FEEDBACK_REMOVE_MESSAGE = "[UI] Remove feedback message";

export const MESSAGE_SEVERITY = {
	SUCCESS: 'SUCCESS',
	INFO: 'INFO',
	WARNING: 'WARNING',
	ERROR: 'ERROR',
};

let APP_MESSAGE_COUNTER = 0;

// -------------------- Actions

/**
 * Report message feedback to user.
 * 
 * Message object: 
 * {
 *	id? : string;
 *	severity : valueof MESSAGE_SEVERITY;
 *	message : string;
 *	timeout? : number;
 *	autoRemove? : boolean;
 * } 
 */
export function reportMessage(msg) {
	// assign automatic msg id
	let msgId = msg.id || (++APP_MESSAGE_COUNTER).toString();
	// configure message autoremove
	let msgTimeout = msg.timeout != null ? msg.timeout : 4000;

	let preparedMsg = {
		...msg,

		id : msgId,
		timeout: msgTimeout
	};

	return {
		type: UI_FEEDBACK_ADD_MESSAGE,
		payload: preparedMsg,
	};
}

/**
 * Remove message reported with given ID
 */
export function removeMessage(msgId) {
	return {
		type: UI_FEEDBACK_REMOVE_MESSAGE,
		payload: {
			id: msgId
		},
	};
}
