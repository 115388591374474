import { createSelector } from 'reselect'
import _ from 'lodash'

const getCartPartItemList = (state, props) => state.cart.partialItems.cartItems

const getCartPartItems = createSelector(
    [getCartPartItemList, ], 
    (cartPartItems, ) => {
        return cartPartItems
    }

)

export default getCartPartItems