import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

/* containers */
import { HomeContainer } from './containers/HomeContainer';
import LoginView from './components/LoginView';

import ProtectedView from './components/ProtectedView';

import NotFound from './components/NotFound';

import OrderViewContainer from './containers/OrderViewContainer'
import TabletOrderViewContainer from './containers/TabletOrderViewContainer';
import CallRecordsView from './components/ViewsUI/CallRecords';
import DeliveriesView from './components/ViewsUI/DeliveriesView';
import Handoff from './components/ViewsUI/Handoff';
import StatsView from './components/ViewsUI/StatsView';

import MenuItemCreate from './containers/MenuItemCreate';
import MenuItemEdit from './containers/MenuItemEdit';

import MenuGroupCreate from './containers/MenuGroupCreate';
import MenuItemCreateInGroup from './containers/MenuItemCreateInGroup';
import MenuGroupCreateAsComplement from './containers/MenuGroupCreateAsComplement'
import MenuGroupList from './containers/MenuGroupList';
import MenuGroupEdit from './containers/MenuGroupEdit';

import DeliveryManCreate from './containers/DeliveryManCreateContainer'
import DeliveryManEdit from './containers/DeliveryManEditContainer'
import DeliveryManList from './containers/DeliveryManListContainer'

import { DetermineAuth } from './components/auth/DetermineAuth';
import { requireAuthentication } from './components/auth/AuthenticatedComponent';
import { requireNoAuthentication } from './components/auth/notAuthenticatedComponent';
import UserView from './components/UserView';
import { requireAdminAuth } from './components/auth/AdminAuth';
import { tabletViewRouteGuard } from './components/auth/tabletViewRouteGuard';


export default (
    <Switch>
        <Redirect path="/" exact to="/home" />

        {/* ----- public routes ----- */}

        <Route path="/login" component={requireNoAuthentication(LoginView)} />
        <Route path="/home" component={requireNoAuthentication(HomeContainer)} />


        {/* ----- private routes ----- */}

        <Route path="/main" component={requireAuthentication(ProtectedView)} />
        <Route path="/order" component={requireAuthentication(OrderViewContainer)} />

        <Route path="/ordertablet" component={requireAuthentication(tabletViewRouteGuard(TabletOrderViewContainer, { redirectTo: '/main' }))} />

        <Route path="/calls" component={requireAuthentication(CallRecordsView)} />
        <Route path="/deliveries" component={requireAuthentication(DeliveriesView)} />
        <Route path="/stats" component={requireAuthentication(requireAdminAuth(StatsView))} />

        <Route path="/menu/item/list" component={requireAuthentication(MenuItemCreate)} />
        <Route path="/menu/item/create" component={requireAuthentication(MenuItemCreate)} />
        <Route path="/menu/item/edit/:menu_item_sort_id" component={requireAuthentication(MenuItemEdit)} />
        <Route path="/menu/item/:menu_item_sort_id/complement/group/create" component={requireAuthentication(MenuGroupCreateAsComplement)} />

        <Route path="/menu/group/create" component={requireAuthentication(MenuGroupCreate)} />
        <Route path="/menu/group/list" component={requireAuthentication(MenuGroupList)} />
        <Route path="/menu/group/:menu_group_id/items/add" component={requireAuthentication(MenuItemCreateInGroup)} />
        <Route path="/menu/group/:menu_group_id/complement/group/create" component={requireAuthentication(MenuGroupCreateAsComplement)} />
        <Route path="/menu/group/edit/:menu_group_id" component={requireAuthentication(MenuGroupEdit)} />

        <Route path="/deliveryman/create" component={requireAuthentication(DeliveryManCreate)} />
        <Route path="/deliveryman/edit/:deliveryman_id" component={requireAuthentication(DeliveryManEdit)} />
        <Route path="/deliveryman/list" component={requireAuthentication(DeliveryManList)} />
        <Route path="/deliveryman/handoff" component={requireAuthentication(Handoff)} />

        <Route path="/user/create" component={requireAuthentication(requireAdminAuth((UserView)))} />


        {/* ----- default route ----- */}

        <Route path="*" component={DetermineAuth(NotFound)} />
    </Switch>
);
