// Action types 
export const CART_ADD_ITEM = '[CART] Cart Add Item'
export const CART_ADD_ADDON = '[CART] Cart Add Addon'
export const CART_REMOVE_ITEM = '[CART] Remove Item from cart'
export const CART_REMOVE_ADDON = '[CART] Remove Addon from cart'
export const CART_SELECT_ITEM = '[CART] Select Item'

export const CART_ADD_PART = '[CART] Add part of meal'
export const CART_ADD_LAST_PART = '[CART] Add *last* part of meal'
export const CART_ADD_PART_ADDON = '[CART] Add part addon'
export const CART_REMOVE_PART = '[CART] Remove part of meal'
export const CART_REMOVE_LAST_PART = '[CART] Remove last part of meal'
export const CART_REMOVE_PART_ADDON = '[CART] Remove part addon'
export const CART_RESET_PART = '[CART] Last part was added, reset part cart to defaults'
export const CART_REMOVE_ITEM_BY_UUID = '[CART] Remove cart item by UUID'

export const CART_CHECKOUT_REQUEST = '[CART] Checkout request'
export const CART_CHECKOUT_FAILURE = '[CART] Checkout request failure'

// Actions
export const addCartItem = item => ({
    type: CART_ADD_ITEM,
    payload: item
})

export const addCartAddon = (item, target_uuid) => ({
    type: CART_ADD_ADDON,
    payload: item,
    meta: {
        target: target_uuid
    }
})

export const removeCartItem = (item, _) => ({
    type: CART_REMOVE_ITEM,
    payload: item
})

export const removeCartItemByUUID = item_uuid => ({
    type: CART_REMOVE_ITEM_BY_UUID,
    payload: item_uuid
})

export const removeCartAddon = (item, parent_uuid) => ({
    type: CART_REMOVE_ADDON,
    payload: item,
    meta: {
        parent: parent_uuid
    }
})

export const selectCartItem = item_uuid => ({
    type: CART_SELECT_ITEM,
    payload: item_uuid
})

// export const CART_ADD_PART = '[CART] Add part of meal'
// export const CART_ADD_LAST_PART = '[CART] Add *last* part of meal'
// export const CART_ADD_PART_ADDON = '[CART] Add part addon'
// export const CART_REMOVE_PART = '[CART] Remove part of meal'
// export const CART_REMOVE_LAST_PART = '[CART] Remove last part of meal'
// export const CART_REMOVE_PART_ADDON = '[CART] Remove part addon'

// Pola pola cart actions
export const addCartItemPart = item => ({
    type: CART_ADD_PART,
    payload: item
})

export const addCartLastItemPart = (item, _, previous_parts) => ({
    type: CART_ADD_LAST_PART,
    payload: item,
    meta: {
        previous_parts: previous_parts
    }
})

export const addCartPartAddon = (item, target_uuid, _) => ({
    type: CART_ADD_PART_ADDON,
    payload: item,
    meta: {
        target: target_uuid
    }
})

export const removeCartItemPart = (item, _) => ({
    type: CART_REMOVE_PART,
    payload: item
})

export const removeCartPartAddon = (item, parent_uuid) => ({
    type: CART_REMOVE_PART_ADDON,
    payload: item,
    meta: {
        parent: parent_uuid
    }
})

export const removeCartLastItemPart = () => ({
    type: CART_REMOVE_LAST_PART
})

export const resetCartPart = () => ({
    type: CART_RESET_PART
})