import JwtDecode from 'jwt-decode';

import { promiseTimeout } from '../../utils/promiseUtils';


const API_TOKEN_RESOLVE_TIMEOUT = 5000; // in millies

export function resolveToken(token) {
	// this API call is an entry in to the app so it's important that it finishes in normal time so that we can react to possible BE's problems
	return promiseTimeout(fetch('/api/token/is_valid', {
		method: 'post',
		credentials: 'include',
		headers: {
			'Accept': 'application/json', // eslint-disable-line quote-props
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ token }),
	}), API_TOKEN_RESOLVE_TIMEOUT)
}

export function isAdmin() {
    var userToken = localStorage.getItem('token');
    var isAdmin = JwtDecode(userToken).admin;

	return isAdmin;
}
