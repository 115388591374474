import { createSelector } from 'reselect'
import _ from 'lodash'

const getGroupMemberList = (state, props) => state.MenuItemGroupMembers[props.match.params.menu_group_id]

const getItems = (state, props) => state.MenuItems

const getGroupItems = createSelector(
    [getGroupMemberList, getItems], 
    (groupMemberList, items) => {
        return _.filter(
            items,
            item => _.includes(groupMemberList, item.id)
        )
    }
)

export default getGroupItems