import { LOGIN_USER_SUCCESS, LOGOUT_USER, REGISTER_USER_SUCCESS } from '../constants/index'
import { ACTIVATE_AGENT_LISTENER, DEACTIVATE_AGENT_LISTENER, GET_MY_AGENT_KEY } from '../actions/agent'
import {
    UI_AGENT_TALKING_WITH_CUSTOMER,
    UI_AGENT_HANDLING_CALL,
    UI_OPEN_MODAL_CUSTOMER_DATA_LOAD,
    UI_LOAD_NEXT_CUSTOMER_ADDRESS,
    UI_LOAD_PREVIOUS_CUSTOMER_ADDRESS,
    UI_LOAD_CUSTOMER_ADDRESS_IDX,
    UI_LOADED_CUSTOMER_ADDRESS_COUNT,
    UI_FETCH_CUSTOMER_DATA,
    UI_LOAD_CUSTOMER_DATA,
    UI_LOAD_ACTIVE_CALL_DATA,
    UI_MODAL_LOAD_NEW,
    UI_RESET_CUSTOMER,
} from '../actions/UI'

import {
    initialize as initializeForm,
    destroy as destroyForm,
} from 'redux-form'

import moment from "moment"

import { socket } from './websocket'
import jwtDecode from 'jwt-decode'

import { deliveryMdl } from './delivery'
import { resetDelivery } from '../actions/delivery'



const eventManager = store => next => action => {
    const { dispatch } = store

    switch (action.type) {
        case LOGIN_USER_SUCCESS:
            var { token } = action.payload
            if (socket.query.token === null) {
                socket.query.token = token
            }
            socket.open()

            if (token !== null) {
                var user_public_id = jwtDecode(token).public_id
                console.log('[Socket.IO] listener  "' + user_public_id + '" is active')
                socket.on(user_public_id, (data) => {

                    //// UPDATE Agent listener ////
                    var agent_key = localStorage.getItem('agent_key')
                    var user_token = localStorage.getItem('token')
                    var user_public_id = jwtDecode(user_token).public_id

                    console.log('[WS] USER EVENT "' + user_public_id + '" <<< ', data)

                    socket.emit(GET_MY_AGENT_KEY, user_public_id, (data) => {
                        console.log('UPDATE Agent listener by shutting down : ', agent_key)
                        if (agent_key !== null) {
                            dispatch({
                                type: DEACTIVATE_AGENT_LISTENER,
                                payload: agent_key
                            })
                        }

                        if (_.has(data, 'agent_key')) {
                            localStorage.setItem('agent_key', data.agent_key)
                            dispatch({
                                type: ACTIVATE_AGENT_LISTENER,
                                payload: data.agent_key
                            })
                        }
                    })
                    ////
                })
            } else {
                console.err('LOGIN_USER_SUCCESS is missing user_token, payload is : ', action.payload)
            }
            break

        case LOGOUT_USER:
            // TODO : clean the redux store on LOGOUT
            if (action.payload !== null) {
                var { public_id, agent_key } = action.payload
                console.log('[SocketIO] OFF : %s', public_id)
                socket.off(public_id)
                if (agent_key !== null) {
                    console.log('[SocketIO] OFF : %s', agent_key)
                    socket.off(agent_key)
                }
            }
            socket.close()
            break


        case ACTIVATE_AGENT_LISTENER:
            var agent_key = action.payload
            console.log('[SocketIO] ON : %s', agent_key)
            socket.on(agent_key, (data) => {
                console.log('[WS] AGENT EVENT "' + agent_key + '" <<< ', data)

                if (_.has(data, 'CALL')) {
                    var currentState = store.getState()
                    if (currentState.viewUI.loadedCustomer === null) {
                        dispatch({
                            type: UI_LOAD_ACTIVE_CALL_DATA,
                            payload: data
                        })
                    } else {
                        dispatch({
                            type: UI_OPEN_MODAL_CUSTOMER_DATA_LOAD
                        })
                    }
                }
            })
            break


        case DEACTIVATE_AGENT_LISTENER:
            var agent_key = action.payload
            console.log('[SocketIO] OFF : %s', agent_key)
            socket.off(agent_key)
            break


        case UI_LOAD_NEXT_CUSTOMER_ADDRESS:
            var currentState = store.getState()
            dispatch({
                type: UI_LOAD_CUSTOMER_ADDRESS_IDX,
                payload: Math.min(currentState.viewUI.loadedCustomerAddressIndex + 1, currentState.viewUI.loadedCustomerAddressCount)
            })
            break


        case UI_LOAD_PREVIOUS_CUSTOMER_ADDRESS:
            var currentState = store.getState()
            dispatch({
                type: UI_LOAD_CUSTOMER_ADDRESS_IDX,
                payload: Math.max(currentState.viewUI.loadedCustomerAddressIndex - 1, 1)
            })
            break


        case UI_LOAD_CUSTOMER_ADDRESS_IDX:
            var currentState = store.getState()
            if (currentState.viewUI.loadedCustomer.addresses.length >= action.payload) {
                const sortedAddressByLastUse = _.orderBy(currentState.viewUI.loadedCustomer.addresses, [record => new moment(record.last_used_at)], ['desc'])
                dispatch(
                    initializeForm('orderform', sortedAddressByLastUse[action.payload - 1])
                )
            }
            break


        case UI_RESET_CUSTOMER:
            dispatch(
                destroyForm('orderform')
            )
            break


        case UI_LOAD_ACTIVE_CALL_DATA:
            var phonecall_data = action.payload
            if (_.has(phonecall_data, 'CUSTOMER')) {
                console.log('CUSTOMER STUFF ', phonecall_data.CUSTOMER)
                if (phonecall_data.CUSTOMER.customer !== null) {
                    console.log('Loading existing customer')
                    dispatch({
                        type: UI_LOAD_CUSTOMER_DATA,
                        payload: phonecall_data.CUSTOMER
                    })
                } else {
                    console.log('Non existing customer, we probably need to do some cleanup here')
                }
            }
            if (_.has(phonecall_data, 'CALL')) {
                console.log('CALL STUFF ', phonecall_data.CALL)
                dispatch({
                    type: UI_AGENT_HANDLING_CALL,
                    payload: phonecall_data.CALL
                })
            }
            break


        case UI_LOAD_CUSTOMER_DATA:
            var customer_data = action.payload

            if (_.has(customer_data, 'addresses')) {
                // Load CustomerData
                dispatch({
                    type: UI_AGENT_TALKING_WITH_CUSTOMER,
                    payload: customer_data
                })

                // Sort addresses
                const sortedAddressByLastUse = _.orderBy(customer_data.addresses, [record => new moment(record.last_used_at)], ['desc'])
                dispatch({
                    type: UI_LOADED_CUSTOMER_ADDRESS_COUNT,
                    payload: customer_data.addresses.length
                })
                // Initialize form
                dispatch(
                    initializeForm('orderform', sortedAddressByLastUse[0])
                )
            }
            break


        case UI_FETCH_CUSTOMER_DATA:
            var agent_key = action.payload
            socket.emit(UI_FETCH_CUSTOMER_DATA, agent_key, (customer_data) => {
                /**
                 * When we get response, before we try to load it up we need to cleanup current customer
                 */
                dispatch(resetDelivery())
                dispatch({
                    type: UI_LOAD_ACTIVE_CALL_DATA,
                    payload: customer_data
                })
            })
            break


        case UI_MODAL_LOAD_NEW:
            var currentState = store.getState()
            if (currentState.viewUI.activeAgent !== null) {
                const agent_key = currentState.viewUI.activeAgent
                dispatch({
                    type: UI_FETCH_CUSTOMER_DATA,
                    payload: agent_key
                })
            }
            break

    }
    next(action)
}

export const eventMdl = [eventManager, deliveryMdl,];