import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, Button, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'


class DeliveryCancelModal extends Component {
    state = {
        cancelReason: "",
    };

    render() {
        return (
            <div>
                <Modal show={true} onHide={this.handleModalHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Storniranje narudžbe: #{this.props.delivery.id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup controlId="formControlsTextarea">
                            <ControlLabel>Razlog storniranja</ControlLabel>
                            <FormControl componentClass="textarea" placeholder="textarea" onChange={this.handleCancelReasonChange} />
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col xs={6} style={{ textAlign: "left" }}>
                                <Button onClick={this.handleCancelDeliveryButtonClick} bsStyle={'warning'} disabled={!this.isCancelButtonEnabled()}>STORNIRAJ</Button>
                            </Col>
                            <Col xs={6}>
                                <Button onClick={this.handleModalHide}>ODUSTANI</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    handleCancelReasonChange = (e) => {
        // store new value to state
        this.setState({
            cancelReason: e.target.value
        });
    }

    handleCancelDeliveryButtonClick = () => {
        this.props.onDeliveryCancel(this.state.cancelReason);
    }

    handleModalHide = () => {
        // propagate "hide" event to caller
        this.props.onHide();
    }

    isCancelButtonEnabled = () => {
        return this.state.cancelReason && this.state.cancelReason.trim();
    }
}

PropTypes.DeliveryCancelModal = {
    delivery: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        uuid: PropTypes.string.isRequired,
        created_at: PropTypes.instanceOf(Date),
        dispatched_at: PropTypes.instanceOf(Date),
        canceled_at: PropTypes.instanceOf(Date),
        agent: PropTypes.number,
        customer_address: PropTypes.isRequired,
        location: PropTypes.isRequired,
    })).isRequired,
    onDeliveryCancel: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
};

export default DeliveryCancelModal;
