import React, { useCallback } from 'react';
import { Button, FormGroup } from 'react-bootstrap';

export const OrderSubmitForm = (props) => {
	const { onSubmit, onReset } = props;

	const handleSubmit = useCallback((event) => {
		if (onSubmit) {
			onSubmit();
		}
	});

	const handleReset = useCallback((event) => {
		if (onReset) {
			onReset();
		}
	});

	return (
		<FormGroup>
			<Button
				type="button"
				onClick={handleReset}
				bsStyle="info"
			>PONIŠTI</Button>
			&nbsp;
			<Button type="submit"
				bsStyle="success"
				onClick={handleSubmit}
			>NARUČI</Button>
		</FormGroup>
	);
}
