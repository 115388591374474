import { createSelector } from 'reselect'
import _ from 'lodash'

import * as CurrencyMath from '../../utils/currency';
import getCartComboView from './getCartComboView'

const getComboEnabled = (state, props) => state.viewUI.comboEnabled
const getCartItems = (state, props) => {
	return getCartComboView(state)
}

const getCartTotal = createSelector(
	[getCartItems, getComboEnabled],
	(cartItems, comboEnabled) => {
		if (Object.keys(cartItems).length === 0) {
			return 0
		}
		const priceList = _.map(cartItems, item => {
			if (item.addons && item.addons.length > 0) {
				var addonPriceList = _.map(item.addons, addon => {
					if (addon.gratis) {
						return 0
					}
					return addon.price
				})
				var addonPriceTotal = _.reduce(addonPriceList, function (sum, price) {
					return CurrencyMath.add(sum, price);
				})

				if (item.gratis) {
					return addonPriceTotal
				}
				return CurrencyMath.add(item.price, addonPriceTotal);
			} else {
				if (!(item.gratis)) {
					return item.price
				} else {
					return 0
				}

			}
		})

		// we're doing currency arithmetics which means we always have 00 decimal digits
		// to avoid floating point arithmetics accuracy problems, we'll convert them to ints (multiply by 100) and then, divide total sum by 100 again
		return _.reduce(priceList, function (sum, price) {
			return CurrencyMath.add(sum, price);
		}, 0);

	}
)

export default getCartTotal