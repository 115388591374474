import React from 'react'
import _ from 'lodash'
import { reduxForm, Field } from 'redux-form'
import { Button, Well, Glyphicon } from 'react-bootstrap'

import FieldInput from './FieldInput'


// min address length that will trigger distance calculation
const MIN_ADDRESS_CALCULATE_LENGTH = 3;
const ADDRESS_CHANGE_DEBOUNCE_TIMEOUT = 2000;

const validate = values => {
	const errors = {}
	if (!values.address) {
		errors.address = 'Molim unesite adresu'
	}

	if (values.floor && values.floor.length > 15) {
		errors.floor = 'Maksimalno 15 znakova stane'
	}

	if (values.contact_phone && values.contact_phone.length > 20) {
		errors.contact_phone = 'Maksimalno 20 znakova stane'
	}

	if (values.contact_phone && isNaN(Number(values.contact_phone))) {
		errors.contact_phone = 'Polje mora biti broj'
	}

	return errors
}

class CustomerForm extends React.Component {
	componentDidUpdate(prevProps) {
		if (this.props.customerAddress !== prevProps.customerAddress) {
			// we've loaded another customer's address, let's recalculate distances
			this.updateDeliveryAddressDistance(this.props.customerAddress);
		}
	}

	componentWillUnmount() {
		// canceling debounced call (cancel() is created the _.debounce() call)
		this.updateDeliveryAddressDistance.cancel();
	}

	handleSubmit = (values) => {
		const { pristine } = this.props;

		if (!pristine) {
			values.id = undefined;
		}

		// console.log(delivery)
		this.props.onSubmit(values)
	}

	handleReset = () => {
		const { reset } = this.props;

		if (this.props.onReset) {
			this.props.onReset();
		}
		else {
			reset();
		}
	}

	handleDeliveryAddressChange = (event, newValue, previousValue) => {
		this.updateDeliveryAddressDistance(newValue);
	}

	/** Update delivery address distance. Call is debounced to handle fast changes in form field or switching between customer's multiple addresses. */
	updateDeliveryAddressDistance = _.debounce((distance) => {
		const { calculateDeliveryAddressDistance, clearDeliveryAddressDistance } = this.props;

		// first clear current distance
		clearDeliveryAddressDistance();

		if (distance != null) {
			if (distance.length >= MIN_ADDRESS_CALCULATE_LENGTH) {
				// then calculate a new one
				calculateDeliveryAddressDistance(distance);
			}
		} else {
			console.log("Distance is undefined")
		}

	}, ADDRESS_CHANGE_DEBOUNCE_TIMEOUT);

	render() {
		const { handleSubmit, load, pristine, reset, submitting, loadedCall } = this.props;
		if (loadedCall !== null) {
			var call_well = <Well bsSize="small"><center><Glyphicon glyph="earphone" /> {loadedCall.caller_number}</center></Well>
		} else {
			var call_well = ''
		}
		return (
			<form onSubmit={handleSubmit(this.handleSubmit)}>
				<h5>PODACI O NARUČITELJU:</h5>
				{call_well}
				{/* TODO: refactor order form - group address fileds under "customer_address" group/section (see FormSection); 
				  * problem is that form is reinitialized from various middlewares so that must be covered too;
				  * NOTE: those middleware changes do trigger form update/change events
				  */}
				<Field component={FieldInput} name="address" placeholder="Adresa" glyphIcon="home" onChange={this.handleDeliveryAddressChange} />
				<Field component={FieldInput} name="floor" placeholder="Kat" glyphIcon="bell" />
				<Field component={FieldInput} name="name" placeholder="Kupac" glyphIcon="user" />
				<Field component={FieldInput} name="contact_phone" placeholder="Kontakt telefon" glyphIcon="phone-alt" />
				<Field component={FieldInput} name="extra" placeholder="Napomena za dostavu" autoComplete="off" glyphIcon="exclamation-sign" />

				<Field component={FieldInput} name="orderNote" placeholder="Napomena za kuhinju" autoComplete="off" glyphIcon="exclamation-sign" />

				<Button
					type="button"
					disabled={submitting}
					onClick={this.handleReset}
					bsStyle="info"
				>PONIŠTI</Button>
				&nbsp;
				<Button type="submit"
					disabled={submitting}
					bsStyle="success"
				>NARUČI</Button>
			</form>
		)
	}
}


CustomerForm = reduxForm({
	form: 'orderform',
	destroyOnUnmount: false,
	validate
})(CustomerForm)

export default CustomerForm;
