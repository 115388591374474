import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

// UI components
import { Button, OverlayTrigger, Tooltip, Col } from 'react-bootstrap'

const MenuItemUI = props => {
    const { item, click_action, selected_item, cart_part_items } = props

    const tooltip = (
        <Tooltip id={`tooltip_${item.id}`}>
            <strong>{item.description || item.name}</strong>
        </Tooltip>
    );

    return (
        <Col md={4} lg={4} onClick={(e) => {item.active && click_action(item, selected_item, cart_part_items)}} style={{paddingRight: 2, paddingLeft: 2}}>
            <OverlayTrigger
                delayShow={1000}
                delayHide={200}
                placement="top" overlay={tooltip}>

                <div style={{
                        // marginBottom: 4, 
                        marginTop: 4, 
                        padding: 1, 
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 10,
                        borderBottomLeftRadius: 4,
                        borderBottomRightRadius: 4,
                        border: 2,
                        backgroundColor: item.active? '#FFD9D4' : '#e5c5e0',
                        fontFamily: "Trebuchet MS",
                        cursor: item.active? 'pointer' : 'not-allowed' ,
                        paddingTop: 5,
                        paddingBottom: 5,

                        borderStyle: 'solid',
                        // borderLeftWidth: 4,
                        // borderTopWidth: 4,
                        // borderLeftColor: 'transparent',
                        borderTopColor: '#cec4ca',
                        borderLeftColor: '#cec4ca',
                        display: "flex",
                    }}>

                    <span style={{marginLeft: 10, width: '100%' }}>
                        {_.toUpper(item.name)}
                    </span>
                    <span style={{textAlign: 'right', marginRight: 10}}>
                        ({item.price})
                    </span>
                </div>
                
            </OverlayTrigger>
        </Col>
    );
}

MenuItemUI.propTypes = {
    item: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired, 
            short_name: PropTypes.string,
            description: PropTypes.string,
            price: PropTypes.number.isRequired,
            active: PropTypes.bool.isRequired,
            combo: PropTypes.bool.isRequired,
            option: PropTypes.bool.isRequired,
            visible_online: PropTypes.bool.isRequired,
            ui_color: PropTypes.number.isRequired,
    }).isRequired,
    click_action: PropTypes.func.isRequired,
    selected_item: PropTypes.string.isRequired
}

export default MenuItemUI