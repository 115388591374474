import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

// UI components
import { Grid, Col, Row, Glyphicon } from 'react-bootstrap'


export const CartItemUI = props => {
    const {
        select_action,
        remove_item,
        remove_addon,
        item,
        selected_item,
        addon,
        parent_uuid,
    } = props

    if (item === undefined) {
        return 'Loading ...'
    }
    if (addon) {
        var remove_action = remove_addon
    } else {
        var remove_action = remove_item
    }
    const isSelected = selected_item === item.uuid;

    const itemEffect = {
        'hcf-cart__item': true,
        // TODO: add other item effects, eg. "selected"
    }

    return (
        <Grid fluid>
            <Row key={item.uuid} className={classNames(itemEffect)}>
                <Col className="container-flex">
                    {
                        (isSelected) &&
                        <span style={{ marginLeft: -20, marginRight: 5 }}><Glyphicon glyph='hand-right'></Glyphicon></span> ||
                        <span style={{ marginLeft: -20, marginRight: 15 }}>&nbsp;</span>
                    }
                    <span onClick={() => { !addon && select_action(item.uuid) }} style={{ width: '100%', fontWeight: 'regular', cursor: 'pointer', backgroundColor: item.gratis ? '#f9f9f9' : undefined }}>
                        {
                            addon &&
                            <span style={{ marginRight: 15 }}>
                                &nbsp;
                            </span>
                        }
                        {item.name}
                        {
                            item.gratis &&
                            <span> / GRATIS</span> ||
                            <span> / {item.price} kn</span>
                        }

                    </span>
                    <span onClick={() => remove_action(item, parent_uuid)} style={{ textAlign: 'right', cursor: 'pointer' }}>
                        <Glyphicon glyph='remove-sign'></Glyphicon>
                    </span>
                </Col>
            </Row>
        </Grid>
    )
}

export default CartItemUI