import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

// Actions
import { updateDeliveryMan, printDeliveryManCard } from '../actions/deliveryman'

// UI components
import { Grid, Row, Col, Panel, } from 'react-bootstrap'

// Selector
import deliverymanSelected from '../selectors/deliveryman_edit'

// Form components
import DeliveryManForm from '../components/Form/DeliveryManForm'


class DeliveryManEdit extends Component {
    render() {
        const { updateDeliveryMan, deliveryman, AgentDestinations, printDeliveryManCard } = this.props

        if (deliveryman === undefined ) {
            return 'Loading ...'
        }
        
        return (
            <Grid>
                <Row>
                    <Col sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Uredi podatke o dostavljaču</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <DeliveryManForm 
                                    initData={deliveryman} 
                                    submit_action={updateDeliveryMan} 
                                    print_action={printDeliveryManCard}
                                    destinations={AgentDestinations} 
                                />
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        deliveryman: deliverymanSelected(state, props),
        AgentDestinations: state.AgentLocations,
    }
}

export default (connect(mapStateToProps, { updateDeliveryMan, printDeliveryManCard })(DeliveryManEdit));
