import { createSelector } from 'reselect'
import _ from 'lodash'

const getDeliveryman = (state, props) => state.Deliveryman
const getUrlDeliverymanId = (_ , props) => props.match.params.deliveryman_id

const getDeliverymanEdit = createSelector(
    [getUrlDeliverymanId, getDeliveryman], 
    (deliveryman_id, deliveryman) => {
        return deliveryman[deliveryman_id]
    }
)

export default getDeliverymanEdit