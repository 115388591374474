import React from 'react';
import moment from 'moment';

import DatePickerUI from './DatePickerUI'

const TIMESTAMP_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DISPLAY_DATE_FORMAT = 'HH:mm DD.MM.YYYY. ';

export default class OrderDelayTime extends React.Component {

	render() {
		return (

			<React.Fragment>
				<h6>ZA VRIJEME:</h6>
				<DatePickerUI
					value={this.props.value}
					showTimeSelect={true}
					dateFormat={DISPLAY_DATE_FORMAT}
					isClearable={true}

					onChange={this.handleDateChange}
				/>
			</React.Fragment>

		);
	}

	handleDateChange = (date) => {
		if (this.props.onChange) {
			// clear secs and millis and form date, or set null
			let formattedDate = date != null ? date.seconds(0).milliseconds(0).format(TIMESTAMP_DATE_FORMAT) : null

			this.props.onChange(formattedDate);
		}
	}
}
