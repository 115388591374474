import axios from "axios"

export const FETCH_STATS_LOCATION_BALANCE = "[API] Fetch location balance stats";
export const FETCH_STATS_DELIVERYMEN_BALANCE = "[API] Fetch delivermen balance stats";
export const FETCH_STATS_ITEMS_SOLD = "[API] Fetch items sold stats";

const ROOT_URL = "/api"

export function fetchLocationBalanceStats(fromDate, toDate) {
  const formated_from_date = fromDate.format('YYYY/MM/DD')
  const formated_to_date = toDate.format('YYYY/MM/DD')
  const request = axios.get(`${ROOT_URL}/stats/balance/from/${formated_from_date}/to/${formated_to_date}`)

  return {
    type: FETCH_STATS_LOCATION_BALANCE,
    payload: request
  }
}

export function fetchDeliveryMenStats(fromDate, toDate) {
  const formated_from_date = fromDate.format('YYYY/MM/DD')
  const formated_to_date = toDate.format('YYYY/MM/DD')
  const request = axios.get(`${ROOT_URL}/stats/delivery_man/from/${formated_from_date}/to/${formated_to_date}`)

  return {
    type: FETCH_STATS_DELIVERYMEN_BALANCE,
    payload: request
  }
}

export function fetchItemsSoldStats(fromDate, toDate) {
  const formated_from_date = fromDate.format('YYYY/MM/DD')
  const formated_to_date = toDate.format('YYYY/MM/DD')
  const request = axios.get(`${ROOT_URL}/stats/items_sold/from/${formated_from_date}/to/${formated_to_date}`)

  return {
    type: FETCH_STATS_ITEMS_SOLD,
    payload: request
  }
}
