import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

import { viewUIcallRecordsFrom, viewUIdeliveriesFrom } from '../../actions/UI'

// selectors
import { getCallRecordsForDate, getUIviewCallDate } from '../../selectors/calls'
import { getAgentMap } from '../../selectors/agent';

// UI
import CallRecordsTable from '../../components/UI/CallRecordsTable'
import DatePickerUI from '../../components/UI/DatePickerUI'
import { getDeliveries } from '../../selectors/delivery';
import moment from 'moment';


const mapStateToProps = (state, props) => {
    return {
        callDate: getUIviewCallDate(state),
        calls: getCallRecordsForDate(state, props),
        agentsMap: getAgentMap(state),
        deliveriesMap: getDeliveries(state),
    }
}

class CallList extends Component {

    render() {
        const { callDate, calls, agentsMap, deliveriesMap } = this.props

        return (
            <div>
                <div className="hcf-containers__commandContainer">
                    <Form inline onSubmit={(e) => e.preventDefault()}>
                        {/* ----- date filter ----- */}
                        <FormGroup>
                            <ControlLabel>Pozivi dana:&nbsp;</ControlLabel>
                            <FormControl componentClass={DatePickerUI} value={callDate} onChange={this.handleFormChange} />
                        </FormGroup>
                    </Form>
                </div>

                <div>&nbsp;</div>

                <CallRecordsTable calls={calls} agents={agentsMap} deliveries={deliveriesMap} />
            </div>
        );
    }

    handleFormChange = (selectedDate) => {
        const { viewUIcallRecordsFrom, viewUIdeliveriesFrom } = this.props

        viewUIcallRecordsFrom(selectedDate);
        viewUIdeliveriesFrom(selectedDate);
    }
}

export default connect(
    mapStateToProps, {
    viewUIcallRecordsFrom,
    viewUIdeliveriesFrom
}
)(CallList);