import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

// Actions
import { updateMenuGroup } from '../../actions/menu/group'

// Selectors
import groupComplementSelector from '../../selectors/group_complement'
import groupSelected from '../../selectors/group_edit'
import groupMembers from '../../selectors/group_member'

// UI components

import MenuGroupTable from '../../components/UI/MenuGroupTable'
import MenuItemList from '../../components/UI/MenuItemList'
import TargetButtonGroup from '../../components/UI/TargetButtonGroup'
import { Grid, Row, Col, Panel, } from 'react-bootstrap'

// Form components
import MenuGroupForm from '../../components/Form/MenuGroupForm'


class MenuGroupEdit extends Component {
    render() {
        const { group, group_items, group_complements, items } = this.props
        const { updateMenuGroup } = this.props
        const { menu_group_id } = this.props.match.params;
        if (group === undefined || group_items === undefined || group_complements === undefined) {
            return 'Loading...'
        }
        
        return (
            <Grid fluid>
                <Row className="show-grid">
                    <Col md={6} lg={3}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Uredi grupu</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <MenuGroupForm key={group.id} initData={group} submit_action={updateMenuGroup} />
                            </Panel.Body>
                        </Panel>
                    </Col>
                    <Col md={6} lg={4}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Povezne grupe (dodatci, veličine, ...)</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <MenuGroupTable groups={group_complements} items={items} />
                            </Panel.Body>
                            <Panel.Footer>
                                    <TargetButtonGroup 
                                        target={group} 
                                        link_text='NOVA POVEZNA GRUPA'
                                        tooltip_text='Napravi novu grupu koja koja će nadopunjavati stavke ove grupe'
                                        target_url = {`/menu/group/${group.id}/complement/group/create`}
                                    />
                            </Panel.Footer>
                        </Panel>
                    </Col>
                    <Col md={12} lg={5}>
                        <Panel>
                            <Panel.Heading>
                                    <Panel.Title componentClass="h3">Stavke u grupi </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                              <MenuItemList items={group_items} />
                            </Panel.Body>
                            <Panel.Footer>
                                    <TargetButtonGroup 
                                        target={group} 
                                        link_text='DODAJ STAVKU'
                                        tooltip_text='Dodaj novu stavku u grupu'
                                        target_url = {`/menu/group/${group.id}/items/add`}
                                    />
                            </Panel.Footer>
                        </Panel>
                    </Col>
                </Row>          
            </Grid>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        group: groupSelected(state, props),
        group_items: groupMembers(state, props),
        group_complements: groupComplementSelector(state, props),
        items: state.MenuItems,
    }
}

export default (connect(mapStateToProps, {updateMenuGroup, })(MenuGroupEdit));

