import React from 'react'
import { Row, Col, Panel } from 'react-bootstrap'

// Containers
import CartContainer from '../../containers/Cart'
import MenuTabsContainer from '../../containers/MenuTabs'
import ModalCustomerDataLoad from '../../containers/ModalCustomerDataLoad'
import OrderContainer from '../../containers/Order'

// UI Components
import GratisOption from '../../containers/GratisOption'
import DeliveryTypeContainer from '../../containers/DeliveryTypeContainer'
import DiscountSelector from '../../containers/DiscountSelector'


const TabletOrderView = (props) => {
	const { uiVisibleComponents } = props;

	return (
		<React.Fragment>
			<Row>
				<Col xs={12} sm={4}>
					<Row>
						<Col sm={12}>
							<DeliveryTypeContainer />
						</Col>
					</Row>
					{uiVisibleComponents.ui_gratis && (<Row>
						<Col sm={12}>
							<GratisOption />
						</Col>
					</Row>)}
					{uiVisibleComponents.ui_discount && (<Row>
						<Col sm={12}>
							<DiscountSelector />
						</Col>
					</Row>)}
					<Row>
						<Col sm={12}>
							<OrderContainer />
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<CartContainer />
						</Col>
					</Row>
				</Col>
				<Col xs={12} sm={8}>
					{/* Menu block */}
					<Panel>
						{/* <Panel.Heading className="text-center">
									Menu
							</Panel.Heading> */}
						<Panel.Body>
							<MenuTabsContainer />
						</Panel.Body>
					</Panel>
				</Col>
			</Row>

			<ModalCustomerDataLoad />
		</React.Fragment >
	)
}

export default TabletOrderView