import _ from 'lodash';

import { 
	PUSH_LOCATION,
	DELETE_LOCATION,
    GET_LOCATION } from '../../actions/websocket'


export default function (state = {}, action) {
	switch (action.type) {
        
		case GET_LOCATION:
			return _.mapKeys(action.payload.data, 'id');

		case DELETE_LOCATION:
            return _.omit(state, action.payload.data.id);

		case PUSH_LOCATION:
			return { ...state, [action.payload.data.id]: action.payload.data };

		default:
			return state;
	}
}
