
import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import { connect } from 'react-redux'

// Selectors
import getCartTotal from '../../selectors/cart/getCartTotal'
import getCartComboView from '../../selectors/cart/getCartComboView'
import cartPartGroupSelector from '../../selectors/cart_part_group'

// UI
import CartItemsUI from '../../components/UI/CartItemsUI'
import TotalCostUI from '../../components/UI/TotalCostUI'

// Actions
import {
    selectCartItem,
    removeCartItem,
    removeCartAddon,
    removeCartItemPart,
    removeCartPartAddon,
    removeCartLastItemPart,
} from '../../actions/cart'
import { getCartPartItemList, getCartPartMissingNumberOfParts } from '../../selectors/cart_part'
import { UngroupedCartItemsUI } from '../../components/UI/UngroupedCartItemsUI'


class CartContainer extends Component {
    render() {
        const {
            cart_items,
            selected_item,
            cart_total,
            cart_part_items,
            cart_part_missing_items_count,
            cart_part_group,
            cart_combo_view,
            combo_enabled } = this.props
        const {
            selectCartItem,
            removeCartItem,
            removeCartAddon,
            removeCartItemPart,
            removeCartPartAddon,
            removeCartLastItemPart, } = this.props

        /*
        * removeCartItemPart or removeCartLastItemPart depending on number of parts left in part cart
        */
        if (Object.keys(cart_part_items).length === 1) {
            var remove_part_item_action = removeCartLastItemPart
        } else {
            var remove_part_item_action = removeCartItemPart
        }

        return (
            <Panel>

                <Panel.Body>
                    <TotalCostUI total_cost={cart_total} combo_enabled={combo_enabled} />
                    <CartItemsUI
                        cart_items={cart_combo_view}
                        selected_item={selected_item}
                        select_action={selectCartItem}
                        remove_item={removeCartItem}
                        remove_addon={removeCartAddon}
                        combo_enabled={combo_enabled} />

                    {cart_part_group &&
                        <UngroupedCartItemsUI
                            cart_items={cart_part_items}
                            selected_item={selected_item}
                            select_action={selectCartItem}
                            remove_item={remove_part_item_action}
                            remove_addon={removeCartPartAddon}
                            combo_enabled={combo_enabled}
                            cart_part_missing_items_count={cart_part_missing_items_count}
                        />
                    }
                </Panel.Body>
            </Panel>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        cart_combo_view: getCartComboView(state),
        selected_item: state.cart.selectedItem,
        cart_total: getCartTotal(state),
        cart_part_group: cartPartGroupSelector(state),
        cart_part_items: getCartPartItemList(state),
        cart_part_missing_items_count: getCartPartMissingNumberOfParts(state),
        combo_enabled: state.viewUI.comboEnabled,
    }
}

export default connect(mapStateToProps, {
    selectCartItem,
    removeCartItem,
    removeCartAddon,
    removeCartItemPart,
    removeCartPartAddon,
    removeCartLastItemPart,
})(CartContainer)






