import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import UserForm from './Form/UserForm';


class UserView extends React.Component {

    render() {
        return (
            <Grid>
                <Row>
                    <Col sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <UserForm />
                    </Col>
                </Row>
            </Grid>
        );
    }
}


export default UserView
