import _ from 'lodash';

import { 
    PUSH_CHECKIN, 
    GET_CHECKIN, } from '../../actions/websocket'


export default function (state = {}, action) {
	switch (action.type) {
        case GET_CHECKIN:
            return _.mapKeys(action.payload.data, 'location');

		case PUSH_CHECKIN:
            return { ...state, [action.payload.data.location]: action.payload.data };
        
		default:
			return state;
	}
}
