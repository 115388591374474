import { createSelector } from 'reselect'
import _ from 'lodash'

const getCartPartGroupKey = (state, props) => state.cart.partialItems.group_key

const getCartPartKey = createSelector(
    [getCartPartGroupKey, ], 
    (cartPartGroupKey, ) => {
        return cartPartGroupKey
    }

)

export default getCartPartKey