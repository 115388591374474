
/**
 * Helpers for doing currency arithmetics and avoiding floating point number problems.
 */


const CURRENCY_MULTIPLIER = 100;

/** Add multiple currency values and return result. */
export function add(...c) {
	return c.reduce((accum, c) => {
		return ((accum * CURRENCY_MULTIPLIER) + (c * CURRENCY_MULTIPLIER)) / CURRENCY_MULTIPLIER;
	}, 0);
}


/** Subtract two currency values and return result. */
export function sub(c1, c2) {
	return (c1 * CURRENCY_MULTIPLIER - c2 * CURRENCY_MULTIPLIER) / CURRENCY_MULTIPLIER;
}


/** Multiply multiple currency values and return result. */
export function multiply(...c) {
	return c.reduce((accum, c) => {
		return ((accum * CURRENCY_MULTIPLIER) * (c * CURRENCY_MULTIPLIER)) / (CURRENCY_MULTIPLIER * CURRENCY_MULTIPLIER);
	}, 1);
}


/** Devide two currency values and return result. */
export function divide(c1, c2) {
	return (c1 * CURRENCY_MULTIPLIER) / (c2 * CURRENCY_MULTIPLIER);
}
