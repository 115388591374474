import moment from 'moment'
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, DropdownButton, MenuItem, FormGroup, ControlLabel, FormControl, Grid, Row, Col, } from 'react-bootstrap'

import FieldInput from './FieldInput'


const DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';

const isNumber = input => {
    return !isNaN( input );
}
const validate = values => {
    const errors = {}
    if(!values.name)  {
        errors.name = 'Unesite naziv dostavljača'
    }
    if(!values.vehicle)  {
        errors.vehicle = 'Unesite naziv ili opis vozila'
    }

    if(values.name && values.name.length > 20) {
        errors.name = "3 do 20 znakova"
    }
    if(values.name && values.name.length < 3) {
        errors.name = "3 do 20 znakova"
    }
    if(values.vehicle && values.vehicle.length > 20) {
        errors.vehicle = "3 do 20 znakova"
    }
    if(values.vehicle && values.vehicle.length < 3) {
        errors.vehicle = "3 do 20 znakova"
    }

    return errors
}


class DeliveryManForm extends Component {
    componentDidMount() {
        this.handleInitialize()
    }

    handleInitialize = (providedValues = null) => {
        const { initData } = this.props;
        const form_data = providedValues || initData
        this.props.initialize(form_data);
    }

    handleFormSubmit = (values) => {
        this.props.submit_action(values)
        this.props.reset()
        if ('id' in values) {
            // Initialize values only if we are updating existing item; and indicator if it exists is 'id' 
            this.handleInitialize(values)
        }

        this.navigateToList();
    }

    handleFormCancel = (e) => {
        e.preventDefault();

        this.props.reset();
        this.navigateToList();
    }

    navigateToList = () => {
        this.props.history.push('/deliveryman/list');
    }

    isNew = () => {
        return this.props.initData == null || this.props.initData.id == null;
    }


    render() {
        const { handleSubmit, pristine, reset, submitting, destinations, initData, print_action } = this.props;
        const destination_options = _.map(destinations, destination => {
            return <MenuItem key={destination.id} onClick={() => print_action(initData.id, destination.id)}>
                        {destination.name}
                    </MenuItem>
        })

        const { created_at, modified_at, barcode } = this.props.initData
        const created_moment = created_at && moment(created_at)
        const modified_moment = modified_at && moment(modified_at)
        return (
            <form onSubmit={handleSubmit(this.handleFormSubmit)} >
                <Field component={FieldInput} name="name"
                        label="Ime i prezime"
                        type="string"
                        glyphIcon="user"/>
                <Field component={FieldInput} name="vehicle" 
                        label="Vozilo"
                        type="string" 
                        glyphIcon="transfer"/>
                <Field component={FieldInput} name="active"
                        label="Aktivan"
                        type="checkbox" />

                {!this.isNew() && (
                    <React.Fragment>
                        <FormGroup>
                            <ControlLabel>Bar kod:</ControlLabel>
                            <FormControl.Static>{barcode}</FormControl.Static>
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>Izmijenjen:</ControlLabel>
                            <FormControl.Static>{modified_moment ? modified_moment.format(DATE_FORMAT) : '-'}</FormControl.Static>
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>Kreiran:</ControlLabel>
                            <FormControl.Static>{created_moment ? created_moment.format(DATE_FORMAT) : '-'}</FormControl.Static>
                        </FormGroup>
                    </React.Fragment>
                )}

                <Row>
                    <Col xs={6}>
                        <Button
                            type="button" 
                            disabled={submitting} 
                            onClick={this.handleFormCancel}
                            bsStyle="info"
                        > PONIŠTI </Button>
                        <Button type="submit"
                            disabled={pristine || submitting} 
                            bsStyle="success"
                        >{this.props.initData.id ? 'SNIMI' : 'DODAJ'} </Button>
                    </Col>
                    <Col  xs={6} className="text-right">
                        {!this.isNew() && (
                            <DropdownButton id="dropdown-print-deliveryman1" title="ISPIS">
                                {destination_options}
                            </DropdownButton>
                        )}
                    </Col>
                </Row>
            </form>
        )
    }
}

export default reduxForm({
    form: 'deliveryman_form',
    destroyOnUnmount: true,
    validate
})(withRouter(DeliveryManForm))
