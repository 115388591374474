import React, { useCallback, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table, Badge, Button } from 'react-bootstrap'

// UI components
import { RecordingPlayer } from '../common/RecordingPlayer'
import DeliveryTicketModal from '../DeliveryTicketModal'

const CALL_STATUS = {
    INCOMING: 'INCOMING',
    IN_PROGRESS: 'IN_PROGRESS',
    MISSED: 'MISSED',
    ANSWERED: 'ANSWERED',
    CONVERTED: 'CONVERTED',
    UNKNOWN: 'UNKNOWN',
};

const CallRecordsTable = props => {
    const [selectedDelivery, setSelectedDelivery] = useState();
    const [showDeliveryTicketModal, setShowDeliveryTicketModal] = useState();

    const handleDeliveryTicketButtonClick = useCallback((delivery) => {
        setSelectedDelivery(delivery);
        setShowDeliveryTicketModal(true);
    });

    const handleDeliveryTicketDialogHide = useCallback(() => {
        setShowDeliveryTicketModal(false);
    });

    if (props.calls === undefined) {
        return 'Loading calls...'
    }
    if (props.agents === undefined) {
        return 'Loading agents...'
    }

    const { calls, agents, deliveries } = props;
    const table_body = _.map(calls, call => {
        const {
            id,
            uuid,
            incoming_at,
            answered_at,
            finished_at,
            agent,
            agent_count,
            recording_url,
            caller_number,
            customer,
            delivery: callDeliveries,
        } = call;

        const incoming_moment = moment(incoming_at)
        const answer_moment = moment(answered_at)
        const finished_moment = moment(finished_at)

        if (answer_moment.isValid()) {
            var wait_duration = moment.duration(answer_moment.diff(incoming_moment)).asSeconds()
            var player_block = <RecordingPlayer recordingUrl={recording_url} />;
        } else {
            var wait_duration = '-';
            var player_block = '-';
        }

        if (finished_moment.isValid()) {
            var call_duration = moment.duration(finished_moment.diff(incoming_moment)).asSeconds();
        } else {
            var call_duration = '-';
        }

        if (agent && agents && agents[agent]) {
            var { agent_name } = agents[agent]
        } else {
            var agent_name = '-'
        }

        // take the first delivery form array - this will be refactored to a single value instead of an array
        const deliveryId = callDeliveries != null && callDeliveries.slice(0, 1).shift();
        const deliveryInst = deliveryId != null && deliveries != null && deliveries[deliveryId];

        // call status
        const callStatus = detectCallStatus(call);

        let rowEffect;
        let isConverted = false;
        if (callStatus === CALL_STATUS.INCOMING) {
            rowEffect = 'warning';
        }
        else if (callStatus === CALL_STATUS.IN_PROGRESS) {
            rowEffect = 'success';
        }
        else if (callStatus === CALL_STATUS.MISSED) {
            rowEffect = 'danger';
        }
        else if (callStatus === CALL_STATUS.ANSWERED) {
            rowEffect = '';
        }
        else if (callStatus === CALL_STATUS.CONVERTED) {
            rowEffect = '';
            isConverted = true;
        }
        else {
            rowEffect = 'info'; // defaults to unknown - this should not happen, but lets make it visible
        }

        return (
            <tr key={id} className={rowEffect}>
                <td>
                    {isConverted ? (
                        <Badge>{id}</Badge>
                    ) : (
                        <React.Fragment>
                            {id}
                        </React.Fragment>
                    )}
                </td>
                <td className="text-center">{caller_number}</td>
                <td className="text-center">{incoming_moment.format('HH:mm:ss')}</td>
                <td className="text-center">{wait_duration}</td>
                <td className="text-center">{call_duration}</td>
                <td className="text-center">{agent_count}</td>
                <td className="text-center">{agent_name}</td>
                <td className="text-center">
                    {deliveryInst ?
                        <Button onClick={() => handleDeliveryTicketButtonClick(deliveryInst)}>Narudžba</Button>
                        :
                        '-'
                    }
                </td>
                <td className="text-center">{player_block}</td>
            </tr>
        )
    })

    const selectedDeliveryAgent = selectedDelivery != null && selectedDelivery.call != null && selectedDelivery.call.agent;
    const selectedDeliveryAgentName = selectedDeliveryAgent != null && agents[selectedDeliveryAgent] != null ? agents[selectedDeliveryAgent].agent_name : '-';

    return (
        <React.Fragment>
            <Table bordered condensed hover responsive>
                <thead>
                    <tr>
                        <th>ID#</th>
                        <th className="text-center">Pozivatelj</th>
                        <th className="text-center">Vrijeme poziva</th>
                        <th className="text-center">Vrijeme do odgovora (s)</th>
                        <th className="text-center">Trajanje poziva (s)</th>
                        <th className="text-center">Aktivnih agenata</th>
                        <th className="text-center">Agent</th>
                        <th className="text-center">Narudžba</th>
                        <th className="text-center" style={{ maxWidth: '200px' }}>Snimka</th>
                    </tr>
                </thead>
                <tbody>
                    {table_body}
                </tbody>
            </Table>

            {/* modal is shown by default, it's the caller's responsibility to close it on "hide" or other business events */}
            {selectedDelivery && showDeliveryTicketModal && (
                <DeliveryTicketModal
                    delivery={selectedDelivery}
                    agentName={selectedDeliveryAgentName}
                    onHide={handleDeliveryTicketDialogHide}
                />
            )}
        </React.Fragment>
    );
}

function detectCallStatus(call) {
    if (call.incoming_at != null && call.answered_at == null && call.finished_at == null) {
        return CALL_STATUS.INCOMING;
    }
    else if (call.incoming_at != null && call.answered_at != null && call.finished_at == null) {
        return CALL_STATUS.IN_PROGRESS;
    }
    else if (call.incoming_at != null && call.answered_at == null && call.finished_at != null) {
        return CALL_STATUS.MISSED;
    }
    else if (call.incoming_at != null && call.answered_at != null && call.finished_at != null && call.delivery.length === 0) {
        return CALL_STATUS.ANSWERED;
    }
    else if (call.incoming_at != null && call.answered_at != null && call.finished_at != null && call.delivery.length > 0) {
        return CALL_STATUS.CONVERTED;
    }
    else {
        return CALL_STATUS.UNKNOWN;
    }
}


PropTypes.CallRecordsTable = {
    calls: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        uuid: PropTypes.string.isRequired,
        incoming_at: PropTypes.instanceOf(Date),
        answered_at: PropTypes.instanceOf(Date),
        finished_at: PropTypes.instanceOf(Date),
        caller_number: PropTypes.string.isRequired,
        agent: PropTypes.number,
        agent_count: PropTypes.number,
        active: PropTypes.bool.isRequired,
    })).isRequired,
    agents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        agent_name: PropTypes.string.isRequired,
        agent_key: PropTypes.string.isRequired,
    }))
}

export default CallRecordsTable