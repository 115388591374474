import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'

// UI components
import { Table, Button } from 'react-bootstrap'
import DeliveryTicketModal from '../DeliveryTicketModal';


const DATE_FORMAT = 'HH:mm:ss';

class DeliveriesTable extends React.Component {
    state = {
        selectedDelivery: null,
        showDeliveryTicketModal: false,
    };

    render() {
        const { deliveryList, locationsMap, deliverymenMap, agentMap } = this.props;

        const table_body = _.map(deliveryList, delivery => {
            const {
                id,
                uuid,
                canceled,
                printed,
                created_at,
                dispatched_at,
                canceled_at,
                customer_address,
                location,
                call,
                deliveryman
            } = delivery;

            const created_moment = moment(created_at)
            const dispatched_moment = dispatched_at && moment(dispatched_at)
            const canceled_moment = canceled_at && moment(canceled_at)
            const is_canceled = canceled;
            const isPrinted = printed;

            // TODO: display agent name

            let location_name = '-';
            if (location && locationsMap[location.id]) {
                location_name = locationsMap[location.id].name;
            }

            const agent_name = call != null && call.agent != null && agentMap[call.agent] != null ? agentMap[call.agent].agent_name : '-';
            const deliveryman_name = deliveryman != null && deliverymenMap[deliveryman] != null ? deliverymenMap[deliveryman].name : '-';

            const customer_name = customer_address && customer_address.name || '-';
            const customer_contact = customer_address && customer_address.contact_phone || customer_address.customer && customer_address.customer.phone_number && customer_address.customer.phone_number.phone_number || '-';

            // row effect
            const rowEffects = {
                'warning': is_canceled,
                'danger': !isPrinted,
            };

            return (
                <tr key={id} className={classNames(rowEffects)}>
                    <td>
                        {id}
                    </td>
                    <td className="text-center">{customer_name}</td>
                    <td className="text-center">{customer_contact}</td>
                    <td className="text-center">{created_moment ? created_moment.format(DATE_FORMAT) : '-'}</td>
                    {is_canceled ? (
                        <td className="text-center">{canceled_moment ? canceled_moment.format(DATE_FORMAT) : '-'}</td>
                    ) : (
                        <td className="text-center">{dispatched_moment ? dispatched_moment.format(DATE_FORMAT) : '-'}</td>
                    )}
                    <td className="text-center">{agent_name}</td>
                    <td className="text-center">{deliveryman_name}</td>
                    <td className="text-center">{location_name}</td>
                    <td className="text-center">
                        <Button onClick={() => this.handleDeliveryTicketButtonClick(delivery)}>Narudžba</Button>
                    </td>
                </tr>

            )
        })

        const selectedDeliveryAgent = this.state.selectedDelivery != null && this.state.selectedDelivery.call != null && this.state.selectedDelivery.call.agent;
        const selectedDeliveryAgentName = selectedDeliveryAgent != null && agentMap[selectedDeliveryAgent] != null ? agentMap[selectedDeliveryAgent].agent_name : '-';

        return (
            <React.Fragment>
                <Table striped bordered condensed hover responsive>
                    <thead>
                        <tr>
                            <th>ID#</th>
                            <th className="text-center">Naručitelj</th>
                            <th className="text-center">Kontakt</th>
                            <th className="text-center">Zaprimljeno</th>
                            <th className="text-center">Isporučeno</th>
                            <th className="text-center">Agent</th>
                            <th className="text-center">Dostavljač</th>
                            <th className="text-center">Lokacija</th>
                            <th className="text-center">Listić</th>
                        </tr>
                    </thead>
                    <tbody>
                        {table_body}
                    </tbody>
                </Table>

                {/* modal is shown by default, it's the caller's responsibility to close it on "hide" or other business events */}
                {this.state.selectedDelivery && this.state.showDeliveryTicketModal && (
                    <DeliveryTicketModal
                        delivery={this.state.selectedDelivery}
                        agentName={selectedDeliveryAgentName}
                        onHide={this.handleDeliveryTicketDialogHide}
                    />
                )}
            </React.Fragment>
        );
    }

    handleDeliveryTicketButtonClick = (delivery) => {
        this.selectDelivery(delivery);
        this.showDeliveryTicketDialog();
    }

    handleDeliveryTicketDialogHide = () => {
        this.hideDeliveryTicketDialog();
    }


    selectDelivery(delivery) {
        this.setState({
            selectedDelivery: delivery,
        });
    }

    showDeliveryTicketDialog() {
        this.setState({
            showDeliveryTicketModal: true,
        });
    }

    hideDeliveryTicketDialog() {
        this.setState({
            showDeliveryTicketModal: false,
        });
    }
}

export default DeliveriesTable