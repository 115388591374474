import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

// Actions
import { 
    addCartItem, 
    addCartAddon,
    addCartItemPart, 
    addCartLastItemPart,
    addCartPartAddon, } from '../../actions/cart'

import displayGroupsSelector from '../../selectors/display_group'
import cartPartCountSelector from '../../selectors/cart_part_count'
import cartPartGroupSelector from '../../selectors/cart_part_group'
import cartPartItemsSelector from '../../selectors/cart_part_items'

// UI components
import { Tabs, Tab } from 'react-bootstrap'
import MenuItemUI from '../../components/UI/MenuItemUI'

class MenuTabsContainer extends React.Component {
    render() {
        const { groups, items, group_members, selected_item, last_part_bool, cart_part_items, cart_part_group } = this.props;
        const { addCartAddon, addCartItem, addCartItemPart, addCartLastItemPart, addCartPartAddon } = this.props;
        
        const tab_contents = _.map(groups, group => {
            const group_item_list = group_members[group.id] || [];
            const group_items = _.filter(items, function(item) {
                { 
                    return _.indexOf(group_item_list, item.id) > -1  
                }
            }).sort(function(item1, item2) {
                return item1.sort_id - item2.sort_id
            })

            // Deciding what action should be sent to MenuItemUI component
            if (group.base) {
                if (group.number_of_pieces > 1) {
                    // Pola - Pola ili vise dijelova opcije
                    if (last_part_bool) {
                        var click_action = addCartLastItemPart
                    } else {
                        var click_action = addCartItemPart
                    }
                }  else {
                    var click_action = addCartItem
                }
            // addCartItem OR addCartItemPart / addCartLastItemPart
            } else {
                if (cart_part_group) {
                    // cart_part_group is null if no part asembly is in progress
                    var click_action = addCartPartAddon
                } else {
                    var click_action = addCartAddon
                }
            }

            const tab_contents = _.map(group_items, item => {
                const augumentItem = Object.assign({
                    'group_id' : group.id,
                    'price_algorithm_max' : group.price_algorithm_max,
                    'number_of_pieces' : group.number_of_pieces, }, item)

                // Here is good place to augument with minParts property for POLA / POLA stuff
                return <MenuItemUI 
                        key = {augumentItem.id} 
                        item = {augumentItem} 
                        click_action = {click_action}
                        selected_item = {selected_item}
                        cart_part_items = {cart_part_items}
                    />
            })

            return (
                <Tab key={group.id} eventKey={group.id} title={group.name}> 
                    {tab_contents}
                </Tab>  
            );
        })

        return (
            <Tabs defaultActiveKey={1} animation={false} id="MenuTabsContainer-container">
                {tab_contents}
            </Tabs>
        )
    }
}


const mapStateToProps = (state, props) => {
    return {
        groups: displayGroupsSelector(state),
        last_part_bool: cartPartCountSelector(state),
        cart_part_group: cartPartGroupSelector(state),
        cart_part_items: cartPartItemsSelector(state),
        items: state.MenuItems,
        group_members: state.MenuItemGroupMembers,
        selected_item: state.cart.selectedItem,
        cart_items: state.cart.cartItems
    }
}

export default connect(mapStateToProps, 
    {   
        addCartItem, 
        addCartAddon,
        addCartItemPart, 
        addCartLastItemPart,
        addCartPartAddon,
    })(MenuTabsContainer)
