import _ from 'lodash';
import { 
  FETCH_DELIVERIES,
  } from '../../actions/delivery';

import {
  PUSH_DELIVERY, 
  GET_DELIVERY_TODAY,
  GET_DELIVERY_DATE,
} from '../../actions/websocket'


export default function(state = {}, action) {
  switch (action.type) {
    /* when data arrives via XHR */
    case FETCH_DELIVERIES:
        const newDeliveries = _.mapKeys(action.payload.data, 'id')
        return { ...state, ...newDeliveries }

    case GET_DELIVERY_TODAY:
      const todaysDeliveries = _.mapKeys(action.payload.data, 'id')
      return { ...state, ...todaysDeliveries }

    case GET_DELIVERY_DATE:
      const dateDeliveries = _.mapKeys(action.payload.data, 'id')
      return { ...state, ...dateDeliveries }

    case PUSH_DELIVERY:
        return { ...state, [action.payload.data.id]: action.payload.data };

    default:
        return state;
  }
}
