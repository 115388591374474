import axios from "axios"

export const CREATE_DELIVERYMAN = "/POST/deliveryman";
export const UPDATE_DELIVERYMAN = "/PUT/deliveryman";
export const PRINT_DELIVERYMAN = "/PRINT/deliveryman";


const ROOT_URL = "/api"

export function createNewDeliveryMan(values) {
    const request = axios.post(`${ROOT_URL}/deliveryman/`, values);
    return {
        type: CREATE_DELIVERYMAN,
        payload: request
    }
}

export function updateDeliveryMan(values) {
    const request = axios.put(`${ROOT_URL}/deliveryman/${values.id}`, values);
    return {
        type: UPDATE_DELIVERYMAN,
        payload: request
    }
}

export function printDeliveryManCard(deliveryman_id, location_id, ) {
    const request = axios.post(`${ROOT_URL}/deliveryman/print_card/action/`, {
        "location_id" : location_id,
        "deliveryman_id" : deliveryman_id
    });
    return {
        type: PRINT_DELIVERYMAN,
        payload: request
    }
}

