import React, { Component } from 'react'
import { connect } from 'react-redux'

// Actions
import { updateMenuItem, } from '../../actions/menu/item'

// Selectors
import itemSelected from '../../selectors/item_edit'
import itemComplementSelector from '../../selectors/item_complement'
import groupSelected from '../../selectors/group_edit'

// Form components
import MenuItemForm from '../../components/Form/MenuItemForm'

// UI components
import MenuGroupTable from '../../components/UI/MenuGroupTable'
import TargetButtonGroup from '../../components/UI/TargetButtonGroup'
import { Grid, Row, Col, Panel } from 'react-bootstrap'

class MenuItemEdit extends Component {
    render() {
        const { 
            item, 
            items,
            item_complements,
            updateMenuItem,
        } = this.props;

        if (item === undefined || item_complements === undefined || items == undefined) {
            return 'Loading ...'
        }

        return (
            <Grid>
                <Row>
                    <Col md={6}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Uredi menu stavku</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <MenuItemForm initData={item} submit_action={updateMenuItem} />
                            </Panel.Body>
                        </Panel>
                    </Col>
                    <Col md={6}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Povezne grupe (dodatci, veličine, ...)</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <MenuGroupTable groups={item_complements} items={items} />
                            </Panel.Body>
                            <Panel.Footer>
                                    <TargetButtonGroup 
                                        target={item} 
                                        link_text='NOVA POVEZNA GRUPA'
                                        tooltip_text='Napravi novu grupu koja koja će nadopunjavati stavke ove grupe'
                                        target_url = {`/menu/item/${item.sort_id}/complement/group/create`}
                                    />
                            </Panel.Footer>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
const mapStateToProps = (state, props) => {
    return {
        item: itemSelected(state, props),
        item_complements: itemComplementSelector(state, props),
        items: state.MenuItems,
    }
}
export default connect(mapStateToProps, { updateMenuItem, })(MenuItemEdit);