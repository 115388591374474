import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { syncHistoryWithStore } from 'react-router-redux';

// init app first so it's available elsewhere
import './common/appInit';
import App from './containers/App';
import configureStore from './store/configureStore';
import routes from './routes';

// Custom CSS
import './style.scss';

// Bootstrap customization
//require('bootstrap-webpack');


const store = configureStore();
const history = syncHistoryWithStore(createBrowserHistory(), store);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App>
                {routes}
            </App>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

