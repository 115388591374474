import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import promise from 'redux-promise';
// import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
// import { init as websocketInit, emit } from '../actions/websocket'
import { init as wsInit } from '../middleware/websocket'
import { loadState, storeState } from '../common/storage';
import throttle from 'lodash/throttle';

// Middlewares
import { cartMdl } from '../middleware/cart'
import { uiMdl } from '../middleware/UI'
import { eventMdl } from '../middleware/events'
import { uiFeedbackMdl } from '../middleware/feedback'
import { uiWatchdogMdl } from '../middleware/watchdog'

const preloadedState = loadState();
const debugware = [];
const appMiddleware = [...cartMdl, ...uiMdl, ...eventMdl, ...uiFeedbackMdl, ...uiWatchdogMdl ]

if (process.env.NODE_ENV !== 'production') {
    const createLogger = require('redux-logger').createLogger;

    debugware.push(createLogger({
        collapsed: true,
    }));
}


export default function configureStore(initialState) {
    //const middleware = [ ...cartMdl, promise, thunkMiddleware, thunkMiddleware.withExtraArgument({ emit }) ]
    const middleware = [ ...appMiddleware, promise, thunkMiddleware ]
    

    const store = createStore(
        rootReducer, /* preloadedState, */
        initialState,
        composeWithDevTools(
            applyMiddleware(...middleware, ...debugware)
        )
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers/index').default;

            store.replaceReducer(nextRootReducer);
        });
    }

    wsInit(store);

    store.subscribe(throttle(() => {
        storeState({form: store.getState().form});
        /*
        To store whole state use following
        storeState(store.getState());
        */
    }, 3000))

    return store;
}
