import React from 'react'
import PropTypes from 'prop-types'
import Barcode from 'react-barcode'
import moment from 'moment'
import _ from 'lodash'
import './ticket.css'

const DeliveryTicket = props => {
    /**
     * MenuItems maps to state.MenuItems should be provided to this component
     * business_name is string just pass "FooBar" or something for now
     * data is JSON as received from API for given delivery I left static data in comment bellow
    */

    const { MenuItems, business_name, data } = props

    return (
        <React.Fragment>
            <section className="header noselect">
                {
                    data.delayed_to &&
                    <React.Fragment>
                        <div className="row">
                            <p className="delayed noselect">{data.delayed_to}</p>
                        </div>
                        <hr class="break noselect"></hr>
                    </React.Fragment>
                }
                {
                    data.cc_payment &&
                    <React.Fragment>
                        <div className="row">
                            <p className="credit_card noselect">Plaćanje karticom</p>
                        </div>
                        <hr class="break noselect"></hr>
                    </React.Fragment>
                }
            </section>
            <section className="order noselect">
            {
                _.map(data.orders, order => {
                        var order_count_text = order.count > 1 ? order.count + 'x ' : ''
                        var order_block = (
                            <div className="row">
                                <p className="dish noselect">{order_count_text}{MenuItems[order.menu_item].name} {order.gratis && '/ GRATIS' || '(' + order.count * MenuItems[order.menu_item].price + ' kn)'}</p>
                            </div>
                        )
                        var addons_block = _.map(order.addons, addon => {
                            var addon_count_text = addon.count > 1 ? addon.count + 'x ' : ''
                            return (
                                <div className="row" key={addon.id}>
                                    <p className="addon noselect">{addon_count_text}{MenuItems[addon.menu_item].name} {'(' + order.count * addon.count * MenuItems[addon.menu_item].price + ' kn)'} </p>
                                </div>
                            )
                        })
                        var note_block =                     
                                <div className="row">
                                    <p className="dish_note noselect">{order.note}</p>
                                </div>

                        return <React.Fragment key={order.id}>
                            {order_block}
                            {addons_block}
                            {order.note && order.note.length > 0 && note_block}
                        </React.Fragment>
                    })
            }
            
            </section>

            <hr className="break noselect"></hr>

            <section className="business noselect">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="row">
                            <img className="logo" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIgAAACJAQAAAADfnU1aAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAd2KE6QAAAAJb0ZGcwAAAhUAAAFXAOqWlWsAAAAJdnBBZwAABkAAAAOEACTzYUkAAABCSURBVEjHY/iPDhhoJ/KDAQnUj4oMSRFmeHw2MDDYDwaRB2D3sY+KjIqMigyQyGArEwa1CJD+g1y0jooMQRF6tZoA+WuWhVWhhI0AAAAASUVORK5CYII=" />
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="row">
                            <p className="business_name noselect">{business_name}</p>
                        </div>
                        <div className="row">
                            <p className="order_no noselect">{data.id}</p>
                        </div>
                        <div className="row">
                            <p className="timestamp noselect">{moment(data.created_at).format('HH:mm:ss')}</p>
                        </div>
                    </div>
                </div>
            </section>


            <hr className="break noselect"></hr>

            <section className="customer noselect">
                {
                    data.customer_address &&
                    <React.Fragment>
                        <div className="row">
                            <p className="customer noselect">{data.customer_address.name}</p>
                        </div>
                        <div className="row">
                            <p className="address noselect">{data.customer_address.address}</p>
                        </div>
                    </React.Fragment>
                }
                {
                    data.customer_address.floor && data.customer_address.floor.length > 0 &&
                    <React.Fragment>
                        <div className="row">
                            <p className="floor noselect">{data.customer_address.floor}</p>
                        </div>
                    </React.Fragment>
                }
                {
                    data.call && data.call.caller_number &&
                    <React.Fragment>
                        <div className="row">
                            <p className="phone phone-from noselect">{data.call.caller_number}</p>
                        </div>
                    </React.Fragment>
                }
                {
                    data.customer_address.contact_phone &&
                    <React.Fragment>
                        <div className="row">
                            <p className="phone phone-to noselect">{data.customer_address.contact_phone}</p>
                        </div>
                    </React.Fragment>
                }
                {
                    data.customer_address.extra &&
                    <React.Fragment>
                        <div className="row">
                            <p className="note noselect">{data.customer_address.extra}</p>
                        </div>
                    </React.Fragment>
                }
                {
                    data.note &&
                    <React.Fragment>
                        <div className="row">
                            <p className="note noselect">{data.note}</p>
                        </div>
                    </React.Fragment>
                }
                    <React.Fragment>
                        <div className="row">
                            <p className="price noselect">{data.amount}</p>
                        </div>                    
                        <div className="row">
                            <Barcode 
                                format="CODE128"
                                lineColor="#000"
                                width={2}
                                height={35}
                                displayValue={false}
                                value={'O,' + data.id}
                            />
                        </div>
                    </React.Fragment>

            </section>
            <hr className="break noselect"></hr>
        </React.Fragment>
    )
}

export default DeliveryTicket