import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

// Actions
import { PAYMENT_TYPE, selectPaymentType } from '../actions/UI';

import PaymentTypeList from '../components/UI/OrderPaymentType';


class OrderPaymentTypeContainer extends React.Component {
	render() {
		return (
			<React.Fragment>
				{this.props.paymentTypes && this.props.paymentTypes.length > 0 && (
					<PaymentTypeList
						value={this.props.selectedPaymentType}
						paymentTypes={this.props.paymentTypes}
						onChange={this.handlePaymentTypeChange}
					/>
				)}
			</React.Fragment>
		);
	}

	handlePaymentTypeChange = (paymentType) => {
		console.log(`Selected order payment type: ${JSON.stringify(paymentType)}`);

		this.props.selectPaymentType(paymentType);
	}


	getInitialSelectedPaymentType() {
		return (this.props.paymentTypes || []).slice(0, 1).shift();
	}

}

function mapStateToProps(state) {
	return {
		// fake fetching payment type list to keep standard structure and allow for easier later upgrades
		paymentTypes: _.values(PAYMENT_TYPE),
		selectedPaymentType: state.viewUI.selectedPaymentType,
	}
}



export default connect(mapStateToProps, { selectPaymentType })(OrderPaymentTypeContainer)
