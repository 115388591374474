import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
    ButtonGroup, 
    Glyphicon, 
    OverlayTrigger, 
    Popover, 
    Tooltip } from 'react-bootstrap'

const TargetButtonGroup = props => {
    const { target, target_url , tooltip_text, link_text } = props;
    if (target === undefined) {
        return 'Loading...'
    }

    const tooltip_add = (
        <Tooltip id="tooltip">{tooltip_text} <strong>{target.name}</strong></Tooltip>
    );

    return (
        <ButtonGroup bsSize="xsmall">
            <OverlayTrigger placement="bottom" overlay={tooltip_add} delayShow={500}>
                <Link to={target_url} className="btn btn-default">
                    <Glyphicon glyph="plus" /> {link_text}
                </Link>
            </OverlayTrigger>
        </ButtonGroup>
    )
}

TargetButtonGroup.propTypes = {
    target_url: PropTypes.string.isRequired,
    link_text: PropTypes.string.isRequired,
    tooltip_text: PropTypes.string.isRequired,
    target: PropTypes.shape({
        name: PropTypes.string.isRequired
    })
}

export default TargetButtonGroup
