import _ from 'lodash';
import { 
    GET_AGENT_RECORDS,
} from '../../actions/agent';


export default function(state = {}, action) {
  switch (action.type) {
    case GET_AGENT_RECORDS:
        const newRecords = _.mapKeys(action.payload, 'id')
        return { ...state, ...newRecords }
    default:
        return state;
  }
}