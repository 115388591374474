import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* application components */
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default class App extends Component { // eslint-disable-line react/prefer-stateless-function
    static propTypes = {
        children: PropTypes.node,
    };

    render() {
        return (
            <div className="hcf-mainLayout__container">
                <div className="hcf-mainLayout__header">
                    <Header />
                </div>

                <div className="hcf-mainLayout__content">
                    {this.props.children}
                </div>

                <div className="hcf-mainLayout__footer">
                    <Footer />
                </div>
            </div>
        );
    }
}