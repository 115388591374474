import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col, FormControl, FormGroup, ControlLabel, Form } from 'react-bootstrap';

import DatePickerUI from '../../../components/UI/DatePickerUI'


class StatsFilter extends React.Component {
    state = {
        startDate: this.props.filter.startDate,
        endDate: this.props.filter.endDate,
    };

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.updateFilterData(this.props.filter.startDate, this.props.filter.endDate);
        }
    }

    render() {
        return (
            <Grid>
                <Row>
                    <Col>
                        <div className="hcf-containers__commandContainer">
                            <Form inline>
                                {/* ----- start date ----- */}
                                <FormGroup>
                                    <ControlLabel>Stanje od dana:&nbsp;</ControlLabel>
                                    <FormControl componentClass={DatePickerUI} value={this.state.startDate} onChange={this.handleStartDatePickerChange} />
                                </FormGroup>

                                &nbsp;

                                {/* ----- end date ----- */}
                                <FormGroup>
                                    <ControlLabel>do dana:&nbsp;</ControlLabel>
                                    <FormControl componentClass={DatePickerUI} value={this.state.endDate} onChange={this.handleEndDatePickerChange} />
                                </FormGroup>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }

    handleStartDatePickerChange = (startDate) => {
        this.updateFilterData(startDate, this.props.endDate);

        if (this.props.onChange != null) {
            this.props.onChange({ startDate, endDate: this.props.filter.endDate });
        }
    }

    handleEndDatePickerChange = (endDate) => {
        this.updateFilterData(this.props.startDate, endDate);

        if (this.props.onChange != null) {
            this.props.onChange({ startDate: this.props.filter.startDate, endDate });
        }
    }

    updateFilterData(startDate, endDate) {
        this.setState({
            startDate, endDate,
        });
    }
}

StatsFilter.propTypes = {
    filter: PropTypes.shape({
        startDate: PropTypes.object,
        endDate: PropTypes.object,
    }),
    onChange: PropTypes.func,
}

export default StatsFilter