import * as Sentry from '@sentry/browser';
import jwtDecode from 'jwt-decode';

import { createConfig } from "../utils/config/config";
import APP_CONFIG from './config/appConfig.default';
import { APP_CONFIG_NAME } from "./config/appConfig";
import * as AppConfig from "./config/appConfig";


// ---------- Static app initialization

/**
 * HCF app config initialization
 * 
 * ENV_APP_CONFIG should be available as global variable
 */
createConfig(APP_CONFIG_NAME, window.ENV_APP_CONFIG || {}, APP_CONFIG);


/**
 * Initialize sentry log collector
 */
const LOGGING_SENTRY_ENABLED = AppConfig.getValue('sentry.enabled');
const SENTRY_CONFIG = AppConfig.getValue('sentry.config');
const APP_ENVIRONMENT = AppConfig.getValue('app.environment');

if (LOGGING_SENTRY_ENABLED) {
	console.log('Configuring sentry client');

	// initialize sentry
	Sentry.init({
		...SENTRY_CONFIG,
		environment: APP_ENVIRONMENT,
	});

	// configre sentry scope
	Sentry.configureScope((scope) => {
		// ----- logged user (if logged in)
		// TODO: move handling user token to a centralized place instead of here
		var userToken = localStorage.getItem('token')
		if (userToken != null) {
			var userPublicId = jwtDecode(userToken).public_id

			scope.setUser({
				id: userPublicId,
			});
		}
	});
}
