import React from 'react'
import { connect } from 'react-redux'

// Actions
import { selectDeliveryType } from '../../actions/UI';

// Selectors
import { getSelectedDeliveryType } from '../../selectors/location';

// components
import { DeliveryTypeList } from '../../components/UI/DeliveryTypeList';

const DELIVERY_TYPE_TABLE_COUNT = 40;

const DeliveryTypeContainer = (props) => {
	const { selectedDeliveryType, deliveryTypes, selectDeliveryType } = props;

	return (
		<React.Fragment>
			{deliveryTypes && (<DeliveryTypeList
				value={selectedDeliveryType}
				deliveryTypes={deliveryTypes}
				onChange={selectDeliveryType}
			/>)}
		</React.Fragment>
	);
}

/** Delivery types are currently hardcoded so we mock them to make it hurt less. */
function mockDeliveryTypes() {
	const deliveryTypes = [];

	// take out
	deliveryTypes.push({
		id: 'Za van',
		name: 'Za van'
	});

	// tables - hardcoded to N tables until we get this from BE 
	for (let i = 0; i < DELIVERY_TYPE_TABLE_COUNT; i++) {
		const typeName = 'Stol ' + (i + 1);
		deliveryTypes.push({
			id: typeName,
			name: typeName
		});
	}

	return deliveryTypes;
}

function mapStateToProps(state) {
	return {
		deliveryTypes: mockDeliveryTypes(),
		selectedDeliveryType: getSelectedDeliveryType(state),
	}
}



export default connect(mapStateToProps, { selectDeliveryType })(DeliveryTypeContainer)
