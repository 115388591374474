import { createSelector } from "reselect";

/** Returns locations assigned to current agent. */
export function getAgentLocations(state) {
	return state.AgentLocations;
}

/** Returns map of ALL locations. */
export function getLocationsMap(state) {
	return state.Locations;
}

/** Get UI selected delivery type. */
export function getSelectedDeliveryType(state) {
	return state.viewUI.selectedDeliveryType;
}

/** Get UI selected location. */
export function getSelectedLocation(state) {
	return state.viewUI.selectedLocation;
}

/** Returns default location if any.. */
export const getDefaultLocation = createSelector(
	[getAgentLocations],
	(locations) => {
		return (locations || []).reduce((accum, location) => {
			if (accum != null) {
				return accum;
			}

			return location.is_default ? location : undefined;
		}, undefined);
	});
