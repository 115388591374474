import React from 'react';
import HcfImage from '../common/HcfImage';

export const Home = () =>
    <section>
        <div className="container text-center">
            <p className="hcf-welcomePage__image">
                <HcfImage path="hallochef.svg" />
            </p>
            <h1>Dobrodošli u HalloChef!</h1>
        </div>
    </section>;
