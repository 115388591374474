import { CREATE_DELIVERY, SUBMIT_DELIVERY, CALCULATE_DELIVERY_ADDRESS_DISTANCE, storeDeliveryAddressDistance, resetDelivery } from '../actions/delivery'
import { socket } from './websocket'

import { GET_ADDRESS_DISTANCE } from '../actions/websocket'
import { CART_CHECKOUT_REQUEST } from '../actions/cart'

export const deliveryMdl = store => next => action => {
    const { dispatch } = store

    switch (action.type) {
        case SUBMIT_DELIVERY:
            var submit_data = action.payload

            dispatch({
                type: CREATE_DELIVERY,
                payload: submit_data
            })
            break


        case CREATE_DELIVERY:
            var submit_data = action.payload
            socket.emit(CREATE_DELIVERY, submit_data, (data) => {
                console.log('We sent our delivery to backend and got this back ', data)
                if (data.status.code === 200) {
                    console.log("Backend confirmed order was made, clearing UI elements")
                    // Reset cart to initial state
                    dispatch({
                        type: CART_CHECKOUT_REQUEST
                    })
                    // Reset customer order form to initial state
                    dispatch(resetDelivery())
                } else {
                    // TODO : raise error message in some modal
                    console.warn("Backend didn't respond well to our attempt to make new delivery ", data)
                }
            })
            break


        case CALCULATE_DELIVERY_ADDRESS_DISTANCE:
            var currentState = store.getState()
            if (currentState.viewUI.activeAgent !== null) {
                const address = action.payload;
                var submit_data = {
                    address,
                    agent_key: currentState.viewUI.activeAgent,
                };

                console.log(`Calculating locations distances from address ${address}`);
                socket.emit(GET_ADDRESS_DISTANCE, JSON.stringify(submit_data), (data) => {
                    const distance = data.payload;
                    dispatch(storeDeliveryAddressDistance(distance));
                });
            }
            else {
                console.warn('Trying to get delivery address distance but agent is not logged in.');
            }
            break

        default:
            next(action)
    }
}