import io from 'socket.io-client'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'

import { 
    GET_AGENT_RECORDS, 
    GET_MY_AGENT_KEY, 
    ACTIVATE_AGENT_LISTENER, 
    DEACTIVATE_AGENT_LISTENER } from '../actions/agent'

import { UI_VISIBLE_COMPONENTS } from '../actions/UI'

import {
    WS_CONNECT,
    WS_DISCONNECT,
    WS_RECONNECT, } from '../actions/websocket'

import {
    GET_MENU_ITEM,
    GET_MENU_GROUP,
    GET_MENU_ITEM_GROUP_COMPLEMENT,
    GET_MENU_GROUP_GROUP_COMPLEMENT,
    GET_MENU_ITEM_GROUP_MEMBER,
    GET_DELIVERY_TODAY,
    GET_CALL_TODAY,
    GET_LOCATION,
    GET_DELIVERYMAN,
    GET_CHECKIN,
    GET_AGENT_LOCATIONS, } from '../actions/websocket'

import {
    PUSH_DELIVERY,
    PUSH_CALL,
    PUSH_LOCATION,
    PUSH_MENU_ITEM,
    PUSH_MENU_GROUP,
    PUSH_MENU_ITEM_GROUP_MEMBER,
    PUSH_MENU_GROUP_GROUP_COMPLEMENT,
    PUSH_MENU_ITEM_GROUP_COMPLEMENT,
    PUSH_DELIVERYMAN,
    PUSH_CHECKIN, } from '../actions/websocket'

import {
    DELETE_MENU_GROUP_GROUP_COMPLEMENT,
    DELETE_MENU_ITEM_GROUP_COMPLEMENT,
    DELETE_MENU_ITEM_GROUP_MEMBER,
    DELETE_DELIVERYMAN,
    DELETE_LOCATION,  } from '../actions/websocket'


const token = localStorage.getItem('token');
const ws_uri = '/'

// Using query leaks token in access logs. NGiNX config should avoid logging the token.
export const socket = io(ws_uri, { 
    query: { token: token },
    transports: ['websocket'],
    autoConnect: false,
})


export const init = (store) => {
    const { dispatch } = store

    socket.on("connect", () => {
        dispatch({
            type: WS_CONNECT,
        })

        var user_token = localStorage.getItem('token')
        if (user_token) {
            var user_public_id = jwtDecode(user_token).public_id
            /* When user connects he should fetch number of things to populate redux store
                - user UI settings
                - agents
                - locations
                - menu*
                - [ ] active call for agent, BUT is this a wise thing to do - Easily can be done
                      but then on re-connect we might overrun the data? 
             */
            socket.emit('[WS] get user UI settings', user_public_id, (data) => {
                dispatch({
                    type: UI_VISIBLE_COMPONENTS,
                    payload: data
                })
            })
            
            socket.emit(GET_AGENT_RECORDS, (data) => {
                dispatch({
                    type: GET_AGENT_RECORDS,
                    payload: data
                })
            })

            socket.emit(GET_MY_AGENT_KEY, user_public_id, (data) => {                
                var currentState = store.getState()
                if (_.has(currentState.viewUI.activeAgent, 'agent_key')) {
                    const { activeAgent } = currentState.viewUI
                    dispatch({
                        type: DEACTIVATE_AGENT_LISTENER,
                        payload: activeAgent.agent_key
                    })
                }

                if (_.has(data, 'agent_key')) {
                    localStorage.setItem('agent_key', data.agent_key)
                    dispatch({
                        type: ACTIVATE_AGENT_LISTENER,
                        payload: data.agent_key
                    })

                    // Fetch locations visible to this specific agent
                    socket.emit(GET_AGENT_LOCATIONS, data.agent_key, (data) => {
                        dispatch({
                            type: GET_AGENT_LOCATIONS,
                            payload: {
                                data: JSON.parse(data.payload)
                            } 
                        })
                    })
                }
            })

            // Fetch all todays deliveries on connect
            socket.emit(GET_DELIVERY_TODAY, (data) => {
                if (data) {
                    dispatch({
                        type: GET_DELIVERY_TODAY,
                        payload: {
                            data: JSON.parse(data.payload)
                        }
                    })
                } else {
                    console.warn("No deliveries were fetched for today")
                }
            })

            // Fetch all todays deliveries on connect
            socket.emit(GET_CALL_TODAY, (data) => {
                if (data) {
                    dispatch({
                        type: GET_CALL_TODAY,
                        payload: {
                            data: data.payload
                        }
                    })
                } else {
                    console.warn("No calls were fetched for today")
                }
            })

            socket.emit(GET_MENU_ITEM, (data) => {
                if (data) {
                    dispatch({
                        type: GET_MENU_ITEM,
                        payload: {
                            data: JSON.parse(data.payload)
                        }
                    })
                } else {
                    console.warn("No menu items were fetched")
                }
            })

            socket.emit(GET_MENU_GROUP, (data) => {
                if (data) {
                    dispatch({
                        type: GET_MENU_GROUP,
                        payload: {
                            data: data.payload
                        }
                    })
                } else {
                    console.warn("No menu groups were fetched")
                }
            })

            socket.emit(GET_MENU_ITEM_GROUP_COMPLEMENT, (data) => {
                if (data) {
                    dispatch({
                        type: GET_MENU_ITEM_GROUP_COMPLEMENT,
                        payload: {
                            data: data.payload
                        }
                    })
                } else {
                    console.warn("No menu item group complements were fetched")
                }
            })

            socket.emit(GET_MENU_GROUP_GROUP_COMPLEMENT, (data) => {
                if (data) {
                    dispatch({
                        type: GET_MENU_GROUP_GROUP_COMPLEMENT,
                        payload: {
                            data: data.payload
                        }
                    })
                } else {
                    console.warn("No menu group group complements were fetched")
                }
            })

            socket.emit(GET_MENU_ITEM_GROUP_MEMBER, (data) => {
                if (data) {
                    dispatch({
                        type: GET_MENU_ITEM_GROUP_MEMBER,
                        payload: {
                            data: data.payload
                        }
                    })
                } else {
                    console.warn("No menu item group members were fetched")
                }
            })

            socket.emit(GET_LOCATION, (data) => {
                if (data) {
                    dispatch({
                        type: GET_LOCATION,
                        payload: {
                            data: JSON.parse(data.payload)
                        }
                    })
                } else {
                    console.warn("No locations were fetched")
                }
            })

            socket.emit(GET_DELIVERYMAN, (data) => {
                if (data) {
                    dispatch({
                        type: GET_DELIVERYMAN,
                        payload: {
                            data: data.payload
                        }
                    })
                } else {
                    console.warn("No deliveryman were fetched")
                }
            })

            socket.emit(GET_CHECKIN, (data) => {
                if (data) {
                    dispatch({
                        type: GET_CHECKIN,
                        payload: {
                            data: data.payload
                        }
                    })
                } else {
                    console.warn("No checkins were fetched")
                }
            })
        }

    })


    socket.on("reconnect", () => {
        dispatch({
            type: WS_RECONNECT,
        })
    })


    socket.on("disconnect", () => {
        dispatch({
            type: WS_DISCONNECT,
        })
        // TODO : set UI state webSocket disconnected = True
    })

    /**
     * TODO : implement other websocket events
     * source: https://stackoverflow.com/questions/24224287/list-of-socket-io-events
     * 
     * - connect_error
     * - connect_timeout
     * - reconnect_attempt
     * - reconnecting
     * - reconnect_error
     * - reconnect_failed
     */

    /**
     *  Section below has listeners which relay accepted data to store
     *  As data is changed on backend, changes get emitted to all connected
     *  devices and below are listeners which accept this data and dispatch
     *  it to store
     */

    
    // Create, Update: MenuItem
    socket.on(PUSH_MENU_ITEM, (payload) => {
        dispatch({
            type: PUSH_MENU_ITEM,
            payload: {
                /* This data needs to be parsed because it contains some Decimal data types which are hard to serialize */
                data: JSON.parse(payload)
            }
        })
    })

    // Create, Update: MenuGroup
    socket.on(PUSH_MENU_GROUP, (payload) => {
        dispatch({
            type: PUSH_MENU_GROUP,
            payload: {
                data: payload
            }
        })
    })
    
    // Create, Update: MenuItemGroupMember
    socket.on(PUSH_MENU_ITEM_GROUP_MEMBER, (payload) => {
        dispatch({
            type: PUSH_MENU_ITEM_GROUP_MEMBER,
            payload: {
                data: payload
            }
        })
    })

    // Delete: MenuItemGroupMember
    socket.on(DELETE_MENU_ITEM_GROUP_MEMBER, (payload) => {
        dispatch({
            type: DELETE_MENU_ITEM_GROUP_MEMBER,
            payload: {
                data: payload
            }
        })
    })

    // Create, Update: MenuGroupGroupComplement
    socket.on(PUSH_MENU_GROUP_GROUP_COMPLEMENT, (payload) => {
        dispatch({
            type: PUSH_MENU_GROUP_GROUP_COMPLEMENT,
            payload: {
                data: payload
            }
        })
    })

    // Delete: MenuGroupGroupComplement
    socket.on(DELETE_MENU_GROUP_GROUP_COMPLEMENT, (payload) => {
        dispatch({
            type: DELETE_MENU_GROUP_GROUP_COMPLEMENT,
            payload: {
                data: payload
            }
        })
    })

    // Create, Update: MenuItemGroupComplement
    socket.on(PUSH_MENU_ITEM_GROUP_COMPLEMENT, (payload) => {
        dispatch({
            type: PUSH_MENU_ITEM_GROUP_COMPLEMENT,
            payload: {
                data: payload
            }
        })
    })

    // Delete: MenuItemGroupComplement
    socket.on(DELETE_MENU_ITEM_GROUP_COMPLEMENT, (payload) => {
        dispatch({
            type: DELETE_MENU_ITEM_GROUP_COMPLEMENT,
            payload: {
                data: payload
            }
        })
    })

    
    // Create, Update: Delivery
    socket.on(PUSH_DELIVERY, (payload) => {
        dispatch({
            type: PUSH_DELIVERY,
            payload: {
                /* This data needs to be parsed because it contains some Decimal data types which are hard to serialize */
                data: JSON.parse(payload)
            }
        })
    })

    // Create, Update: Call
    socket.on(PUSH_CALL, (payload) => {
        dispatch({
            type: PUSH_CALL,
            payload: {
                data: payload
            }
        })
    })

    // Create, Update: Location
    socket.on(PUSH_LOCATION, (payload) => {
        dispatch({
            type: PUSH_LOCATION,
            payload: {
                data: JSON.parse(payload)
            }
        })
    })
    
    // Create, Update: Checkin
    socket.on(PUSH_CHECKIN, (payload) => {
        dispatch({
            type: PUSH_CHECKIN,
            payload: {
                data: payload
            }
        })
    })

    // Create, Update: DeliveryMan
    socket.on(PUSH_DELIVERYMAN, (payload) => {
        dispatch({
            type: PUSH_DELIVERYMAN,
            payload: {
                data: payload
            }
        })
    })

    // Delete: DeliveryMan
    socket.on(DELETE_DELIVERYMAN, (payload) => {
        dispatch({
            type: DELETE_DELIVERYMAN,
            payload: {
                data: payload
            }
        })
    })

    // Delete: Location
    socket.on(DELETE_LOCATION, (payload) => {
        dispatch({
            type: DELETE_LOCATION,
            payload: {
                data: payload
            }
        })
    })
}