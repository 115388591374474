import React from 'react'
import ReactPlayer from "react-player";

const CONFIG_DEFAULT = {
	file: {
		forceAudio: true, // we don't use video so we can force rendering audio element
		attributes: {
			'preload': 'none' // prevent browser from preloading recordings
		}
	}
};

/** Component that displays audio player for call recordings. */
export const RecordingPlayer = (props) => {
	const { recordingUrl } = props;

	if (recordingUrl) {
		return <ReactPlayer url={recordingUrl} controls={true} config={CONFIG_DEFAULT} className="hcf-calls__recordingControls" />
	} else {
		return '-';
	}

};
