import React from 'react'
import _ from 'lodash'

// UI components
import CartItemUI from './CartItemUI'
import { Grid } from 'react-bootstrap'

const CartItemsUI = props => {
    const { selected_item, cart_items, ungrouped } = props
    const { select_action, remove_item, remove_addon } = props
    return (
        <Grid fluid>
            {_.map(cart_items, item => {
                if (item.addons !== undefined) {
                    var addon_items = item.addons.map((addon, index) => {
                        return (
                            <CartItemUI key={addon.uuid}
                                item={addon}
                                select_action={select_action}
                                remove_item={remove_item}
                                remove_addon={remove_addon}
                                selected_item={selected_item}
                                parent_uuid={item.uuid}
                                addon
                                ungrouped={ungrouped}
                            />
                        )
                    })
                    var addons_length = item.addons.length
                } else {
                    var addon_items = null
                    var addons_length = 0
                }

                return (
                    <React.Fragment key={item.uuid + addons_length}>
                        <CartItemUI
                            item={item}
                            select_action={select_action}
                            remove_item={remove_item}
                            remove_addon={remove_addon}
                            selected_item={selected_item}
                            parent_uuid={null}
                            ungrouped={ungrouped}
                        />

                        {addon_items && addon_items}
                    </React.Fragment>
                )


            })}
        </Grid >
    )
}

export default CartItemsUI


