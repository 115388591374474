import _ from 'lodash';

import { 
    PUSH_MENU_ITEM_GROUP_MEMBER, 
    DELETE_MENU_ITEM_GROUP_MEMBER,
    GET_MENU_ITEM_GROUP_MEMBER, } from '../../actions/websocket'

export default function(state = {}, action) {
  switch (action.type) {

      case DELETE_MENU_ITEM_GROUP_MEMBER:
        var { menu_group_id, menu_item_id } = action.payload.data
        if (menu_group_id in state) {
            if (state[menu_group_id].includes(menu_item_id)) {
                const idx = state[menu_group_id].indexOf(menu_item_id);
                return {
                    ...state,
                    [menu_group_id]: [
                        ...state[menu_group_id].slice(0, idx),
                        ...state[menu_group_id].slice(idx + 1)
                    ]
                };
            }
        }

        return state;
        
    case PUSH_MENU_ITEM_GROUP_MEMBER:        
        var { menu_group_id, menu_item_id } = action.payload.data
        if (menu_group_id in state) {
            if (state[menu_group_id].includes(menu_item_id)) {
                var updatedGroup = state[menu_group_id]
            } else {
                var updatedGroup = [...state[menu_group_id], menu_item_id]
            }
        } else {
            var updatedGroup = [menu_item_id];
        }
        return {
            ...state,
            [menu_group_id]: updatedGroup
        }

    case GET_MENU_ITEM_GROUP_MEMBER:
        return _.reduce(action.payload.data, function (result, kv) {
            (result[kv.menu_group_id] || (result[kv.menu_group_id] = [])).push(kv.menu_item_id);
            return result;
        }, {});
        
    default:
        return state;
  }
}