import React from 'react'
import { connect } from 'react-redux'

import OrderView from '../components/ViewsUI/OrderView';


const OrderViewContainer = (props) => {
	const { uiVisibleComponents } = props;

	return (
		<OrderView uiVisibleComponents={uiVisibleComponents} />
	);
}

function mapStateToProps(state) {
	return {
		uiVisibleComponents: state.viewUI.uiVisibleComponents,
	}
}

export default connect(mapStateToProps)(OrderViewContainer)
