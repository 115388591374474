import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

function mapStateToProps(state) {
    return {
        isTabletUser: state.viewUI.uiVisibleComponents && state.viewUI.uiVisibleComponents.ui_tablet_view ? state.viewUI.uiVisibleComponents.ui_tablet_view : false,
    };
}

/** Prevent access to users without "table" view option. */
export const tabletViewRouteGuard = (Component, routeProps) => {
    const TabletViewRoute = (props) => {
        const { isTabletUser, history, location } = props;
        const { redirectTo } = routeProps || {};

        if (isTabletUser) {
            return <Component {...props} />;
        }
        else {
            // prevent endless redirect loop
            if (redirectTo != null && location.pathname != redirectTo) {
                history.replace(redirectTo);
            }

            return null;
        }
    }
    return withRouter(connect(mapStateToProps)(TabletViewRoute));
}
