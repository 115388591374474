import { createSelector } from 'reselect'
import _ from 'lodash'

const getGroups = (state, props) => state.MenuGroups
const getUrlGroupId = (_ , props) => props.match.params.menu_group_id

const getEditGroup = createSelector(
    [getUrlGroupId, getGroups], 
    (UrlGroupId, groups) => {
        return groups[UrlGroupId]
    }
)

export default getEditGroup