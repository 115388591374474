import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

// CSS Modules, react-datepicker-cssmodules.css
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './custom-datepicker.scss'

const TIME_FORMAT_DEFAULT = 'HH:mm';
const DATE_FORMAT_DEFAULT = 'DD.MM.YYYY.';

/** Custom bootstrap style input for date picker. */
const CustomInput = (props) => {
  return (<input {...props} type="text" className="form-control" />);
}

const DatePickerUI = (props) => {
  const { value, timeFormat, dateFormat, showTimeSelect } = props;

  const selectedDate = value != null ? moment(value) : undefined;

  return <DatePicker
    selected={selectedDate}
    timeFormat={timeFormat || TIME_FORMAT_DEFAULT}
    dateFormat={dateFormat || DATE_FORMAT_DEFAULT}
    showTimeSelect={showTimeSelect || false}

    customInput={<CustomInput />}

    onChange={props.onChange}
  />;
}

export default DatePickerUI;
