import React from 'react'
import { connect } from 'react-redux'

// Actions
import { selectOrderLocation } from '../../actions/UI';

// Selectors
import { getAgentLocations, getDefaultLocation, getSelectedLocation } from '../../selectors/location';
import { getTodaysDeliveriesDispatchPending, getDeliveryAddressDistance, getTodaysPendingDeliveryCountPerLocation, getTodaysPendingOrderCountPerLocation } from '../../selectors/delivery'

import OrderLocationList from '../../components/UI/OrderLocationList';


class OrderLocationContainer extends React.Component {
	componentDidMount() {
		this.preselectLocation();
	}

	componentDidUpdate(prevProps) {
		// locations have changed
		if (this.props.selectedLocation !== prevProps.selectedLocation || this.props.defaultLocation !== prevProps.defaultLocation) {
			this.preselectLocation();
		}
	}

	render() {
		return (
			<React.Fragment>
				{this.props.agentLocations && this.props.agentLocations.length > 0 && (
					<OrderLocationList
						value={this.props.selectedLocation}
						locations={this.props.agentLocations}
						onChange={this.handleAgentLocationChange}
						deliveryAddressDistance={this.props.deliveryAddressDistance}
						deliveryCountPerLocation={this.props.deliveryCountPerLocation}
						orderCountPerLocation={this.props.orderCountPerLocation}
					/>
				)}
			</React.Fragment>
		);
	}

	handleAgentLocationChange = (location) => {
		this.props.selectOrderLocation(location);
	}

	/** if selected loc is empty and default isn't, set default as selected */
	preselectLocation = () => {
		if (this.props.selectedLocation == null && this.props.defaultLocation != null) {
			this.handleAgentLocationChange(this.props.defaultLocation);
		}
	}
}

function mapStateToProps(state) {
	return {
		agentLocations: getAgentLocations(state),
		selectedLocation: getSelectedLocation(state),
		defaultLocation: getDefaultLocation(state),
		deliveries: getTodaysDeliveriesDispatchPending(state),
		deliveryAddressDistance: getDeliveryAddressDistance(state),
		deliveryCountPerLocation: getTodaysPendingDeliveryCountPerLocation(state),
		orderCountPerLocation: getTodaysPendingOrderCountPerLocation(state),
	}
}



export default connect(mapStateToProps, { selectOrderLocation })(OrderLocationContainer)
