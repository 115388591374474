import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import {
	Navbar,
	Nav,
	NavDropdown,
	MenuItem,
	Glyphicon
} from 'react-bootstrap';

import FeedbackMessageContainer from '../../containers/Feedback';

import * as actionCreators from '../../actions/auth';
import UnansweredCallsWatchdog from '../../containers/watchdog/UnansweredCallsWatchdog';
import { isAdmin } from '../auth/util';


function mapStateToProps(state) {
	return {
		token: state.auth.token,
		userName: state.auth.userName,
		agentKey: state.viewUI.activeAgent,
		isAuthenticated: state.auth.isAuthenticated,
		uiTableView: state.viewUI.uiVisibleComponents && state.viewUI.uiVisibleComponents.ui_tablet_view,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(actionCreators, dispatch);
}

class Header extends Component {
	logout(e) {
		e.preventDefault();
		this.props.logoutAndRedirect();
	}

	render() {
		const history = this.props.history;

		const showTableNav = this.props.uiTableView != null ? this.props.uiTableView : false

		return (
			<header>
				<Navbar inverse fluid collapseOnSelect>
					<Navbar.Header>
						<Navbar.Brand>
							<Link to="/home">HalloChef</Link>
						</Navbar.Brand>
						<Navbar.Toggle />
					</Navbar.Header>
					<Navbar.Collapse>
						{!this.props.isAuthenticated ? (
							<Nav pullRight>
								<MenuItem href="#" onClick={() => history.push('/login')}>Login</MenuItem>
							</Nav>
						) : (
							<React.Fragment>
								{showTableNav && (
									<Nav>
										<MenuItem href="#" onClick={() => history.push('/ordertablet')}>Naručivanje</MenuItem>
										<MenuItem href="#" onClick={() => history.push('/deliveries')}>Narudžbe</MenuItem>
									</Nav>
								)}

								{!showTableNav && (
									<Nav>
										<MenuItem href="#" onClick={() => history.push('/order')}>Telefon</MenuItem>
										<MenuItem href="#" onClick={() => history.push('/calls')}>Pozivi</MenuItem>
										<MenuItem href="#" onClick={() => history.push('/deliveries')}>Narudžbe</MenuItem>
										{isAdmin() && (
											<MenuItem href="#" onClick={() => history.push('/stats')}>Obračun</MenuItem>
										)}
										<NavDropdown eventKey={3} title="Menu" id="basic-nav-dropdown">
											<MenuItem eventKey={3.2} href="#" onClick={() => history.push('/menu/group/list')}>Pregled grupa</MenuItem>
											{/*
									<MenuItem divider />
									<MenuItem eventKey={3.3} href="#" onClick={() => history.push('/menu/item/create')}>Nova stavka</MenuItem>
									<MenuItem eventKey={3.4} href="#" onClick={() => history.push('/menu/item/list')}>Pregled stavaka</MenuItem>
									<MenuItem divider />
									<MenuItem eventKey={3.5} href="#" onClick={() => history.push('/menu/help')}>Pomoć</MenuItem>
									*/}
										</NavDropdown>
										<NavDropdown eventKey={4} title="Dostava" id="basic-nav-dropdown">
											<MenuItem eventKey={4.1} href="#" onClick={() => history.push('/deliveryman/list')}>Pregled dostavljača</MenuItem>
											<MenuItem divider />
											<MenuItem eventKey={4.2} href="#" onClick={() => history.push('/deliveryman/handoff')}>Isporuka</MenuItem>
										</NavDropdown>
										{isAdmin() && (
											<NavDropdown eventKey={5} title="Postavke" id="basic-nav-dropdown">
												<MenuItem eventKey={5.1} href="#" onClick={() => history.push('/user/create')}>Novi korisnik</MenuItem>
												{/* <MenuItem eventKey={5.2} href="#" onClick={() => history.push('/user/list')}>Pregled korisnika</MenuItem>*/}
												<MenuItem divider />
												<MenuItem eventKey={5.3} href="#" onClick={() => history.push('/location/create')}>Nova lokacija</MenuItem>
												<MenuItem eventKey={5.4} href="#" onClick={() => history.push('/location/list')}>Pregled lokacija</MenuItem>
											</NavDropdown>
										)}
									</Nav>
								)}

								<Nav pullRight>
									<NavDropdown eventKey={6} title={<span><Glyphicon glyph="user" />&nbsp;&nbsp;{this.props.userName}{this.props.agentKey != null && (<span> ({this.props.agentKey})</span>)}</span>} id="basic-nav-usermenu">
										<MenuItem eventKey={6.1} href="#" onClick={(e) => this.logout(e)}>Izlaz</MenuItem>
									</NavDropdown>
								</Nav>
							</React.Fragment>
						)}
					</Navbar.Collapse>

				</Navbar>

				<FeedbackMessageContainer />
				<UnansweredCallsWatchdog />
			</header>

		);
	}
}



Header.propTypes = {
	logoutAndRedirect: PropTypes.func,
	isAuthenticated: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
