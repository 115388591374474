/* Ordering Cart Middleware */
import uuid from 'uuid/v4'
import _ from 'lodash'

// Action types
import {
    CART_ADD_ITEM,
    CART_ADD_ADDON,
    CART_ADD_PART,
    CART_ADD_LAST_PART,
    CART_ADD_PART_ADDON,
    CART_REMOVE_ITEM,
    CART_REMOVE_ADDON,
    CART_REMOVE_PART,
    CART_REMOVE_LAST_PART,
    CART_REMOVE_PART_ADDON,
    CART_SELECT,
    CART_CHECKOUT_REQUEST,
    CART_CHECKOUT_FAILURE,
} from '../actions/cart'




// Actions
import {
    addCartItem,
    addCartAddon,
    removeCartItem,
    removeCartItemByUUID,
    removeCartAddon,
    selectCartItem,
    addCartItemPart,
    addCartPartAddon,
    addCartLastItemPart,
    resetCartPart,
} from '../actions/cart'


// translate to update order action
export const cartItemEnhancher = ({dispatch}) => next => action => {
    switch(action.type) {
        case CART_ADD_ITEM:
            if ( !('uuid' in action.payload) ) {
                let enhancedItem = Object.assign({'uuid': uuid(), 'gratis' : false, 'total_price': action.payload.price, 'main_part': true}, action.payload)
                dispatch(addCartItem(enhancedItem))
            } else {
                // console.log('Middleware found enhancedItem, passing it to next action')
                next(action)
            }
            break;

        case CART_ADD_ADDON:
            if ( !('uuid' in action.payload) ) {
                // console.log('Augumenting addon before it reaches CART with UUID')
                // console.log('Vanilla addon has target : ', action)
                let enhancedItem = Object.assign({'uuid': uuid(), 'gratis' : false, 'total_price': action.payload.price, 'main_part': true} , action.payload)
                dispatch(addCartAddon(enhancedItem, action.meta.target))
            } else {
                // console.log('Middleware found enhancedAddon, passing it to next action')
                next(action)
            }
            break;

        case CART_ADD_PART:
            if ( !('uuid' in action.payload) ) {
                let enhancedItem = Object.assign({'uuid': uuid(), 'gratis' : false, 'total_price': action.payload.price, 'main_part': true} , action.payload)
                dispatch(addCartItemPart(enhancedItem))
            } else {
                next(action)
            }
            break;
        
        case CART_ADD_PART_ADDON:
            if ( !('uuid' in action.payload) ) {
                let enhancedItem = Object.assign({'uuid': uuid(), 'gratis' : false, 'total_price': action.payload.price, 'main_part': true} , action.payload)
                dispatch(addCartPartAddon(enhancedItem, action.meta.target))
            } else {
                next(action)
            }
            break;

        case CART_ADD_LAST_PART:
            if ( !('uuid' in action.payload) ) {
                let enhancedItem = Object.assign({'uuid': uuid(), 'gratis' : false, 'total_price': action.payload.price, 'main_part': true} , action.payload)
                dispatch(addCartLastItemPart(enhancedItem, _, action.meta.previous_parts))
            } else {
                next(action)
            }
            break;

        default:
            next(action)
        }
};

// CART_REMOVE_ITEM Teh Sibling removed
const cartSiblingRemover = ({dispatch}) => next => action => {
    switch(action.type) {
        case CART_REMOVE_ITEM:
            if ('sibling_list' in action.payload) {
                const { sibling_list } = action.payload
                sibling_list.map( siblingUUID => {
                    dispatch(removeCartItemByUUID(siblingUUID))
                })
            } else {
                next(action)
            }
            break
        default:
            next(action)
    }
}

// CART_ADD_LAST_PART

const cartPartLast = ({dispatch}) => next => action => {
    switch(action.type) {
        case CART_ADD_LAST_PART:
            /**
             * Create list of all siblings that make up the 'full' composition
             * Assign list of siblings to each 'part'
             * Dispatch each part enhanced with sibling list to cart
             * Reset 'parts' cart
             */

            var { previous_parts } = action.meta
            var last_part = action.payload

            var all_parts = { ...previous_parts, [last_part.uuid] : last_part }

            // Part siblings list
            var sibling_list = Object.keys(all_parts)

            // Price of all parts together
            const totalPrice = _.sum(_.map(all_parts, part => { return part.price }))

            const mostExpensiveItemUUID =_.maxBy(_.values(all_parts), 'price').uuid
            if (last_part.price_algorithm_max) {
                _.map(all_parts, part => { 
                    if (part.uuid !== mostExpensiveItemUUID) {
                        part.gratis = true 
                        part.main_part = false
                    }
                }) 
            } else {
                _.map(all_parts, part => { 
                    if (part.uuid !== mostExpensiveItemUUID) {
                        part.main_part = false
                    }
                    part.total_price = totalPrice
                }) 
            }


            // Enhanche all parts with sibling list and dispatch them to Cart
            _.map(all_parts, part => {
                const enhancedPart = Object.assign({'sibling_list': sibling_list} , part)
                dispatch(addCartItem(enhancedPart))
            })

            dispatch(resetCartPart())
        default:
            next(action)
    }
}

// cartItemEnhancher must be before cartPartLast, probably best to keep it glued in 1st place
export const cartMdl = [cartItemEnhancher, cartPartLast, cartSiblingRemover, ];