import { createSelector } from 'reselect'
import _ from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment);

/** Get currently selected delivery list date filter. */
export const getViewUIDeliveryDate = (state) => state.viewUI.deliveriesDate;

export const getDeliveries = (state) => state.Delivery

export const getDeliveriesForUiviewDeliveryDate = (store) => {
	return getDeliveriesForDate(getViewUIDeliveryDate(store))(store);
};

export const getDeliveryAddressDistance = (state) => {
	return state.viewUI.deliveryAddressDistance;
}

/**
 * Get deliveries for custom date. This selector is nested within wrapper fn which enables custom date.
 *
 * Usage example: getDeliveriesForDate(date)(state).
 */
export const getDeliveriesForDate = (forDate) => createSelector(
	[getDeliveries],
	(deliveries) => {
		const target_range = moment.range(moment(forDate).startOf('day'), moment(forDate).endOf('day'))
		const deliveryList = _.filter(deliveries, delivery => {
			// TODO: delivery date filter field
			return target_range.contains(moment(delivery.created_at))
		})

		// TODO: delivery date filter field
		return _.orderBy(deliveryList, [delivery => moment(delivery.created_at)], ['desc']);
	}
)


export const getTodaysDeliveries = createSelector(
	[getDeliveries],
	(deliveries) => {
		const target_range = moment.range(moment().startOf('day'))
		return _.filter(deliveries, delivery => {
			return target_range.contains(moment(delivery.created_at))
		})
	}
)


export const getTodaysDeliveriesDispatchPending = createSelector(
	[getTodaysDeliveries],
	(deliveries) => {
		return _.filter(deliveries, delivery => {
			return (!delivery.dispatched && !delivery.canceled)
		})
	}
)

export const getTodaysPendingDeliveryCountPerLocation = createSelector(
	[getTodaysDeliveriesDispatchPending],
	(deliveries) => {
		return deliveries.reduce((accum, delivery) => {
			if (accum[delivery.location.id] == null) {
				accum[delivery.location.id] = 0;
			}
			accum[delivery.location.id]++;

			return accum;
		}, {});
	}
)

export const getTodaysPendingOrderCountPerLocation = createSelector(
	[getTodaysDeliveriesDispatchPending],
	(deliveries) => {
		return deliveries.reduce((accum, delivery) => {
			if (accum[delivery.location.id] == null) {
				accum[delivery.location.id] = 0;
			}
			accum[delivery.location.id] += delivery.orders.reduce((orderCount, order) => orderCount + order.count, 0);

			return accum;
		}, {});
	}
)
