import { createSelector } from 'reselect'
import _ from 'lodash'

const getCartPartItems = (state, props) => state.cart.partialItems.cartItems
const getCartPartNumberOfParts = (state, props) => state.cart.partialItems.number_of_pieces

const isLastPart = createSelector(
    [getCartPartItems, getCartPartNumberOfParts], 
    (cartPartItems, cartPartNumberOfParts) => {
        return Object.keys(cartPartItems).length + 1 === cartPartNumberOfParts && cartPartNumberOfParts > 1
    }

)

export default isLastPart
