import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth'
import currentUser from './currentUser'
import events from './events'
import modal from './modal'
import viewUI from './viewUI'
import cart from './cart/index'
import Call from './call'
import Delivery from './delivery'
import Deliveryman from './deliveryman'
import MenuGroupGroupComplements from './menu/group_complement'
import MenuItemGroupComplements from './menu/item_complement'
import MenuItemGroupMembers from './menu/group_member'
import MenuGroups from './menu/group'
import MenuItems from './menu/item'
import AgentLocations from './agent_location'
import Locations from './location'
import Agent from './agent'
import Checkin from './checkin'
import Stats from './stats'
import feedback from './feedback'


const rootReducer = combineReducers({
	routing: routerReducer,
	/* your reducers */
	form: formReducer,
	auth,
	currentUser,
	modal,
	events,
	/* UI state reducer */
	viewUI,
	/* cart reducer */
	cart,
	/* Agent can order to only this subset of locations */
	AgentLocations,
	/* halloChef db mapping reducers */
	Call,
	Checkin,
	Delivery,
	Deliveryman,
	Locations,
	Agent,
	MenuGroupGroupComplements,
	MenuItemGroupComplements,
	MenuItemGroupMembers,
	MenuGroups,
	MenuItems,
	Stats,
	feedback,
});

export default rootReducer;