import React, { Component } from 'react'
import { connect } from 'react-redux'

// Actions
import { createMenuItemInGroup } from '../../actions/menu/item'

// Selectors
import groupSelected from '../../selectors/group_edit'

// Form components
import MenuItemForm from '../../components/Form/MenuItemForm'

// UI components
import { Grid, Row, Col, Panel } from 'react-bootstrap'

class MenuItemCreateInGroup extends Component {
    render() {
        const initData = {
            "active" : true
        }
        const { group, createMenuItemInGroup } = this.props;
        return (
            <Grid>
                <Row className="show-grid">
                    <Col sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Dodaj menu stavku</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <MenuItemForm initData={initData} parent_target={group} submit_action={createMenuItemInGroup} />
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}
const mapStateToProps = (state, props) => {
    return {
        group: groupSelected(state, props)
    }
}
export default connect(mapStateToProps, { createMenuItemInGroup, })(MenuItemCreateInGroup);