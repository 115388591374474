import React, { useCallback } from 'react';
import { FormGroup } from 'react-bootstrap';

export const DeliveryTypeList = (props) => {
	const { value, deliveryTypes, onChange } = props;

	const handleChange = useCallback((event) => {
		const value = event.target.value;

		if (onChange) {
			onChange(value);
		}
	});

	return (
		<FormGroup>
			<select onChange={handleChange} value={value != null ? value : ''}>
				{deliveryTypes.map((deliveryType) => (
					<option key={deliveryType.id} value={deliveryType.id}>{deliveryType.name}</option>
				))}
			</select>
		</FormGroup>
	);
}
