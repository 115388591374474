import React from 'react'
import PropTypes from 'prop-types'

// UI componetns
import { Well, } from 'react-bootstrap'

const propTypes = {
	total_cost: PropTypes.number.isRequired,
}

const TotalCostUI = props => {
	return (
		<Well bsSize="large">
			TOTAL: <b>{props.total_cost != null ? props.total_cost.toFixed(2) : (0).toFixed(2)}</b> kn
        </Well>
	)
}

TotalCostUI.propTypes = propTypes

export default TotalCostUI
