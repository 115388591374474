import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Panel, ButtonGroup, Glyphicon } from 'react-bootstrap'

import MenuGroupTable from '../../components/UI/MenuGroupTable'


class MenuGroupList extends Component {
    render() {
        const { MenuGroups, MenuItems } = this.props;
        return (
            <Grid>
                <Row>
                    <Col>
                        <Panel>
                            <Panel.Heading>
                                <Row>
                                    <Col xs={6}>
                                        <Panel.Title>Pregled grupa stavki u ponudi</Panel.Title>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <ButtonGroup bsSize="xsmall">
                                            <Link to="/menu/group/create" className="btn btn-default">
                                                <Glyphicon glyph="plus" /> Nova grupa
                                            </Link>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Panel.Heading>
                            <Panel.Body>                            
                                <MenuGroupTable
                                    groups = {MenuGroups}
                                    items = {MenuItems}
                                />
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        )
    }
}



function mapStateToProps({ MenuGroups, MenuItems }) {
    return { MenuGroups, MenuItems };
}

export default connect(mapStateToProps, null)(MenuGroupList);