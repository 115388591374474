import React from 'react';
import { connect } from 'react-redux';

// selectors
import * as selectors from '../../selectors/feedback'

// actions
import * as actions from '../../actions/feedback';

// UI
import FeedbackMessageList from '../../components/UI/feedback/FeedbackMessageList';


const mapStateToProps = (state, props) => {
    return {
        messages: selectors.getMessages(state),
    }
}

class FeedbackMessageContainer extends React.Component {
    render() {
        const { messages } = this.props;

		return (
			<React.Fragment>
			{messages && messages.length > 0 && (
				<FeedbackMessageList messages={messages} />
			)}
			</React.Fragment>
        );
    }
}

export default connect(
    mapStateToProps, actions
)(FeedbackMessageContainer);
