import { removeMessage, reportMessage, MESSAGE_SEVERITY } from "../actions/feedback"

import connectionSuccessSound from '../asset/sound/connection_success.mp3';
import connectionErrorSound from '../asset/sound/connection_error.mp3';

import {
    WS_CONNECT,
	WS_DISCONNECT, } from '../actions/websocket'
import { LOGOUT_USER } from "../constants";


const WEBSCOKET_DISCONNECTED_MESSAGE_ID = 'FEEDBACK@WEBSCOKET_DISCONNECTED_MESSAGE_ID';


// ----- Middlewares assigned to do some watchdog activities (eg. monitoring WS connection)


/**
 *  Watchdog monitoring WS connection status.
 */
const uiWebsocketWachdog = store => next => action => {
	const { dispatch, getState } = store;

	// show message if we're disconnected AND already authenticated
    if (action.type == WS_DISCONNECT && getState().auth.isConnected) {
		dispatch(reportMessage({
			id: WEBSCOKET_DISCONNECTED_MESSAGE_ID,
			message: 'Izgubili smo vezu sa serverom. Pokušavamo se spojiti ...',
			autoRemove: false,
			severity: MESSAGE_SEVERITY.ERROR,
			sound: connectionErrorSound,
		}));
	}
    else if (action.type == WS_CONNECT && getState().auth.isConnected) {
		dispatch(removeMessage(WEBSCOKET_DISCONNECTED_MESSAGE_ID));

		dispatch(reportMessage({
			message: 'Ponovno smo se spojili na server :-)',
			severity: MESSAGE_SEVERITY.SUCCESS,
			sound: connectionSuccessSound,
		}));
	}
    else if (action.type == LOGOUT_USER) {
		// we have to do manual message cleanup because we get DISCONNECT event before LOGOUT event has been processed, 
		// so we cannot know if it's just a normal disconnect or it's a user logout which also results with disconnect but without the need for message
		dispatch(removeMessage(WEBSCOKET_DISCONNECTED_MESSAGE_ID));
	}

	next(action);
}


export const uiWatchdogMdl = [uiWebsocketWachdog];
