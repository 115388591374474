import React from 'react'

// Containers
import CartContainer from '../../containers/Cart'
import MenuTabsContainer from '../../containers/MenuTabs'
import OrderContainer from '../../containers/Order'
import CustomerDataNavContainer from '../../containers/CustomerDataNav'
import ModalCustomerDataLoad from '../../containers/ModalCustomerDataLoad'

// UI Components
import { Row, Col, Panel } from 'react-bootstrap'
import OrderLocationContainer from '../../containers/OrderLocationContainer';
import OrderPaymentTypeContainer from '../../containers/OrderPaymentTypeContainer';
import ComboToggler from '../../containers/Combo'
import OrderDelayContainer from '../../containers/OrderDelayContainer';
import DiscountSelector from '../../containers/DiscountSelector'
import GratisOption from '../../containers/GratisOption'


const OrderView = (props) => {
	const { uiVisibleComponents } = props;

	return (
		<React.Fragment>
			<Row>
				<Col md={6} lg={6}>
					<Row>
						<Col md={7} lg={7}>
							<Row>
								<Col md={8} lg={8}>
									{/* Form and Adress navigation */}
									<CustomerDataNavContainer />
									<OrderContainer />
								</Col>
								<Col md={4} lg={4}>
									{/* Combo, Payment, Delay time picker */}
									<ComboToggler />

									<OrderPaymentTypeContainer />

									{uiVisibleComponents.ui_gratis && <GratisOption />}

									{uiVisibleComponents.ui_discount && <DiscountSelector />}

									<OrderDelayContainer />
								</Col>
							</Row>
							<Row>
								<Col sm={12}>
									{/* Location radio & ministats */}
									<OrderLocationContainer />
								</Col>
							</Row>
						</Col>
						<Col md={5} lg={5}>
							<CartContainer />
						</Col>
					</Row>
				</Col>
				<Col md={6} lg={6}>
					{/* Menu block */}
					<Panel>
						{/* <Panel.Heading className="text-center">
									Menu
							</Panel.Heading> */}
						<Panel.Body>
							<MenuTabsContainer />
						</Panel.Body>
					</Panel>
				</Col>
			</Row>

			<ModalCustomerDataLoad />
		</React.Fragment >
	)
}

export default OrderView