import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toggleCombo } from '../../actions/UI'
import { Checkbox, FormGroup } from 'react-bootstrap'

const mapStateToProps = (state, props) => {
    return {
        combo_enabled: state.viewUI.comboEnabled,
    }
}

class ComboCheckbox extends Component {
    render() {
        const { toggleCombo, combo_enabled } = this.props
        return (
            <FormGroup>
                <Checkbox onClick={() => toggleCombo()} checked={combo_enabled} readOnly>
                    Combo
                </Checkbox>
            </FormGroup>
        );
    }
}

export default connect(
    mapStateToProps, {
    toggleCombo
}
)(ComboCheckbox);