import { createSelector } from "reselect";

import * as CurrencyMath from '../utils/currency';
import { getItemGroup, getMenuGroupMembers, getMenuGroups, getMenuItems } from "./menu";


export function getLocationBalanceStats(state) {
  return state.Stats.locationBalanceStats;
}

export const getLocationBalanceTotals = createSelector(
  [getLocationBalanceStats,],
  (data,) => {
    const totals = {
      deliveryCount: 0,
      amountTotal: 0.00,
    };

    if (data == null) {
      return totals;
    }

    data.stats.forEach((item) => {
      totals.deliveryCount += item.delivery_count;
      // we're doing currency arithmetics which means we always have 00 decimal digits
      totals.amountTotal = CurrencyMath.add(totals.amountTotal, item.total, item.total_cc);
    });


    return totals;
  }
);

export function getDeliveryMenStats(state) {
  return state.Stats.deliveryMenStats;
}

export const getDeliveryMenStatsTotals = createSelector(
  [getDeliveryMenStats,],
  (data,) => {
    const totals = {};

    // TODO: data.stats is an object when empty and array when not!?!?
    if (data == null || data.stats.length == null) {
      return totals;
    }

    data.stats.forEach((item) => {
      const itemTotals = {
        amountTotal: 0.00,
        amountCcTotal: 0.00,
        amountCashTotal: 0.00,
      };
      totals[item.id] = itemTotals;

      item.locations.forEach((statsLocation) => {
        statsLocation.addresses.forEach((address) => {
          if (address.canceled == null) {
            return;
          }

          // we're doing currency arithmetics which means we always have 00 decimal digits
          itemTotals.amountTotal = CurrencyMath.add(itemTotals.amountTotal, address.amount);
          if (address.cc_payment) {
            itemTotals.amountCcTotal = CurrencyMath.add(itemTotals.amountCcTotal, address.amount);
          }
          else {
            itemTotals.amountCashTotal = CurrencyMath.add(itemTotals.amountCashTotal, address.amount);
          }
        });
      });
    });


    return totals;
  }
);

export function getItemsSoldStats(state) {
  return state.Stats.itemsSoldStats;
}

/** Special/uknonw group ID. For changed/deleted items which are removed from all groups but still have been sold at one time or another. */
export const UNKNOWN_GROUP_ID = null;

/**
 * Prepare stats for displaying in items sold list.
 * 
 * Sold items and their stats are grouped by their group (only the first group is taken so no multigroup items).
 * For each group item count and total amount are calculated.
 * If item does not belong to any group then it's most probably changed/deleted item and those items are grouped 
 * under a special gorup with ID `null`.
 * 
 * Output format:
 * {
 *   [groupId]: { menuGroup, menuItemCount, totalAmount, stats: [{ menuItem, itemStat, totalAmount }]}
 * }
*/
export const getGroupItemsSold = createSelector(
  [getItemsSoldStats, getMenuItems, getMenuGroups, getMenuGroupMembers],
  (itemsSoldStats, items, groups, groupMembers) => {
    // default value for all these maps is an empty object so to check if they have been fetched we can only check for value count
    if (itemsSoldStats == null || Object.values(itemsSoldStats).length === 0
      || items == null || Object.values(items).length === 0
      || groups == null || Object.values(groups).length === 0
      || groupMembers == null || Object.values(groupMembers).length === 0) {
      return {}
    }

    return (itemsSoldStats.stats).reduce((accum, itemStat) => {
      const menuGroup = getItemGroup(itemStat.menu_item_id, groups, groupMembers);
      const menuGroupId = menuGroup != null ? menuGroup.id : UNKNOWN_GROUP_ID;

      const menuItemId = itemStat.menu_item_id;
      const menuItem = items[menuItemId];
      if (menuItem == null) {
        console.error(`Unknown item ID ${menuItemId}`);
      }
      else {
        if (accum[menuGroupId] == null) {
          accum[menuGroupId] = { menuGroup, menuItemCount: 0, totalAmount: 0.0, stats: [] };
        }

        const totalItemPrice = CurrencyMath.multiply(itemStat.count, menuItem.price);
        accum[menuGroupId].menuItemCount += itemStat.count;
        // don't add gratis items to total sum
        if (!itemStat.gratis) {
          accum[menuGroupId].totalAmount = CurrencyMath.add(accum[menuGroupId].totalAmount, totalItemPrice);
        }
        accum[menuGroupId].stats.push({ menuItem, itemStat, totalAmount: totalItemPrice });
      }

      return accum;
    }, {});
  }
);
