import React from 'react'
import { Grid, Row, Col, Table, Glyphicon } from 'react-bootstrap';

class HandoffView extends React.Component {

	render() {
		return (
			<Grid>
				<Row>
					<Col sm={5}>
						<h3>Dostavljač</h3>
						<h4>{this.props.deliveryman ? this.props.deliveryman.name : ' - '}</h4>
					</Col>
					<Col sm={3}>
						<h3>Vozilo</h3>
						<h4>{this.props.deliveryman ? this.props.deliveryman.vehicle : ' - '}</h4>
					</Col>
					<Col sm={4}>
						<h3>Ukupno</h3>
						<h4>{this.props.deliveries ? this.deliveriesTotal() : ' - '}</h4>
					</Col>
				</Row>

				<Row><Col>&nbsp;</Col></Row>

				<Row>
					<Col sm={5}>
						<h3>Dostave</h3>

						<Table>
							<thead>
								<tr>
									<th>#</th>
									<th>Adresa</th>
									<th>Cijena</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{this.props.deliveries.map(delivery => (
									<tr key={delivery.id}>
										<td>{delivery.id}</td>
										<td>{delivery.customer_address && delivery.customer_address.address}</td>
										<td>{delivery.amount}</td>
										<td>
											<a href="" onClick={(event) => this.handleRemoveButtonClick(event, delivery.id)}>
												<Glyphicon glyph="remove" />
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Col>
					<Col sm={7}>
						<h3>Karta</h3>

						<div>TODO</div>
					</Col>
				</Row>
			</Grid>
		);
	}

	handleRemoveButtonClick = (event, deliveryId) => {
		event.preventDefault();

		if (this.props.onRemoveDelivery) {
			this.props.onRemoveDelivery(deliveryId);
		}
	}


	deliveriesTotal() {
		return (this.props.deliveries || []).reduce((accum, delivery) => {
			return accum + delivery.amount;
		}, 0);
	}

}

export default HandoffView;
