import _ from 'lodash';

import { 
    PUSH_MENU_GROUP_GROUP_COMPLEMENT,
    DELETE_MENU_GROUP_GROUP_COMPLEMENT,
    GET_MENU_GROUP_GROUP_COMPLEMENT, } from '../../actions/websocket';


export default function(state = {}, action) {
  
  switch (action.type) {
    case GET_MENU_GROUP_GROUP_COMPLEMENT:
        return _.reduce(action.payload.data, function (result, kv) {
            (result[kv.menu_group_id] || (result[kv.menu_group_id] = [])).push(kv.complement_menu_group_id);
            return result;
        }, {});

    case DELETE_MENU_GROUP_GROUP_COMPLEMENT:
        var { menu_group_id, complement_menu_group_id } = action.payload.data
        if (menu_group_id in state) {
            if (state[menu_group_id].includes(complement_menu_group_id)) {
                const idx = state[menu_group_id].indexOf(complement_menu_group_id);
                return {
                    ...state,
                    [menu_group_id]: [
                        ...state[menu_group_id].slice(0, idx),
                        ...state[menu_group_id].slice(idx + 1)
                    ]
                };
            }
        }
        return state;
        
    case PUSH_MENU_GROUP_GROUP_COMPLEMENT:
        var { menu_group_id, complement_menu_group_id } = action.payload.data
        if (menu_group_id in state) {
            if (state[menu_group_id].includes(complement_menu_group_id)) {
                var updatedGroup = state[menu_group_id]
            } else {
                var updatedGroup = [...state[menu_group_id], complement_menu_group_id]
            }
        } else {
            var updatedGroup = [complement_menu_group_id];
        }
        return {
            ...state,
            [menu_group_id]: updatedGroup
        }

    default:
        return state;
  }
}