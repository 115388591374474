import React from 'react'
import CartItemsUI from './CartItemsUI';

export const UngroupedCartItemsUI = (props) => {
	const {
		cart_items,
		cart_part_missing_items_count,
		combo_enabled } = props
	const {
		selected_item,
		select_action,
		remove_item,
		remove_addon
	} = props

	return (
		<div className="hcf-cart__ungroupedItems">
			<CartItemsUI
				cart_items={cart_items}
				selected_item={selected_item}
				select_action={select_action}
				remove_item={remove_item}
				remove_addon={remove_addon}
				combo_enabled={combo_enabled}
				ungrouped />

			<div className="hcf-cart__ungroupedItemsInfo">Nedostaje dijelova: {cart_part_missing_items_count}</div>
		</div>

	);
};
