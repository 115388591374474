import moment from 'moment'
import { UI_VIEW_CALL_RECORDS_FROM, UI_VIEW_DELIVERIES_FROM } from '../actions/UI'
import { GET_DELIVERY_DATE, GET_CALL_DATE } from '../actions/websocket'
import { socket } from './websocket'

const uiViewCallRecords = ({ dispatch }) => next => action => {
    next(action)
    switch (action.type) {
        case UI_VIEW_CALL_RECORDS_FROM:
            /**
             *  UI side effect
             *  when user changes selected date in Call Record view we want to
             *  emit WS event which will fetch Call records for given date (target_date)
             */
            const target_date = action.payload.format('YYYY-MM-DD');
            socket.emit(GET_CALL_DATE, target_date, (data) => {
                if (data) {
                    dispatch({
                        type: GET_CALL_DATE,
                        payload: {
                            data: data.payload
                        }
                    })
                } else {
                    console.error("Got back response with no data when fetching calls for date ", target_date)
                }
            })
            break
    }
}

const uiViewDeliveries = ({ dispatch }) => next => action => {
    next(action)
    switch (action.type) {
        case UI_VIEW_DELIVERIES_FROM:
            /**
             *  UI side effect
             *  when user changes selected date in Deliveries view we want to
             *  emit WS event which will fetch Delivery records for given date (target_date)
             *  
             */
            const target_date = action.payload.format('YYYY-MM-DD');
            socket.emit(GET_DELIVERY_DATE, target_date, (data) => {
                if (data) {
                    dispatch({
                        type: GET_DELIVERY_DATE,
                        payload: {
                            data: JSON.parse(data.payload)
                        }
                    })
                } else {
                    console.error("Got back response with no data when fetching deliveries for date ", target_date)
                }
            })
            break
    }
}

export const uiMdl = [uiViewCallRecords, uiViewDeliveries,];
