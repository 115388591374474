import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toggleGratis } from '../../actions/UI'
import { Checkbox, FormGroup } from 'react-bootstrap'

const mapStateToProps = (state, props) => {
    return {
        gratisEnabled: state.viewUI.gratisEnabled,
    }
}

class GratisOption extends Component {
    render() {
        const { toggleGratis, gratisEnabled } = this.props
        return (
            <FormGroup>
                <h6>GRATIS:</h6>
                <Checkbox onClick={() => toggleGratis()} checked={gratisEnabled} readOnly>
                    Gratis
                </Checkbox>
            </FormGroup>
        );
    }
}

export default connect(
    mapStateToProps, {
    toggleGratis
}
)(GratisOption);