import React from 'react'
import _ from 'lodash'
import { Col, Glyphicon, Panel, Row, Table } from 'react-bootstrap';


class ItemsSoldStats extends React.Component {
    state = {
        openedPanels: {}
    };

    render() {
        const { data } = this.props;

        const dataValues = Object.values(data)
        return (
            <div>
                <br />
                {dataValues.map((item) => {
                    const groupName = item.menuGroup != null ? item.menuGroup.name : 'Izmijenjene stavke koje vise nisu u ponudi'
                    const groupId = item.menuGroup != null ? item.menuGroup.id : 'GROUPLESS_MENU_ITEMS_ID'

                    return (
                        <Panel key={groupId} eventKey={groupId} onToggle={(opened) => this.handlePanelToggle(groupId, opened)}>
                            <Panel.Heading>
                                <Panel.Title toggle>
                                    <Row>
                                        <Col xs={8}>
                                            {this.state.openedPanels[groupId]
                                                ? (
                                                    <a><Glyphicon glyph="menu-up" /></a>
                                                ) : (
                                                    <a><Glyphicon glyph="menu-down" /></a>
                                                )
                                            }

                                            &nbsp;&nbsp;
                                            {groupName}
                                        </Col>
                                        <Col xs={4} className="text-right">
                                            {item.menuItemCount} ({item.totalAmount.toFixed(2)} Kn)
                                        </Col>
                                    </Row>
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    <Table responsive striped condensed hover>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '60%' }}>Naziv</th>
                                                <th className="text-center" style={{ width: '20%' }}>Kol.</th>
                                                <th className="text-center" style={{ width: '20%' }}>Cijena</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item.stats.map((stat) => (
                                                <tr key={`${stat.menuItem.id}-${stat.itemStat.gratis}`}>
                                                    <td>{stat.menuItem.name} {stat.itemStat.gratis ? `(BESPLATNO)` : ''}</td>
                                                    <td className="text-center">{stat.itemStat.count}</td>
                                                    <td className="text-center">{stat.menuItem.price.toFixed(2)} Kn</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel>
                    )
                })}

                {dataValues.length === 0 && (
                    <p className="text-center">
                        <br /><br />
                        U zadanom razdoblju nije pronađena niti jedna prodana stavka.
                    </p>
                )}
            </div >
        );
    }

    handlePanelToggle(key, opened) {
        this.setState({
            openedPanels: {
                ...this.state.openedPanels,
                [key]: opened
            }
        });
    }
}

export default ItemsSoldStats
