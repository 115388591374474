/* eslint camelcase: 0, no-underscore-dangle: 0 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, FormControl, FormGroup, ControlLabel, Input } from 'react-bootstrap';

import * as actionCreators from '../actions/auth';
import { validateEmail } from '../utils/misc';

function mapStateToProps(state) {
    return {
        isAuthenticating: state.auth.isAuthenticating,
        statusText: state.auth.statusText,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}


const style = {
    marginTop: 50,
    paddingBottom: 50,
    paddingTop: 25,
    width: '100%',
    textAlign: 'center',
    display: 'inline-block',
};


class LoginView extends Component {

    constructor(props) {
        super(props);
        const redirectRoute = '/login';
        this.state = {
            email: '',
            password: '',
            email_error_text: null,
            password_error_text: null,
            redirectTo: redirectRoute,
            disabled: true,
        };
    }

    isDisabled() {
        let email_is_valid = false;
        let password_is_valid = false;

        if (this.state.email === '') {
            this.setState({
                email_error_text: null,
            });
        } else if (validateEmail(this.state.email)) {
            email_is_valid = true;
            this.setState({
                email_error_text: null,
            });

        } else {
            this.setState({
                email_error_text: 'E-mail adresa nije ispravna',
            });
        }

        if (this.state.password === '' || !this.state.password) {
            this.setState({
                password_error_text: null,
            });
        } else if (this.state.password.length >= 6) {
            password_is_valid = true;
            this.setState({
                password_error_text: null,
            });
        } else {
            this.setState({
                password_error_text: 'Lozinka mora biti minimalno 6 znakova duga',
            });

        }

        if (email_is_valid && password_is_valid) {
            this.setState({
                disabled: false,
            });
        }

    }

    changeValue(e, type) {
        const value = e.target.value;
        const next_state = {};
        next_state[type] = value;
        this.setState(next_state, () => {
            this.isDisabled();
        });
    }

    _handleKeyPress(e) {
        if (e.key === 'Enter') {
            if (!this.state.disabled) {
                this.login(e);
            }
        }
    }

    login(e) {
        e.preventDefault();
        this.props.loginUser(this.state.email, this.state.password, this.state.redirectTo);
    }

    render() { 
        return (
            <div className="col-md-6 col-md-offset-3" onKeyPress={(e) => this._handleKeyPress(e)}>
                <form role="form">
                    <div className="text-center">
                    <h2>HalloChef prijava korisnika</h2>
                            {
                                this.props.statusText &&
                                    <div className="alert alert-info">
                                        {this.props.statusText}
                                    </div>
                            }

                        <div className="col-md-12">
                            <FormGroup>
                                <ControlLabel htmlFor="input_email">E-mail</ControlLabel>
                                <FormControl
                                    id="input_email"
                                    placeholder="Email"
                                    type="email"
                                   
                                    onChange={(e) => this.changeValue(e, 'email')}
                                />
                            </FormGroup>
                        </div>
                        
                        <div className="col-md-12">
                                <FormGroup>
                                    <ControlLabel htmlFor="input_password">Lozinka</ControlLabel>
                                    <FormControl
                                    id="input_password"
                                    placeholder="Lozinka"
                                    type="password"
                                    
                                    onChange={(e) => this.changeValue(e, 'password')}
                                    />
                                </FormGroup>
                        </div>
                        <div>
                            <FormGroup>
                                <Button
                                    id="submit_button"
                                    type="submit"
                                    style={{ marginTop: 50 }}
                                    onClick={(e) => this.login(e)}
                                    color="primary"
                                >Prijava</Button>
                            </FormGroup>
                        </div>
                        
                    </div>
                </form>
            </div>
        );
    }

}

LoginView.propTypes = {
    loginUser: PropTypes.func,
    statusText: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)