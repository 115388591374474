import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { getCallRecordsForToday } from "../../selectors/calls";
import { removeMessage, reportMessage, MESSAGE_SEVERITY } from "../../actions/feedback"


/** How far in history should we search for unanswered calls. */
const UNANSWERED_CALL_PERIOD = 30*60*1000; // in millis
/** How often should we check if calls are still inside period. */
const UNANSWERED_CALL_CHECK_INTERVAL = 60*1000; // in millis

const UNANSWERED_CALL_CHECK_MESSAGE_ID = 'FEEDBACK@UNANSWERED_CALL_CHECK_MESSAGE_ID';


/**
 * Component in charge of monitoring for unanswered calls.
 * 
 * This should have probably implemented as a redux middleware but here we can use React's Redux store update system
 * which makes this check much easier.
 */
class UnansweredCallsWatchdog extends React.Component {
	state = {
		tickCounter: 0, // makes component update every check interval
		lastCallUnanswered: false,
		periodCallsUnanswered: false,
	};

	checkInterval = null;

	componentDidMount() {
		this.updateFromStore();

		this.setupCheckInterval();
	}

	componentWillUnmount() {
		this.clearCheckInterval();
	}

	componentDidUpdate(prevProps, prevState) {
		// update message on tick or when calls list has changed
		if (this.state.tickCounter !== prevState.tickCounter || this.props.callRecords !== prevProps.callRecords) {
			this.updateFromStore();
		}

		// has ANY of messages changed?
		if (this.state.lastCallUnanswered !== prevState.lastCallUnanswered || this.state.periodCallsUnanswered !== prevState.periodCallsUnanswered) {
			// last call has precendance
			if (this.state.lastCallUnanswered) {
				this.showLastUnansweredCallMessage();
			}
			// then period calls
			else if (this.state.periodCallsUnanswered) {
				this.showPeriodUnansweredCallMessage();
			}
			// otherwise, cleanup
			else {
				this.hidePeriodUnansweredCallMessage();
			}
		}
	}

	// component renders nothing!
	render() {
		return null;
	}

	showLastUnansweredCallMessage() {
		this.props.reportMessage({
			id: UNANSWERED_CALL_CHECK_MESSAGE_ID,
			message: 'Propustili ste poziv jer telefoni nisu spojeni na internet',
			severity: MESSAGE_SEVERITY.ERROR,
			autoRemove: false,
		});
	}

	showPeriodUnansweredCallMessage() {
		this.props.reportMessage({
			id: UNANSWERED_CALL_CHECK_MESSAGE_ID,
			message: `U proteklih ${UNANSWERED_CALL_PERIOD / (60*1000)} minuta propustili ste poziv jer telefoni nisu bili spojeni na internet`,
			severity: MESSAGE_SEVERITY.WARNING,
			autoRemove: false,
		});
	}

	hidePeriodUnansweredCallMessage() {
		this.props.removeMessage(UNANSWERED_CALL_CHECK_MESSAGE_ID);
	}

	updateFromStore() {
		const periodStart = moment().subtract(UNANSWERED_CALL_PERIOD, 'ms');
	
		// filter calls that started inside defined check period
		const newerCallRecords = this.filterCallRecordsNewerThan(this.props.callRecords || {}, periodStart);
	
		const lastCallUnanswered = newerCallRecords
			.filter(this.isCallFinished) // take only finished calls
			.slice(0, 1) // take the last one
			.filter(this.isCallUnanswered).length > 0; // do we have any?

		const periodCallsUnanswered = newerCallRecords
			.filter(this.isCallFinished) // take only finished calls
			.filter(this.isCallUnanswered).length > 0; // do we have any

		this.setState({
			lastCallUnanswered,
			periodCallsUnanswered,
		});
	}

	setupCheckInterval() {
		this.checkInterval = setInterval(() => {
			this.setState({
				tickCounter: this.state.tickCounter + 1
			});
		}, UNANSWERED_CALL_CHECK_INTERVAL);
	}

	clearCheckInterval() {
		if (this.checkInterval != null) {
			clearInterval(this.checkInterval);
			this.checkInterval = null;
		}
	}

	/** Filter records and return only those incoming between given time moments. */
	filterCallRecordsNewerThan(callRecords, startTime) {
		return _.filter(callRecords, record => {
			const isAfter = moment(record.incoming_at).isAfter(startTime);
			return isAfter;
		})
	}

	/** Check if call is unanswered */
	isCallUnanswered = (call) => {
		return call.agent_count === 0;
	}

	/** Check if call is finished */
	isCallFinished(call) {
		return call.finished_at != null;
	}
}


function mapStateToProps(state) {
	return {
		// take ALL todays calls
		callRecords: getCallRecordsForToday(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		removeMessage: (messageId) => dispatch(removeMessage(messageId)),
		reportMessage: (message) => dispatch(reportMessage(message))
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(UnansweredCallsWatchdog);
