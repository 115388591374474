import React from 'react'

// Containers
import HandoffContainer from '../../containers/HandoffContainer'

// UI Components
import { Grid, Row, Col } from 'react-bootstrap'


const HandoffRecordsView = () => {
	return (
		<Grid >
			<Row className="show-grid">
				<Col md={11} lg={12}>
					<HandoffContainer />
				</Col>
			</Row>
		</Grid>
	)
}

export default HandoffRecordsView