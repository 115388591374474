export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        //console.log('Loading state from storage : ', JSON.parse(serializedState))
        // Uncomment to enable saving state
        return undefined;
        return JSON.parse(serializedState)
    } catch(err) {
        return undefined;
    }
}

export const storeState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch(err) {
        console.warn('Failed to storeState to localStorage')
    }
}