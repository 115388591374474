import { createSelector } from 'reselect'
import _ from 'lodash'

// We need to figure out what is relevant item here, it was simple when we used MenuItem.id
// but since we now reference it by MenuItem.sort_id which is not key in redux we need to search
// through all items and find one that has itemSortID
const getMenuItemBySortID = (state, props) => {
    return _.find(state.MenuItems, function(obj) {
        return obj.sort_id == props.match.params.menu_item_sort_id
    });
}

const getGroups = (state, _) => state.MenuGroups
const getItemComplements = (state, _) => state.MenuItemGroupComplements


const getItemGroupComplements = createSelector(
    [getItemComplements, getGroups, getMenuItemBySortID], 
    (itemComplements, groups, relevantItem) => {

        if (typeof relevantItem !== 'undefined') {
            var itemComplementList = itemComplements[relevantItem.id]
        } else {
            return {}
        }

        return _.filter(
            groups,
            group => _.includes(itemComplementList, group.id)
        )
    }

)

export default getItemGroupComplements
