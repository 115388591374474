import React, { Component } from 'react'
import { 
    FormGroup,
    ControlLabel, 
    FormControl,
    Checkbox, 
    HelpBlock,
    Glyphicon
} from 'react-bootstrap'

export default class FieldInput extends Component {
    render () {
        const {
            feedbackIcon,
            input,
            label,
            type,
            glyphIcon,
            isChecked,
            defaultValue,
            inline,
            meta: { error, warning, touched },
            ...props
        } = this.props;

        let message;
        const validationState = touched && ( error && "error" ) || ( warning && "warning" ) || null;

        if ( touched && ( error || warning ) ) {
            message = <span className="help-block">{ error || warning }</span>;
        }
        const input_element = (type) => {
            switch(type) {
                case 'checkbox':
                    return (
                        <FormGroup validationState={ validationState }>
                            { glyphIcon ? 
                                <FormControl.Feedback>
                                    <Glyphicon glyph={glyphIcon} />
                                </FormControl.Feedback> : null
                            }
            
                            <Checkbox inline { ...input } type={type} { ...props }> 
                                { label }
                            </Checkbox>
            
                            { message }
                        </FormGroup>
                    )
                default:
                    return (
                        <FormGroup validationState={ validationState }>
                            { label ? <ControlLabel>{ label }</ControlLabel> : null }
                            { glyphIcon ? 
                                <FormControl.Feedback>
                                    <Glyphicon glyph={glyphIcon} />
                                </FormControl.Feedback> : null
                            }
            
                            <FormControl { ...input }
                                type={type}
                                { ...props }  />
            
                            { message }
                        </FormGroup>
                    )
            }
        }

        return input_element(type)
    }
}