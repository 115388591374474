import React from 'react';

import imagePathResolver from './imagePathResolver';
import * as AppConfig from '../common/config/appConfig';


// --
// ----- Component

const APP_BASE_PATH = '/';
const API_BASE_PATH = AppConfig.getValue('api.basePath');

/** Higher order component for injecting function for resolving in-app and app-to-api URLs (eg. to images or to API calls). */
const withPathResolver = (Component) =>
  class WithPath extends React.Component {
    render() {
      const TypedComponent = Component;
      return (
        <TypedComponent
          {...this.props}
          resolveImagePath={resolveImagePath}
          resolveApiPath={resolveApiPath}
        />
      );
    }
  };

function resolveImagePath(imageName) {
	return imagePathResolver(imageName);
}

function resolveApiPath(imagePath) {
	return `${APP_BASE_PATH}${API_BASE_PATH}${imagePath}`;
}

// ----- exports

export default withPathResolver;
