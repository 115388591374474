import React, { Component } from 'react';
import { connect } from 'react-redux';

import { selectDiscountValue } from '../actions/UI'
import { Form, FormGroup } from 'react-bootstrap'

const mapStateToProps = (state, props) => {
    return {
        discountList: [0, 5, 10, 15, 20, 50],
        selectedDiscountValue: state.viewUI.selectedDiscountValue,
    }
}

const DiscountSelector = (props) => {
    const { selectDiscountValue, discountList, selectedDiscountValue } = props

    const handleDiscountChange = React.useCallback((event) => {
        const value = parseInt(event.target.value);
        selectDiscountValue(value);
    });

    return (
        <FormGroup>
            <h6>POPUST:</h6>
            <select onChange={handleDiscountChange}>
                {discountList.map(discountValue => {
                    const discountName = discountValue === 0 ? 'nema popusta' : `${discountValue}% popusta`;

                    return <option value={discountValue}>{discountName}</option>
                })}
            </select>
        </FormGroup>
    );
}

export default connect(
    mapStateToProps, {
    selectDiscountValue
}
)(DiscountSelector);