import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

// Actions
import { createMenuGroup } from '../../actions/menu/group'

// UI components
import { Grid, Row, Col, Panel, } from 'react-bootstrap'

// Form components
import MenuGroupForm from '../../components/Form/MenuGroupForm'

class MenuGroupCreate extends Component {
    render() {
        const { createMenuGroup } = this.props

        const initData = { 
            "active": true, 
            "base": true,
        }
        
        return (
            <Grid>
                <Row>
                    <Col sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Nova menu grupa</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <MenuGroupForm initData={initData} submit_action={createMenuGroup} />
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default (connect(null, { createMenuGroup })(MenuGroupCreate));
