import React from 'react'
import { Tabs, Tab } from 'react-bootstrap';

import HandoffView from './HandoffView';


export class HandoffLocationTabs extends React.Component {
	render() {
		return (
			<Tabs
				activeKey={this.props.selectedLocationId && this.props.selectedLocationId}
				id="handoff-location-tabs"
				onSelect={this.handleLocationTabChange}
			>
				{(this.props.locations || []).map((location) => (
					<Tab eventKey={location.id} title={location.name} key={location.id} animation={false}>
						<HandoffView
							location={location}
							deliveryman={this.props.deliveryman}
							deliveries={this.props.deliveries}
							onRemoveDelivery={this.handleRemoveDelivery}
						/>
					</Tab>
				))}

			</Tabs>
		);
	}

	handleRemoveDelivery = (deliveryId) => {
		if (this.props.onRemoveDelivery) {
			this.props.onRemoveDelivery(deliveryId);
		}
	}

	handleLocationTabChange = (tabKey) => {
		if (this.props.onLocationChange) {
			this.props.onLocationChange(this.props.locations.find(location => location.id == tabKey));
		}
	}
}
