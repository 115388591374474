
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';

import FieldInput from './FieldInput'


const validate = values => {
    const errors = {}
    if(!values.name)  {
        errors.name = 'Unesite naziv grupe'
    }
    return errors
}

class MenuGroupForm extends Component {
    componentDidMount() {
        this.handleInitialize()
    }

    handleInitialize(providedValues = null) {
        const { initData } = this.props;
        const form_data = providedValues || initData
        this.props.initialize(form_data);
    }

    onSubmit(values) {
        const { parent_target } = this.props;
        if (parent_target === undefined) {
            this.props.submit_action(values)
            this.props.reset()
            if ('id' in values) {
                this.handleInitialize(values)
            }
            else {
                // no parent, go back to list
                this.props.history.push('/menu/group/list');
            }
        } else {
            /* 
                Block handles action which will include reference to existing record
                e.g. Create MenuGroup and add MenuGroup complement connection to parent_target MenuGroup
             */
            this.props.submit_action(values, parent_target.id)
            this.props.reset()
            if ('id' in values) {
                this.handleInitialize(values)
            }
            else {
                // go back to parent
                this.props.history.push(`/menu/group/edit/${parent_target.id}`);
            }
        }
    }
    

    render() {
        const { handleSubmit, pristine, submitting } = this.props
        return (
            <form onSubmit={handleSubmit(this.onSubmit.bind(this))} >
                <Field component={FieldInput} name="name"
                        label="Naziv grupe"
                        type="string"
                        glyphIcon="home"/>
                <Field component={FieldInput} name="description" 
                        label="Opis grupe:"
                        type="string" 
                        glyphIcon="home"/>
                <Field component={FieldInput} name="base" 
                        label="Osnovna grupa"
                        type="checkbox" />
                <Field component={FieldInput} name="multi" 
                        label="Izbor više stavki"
                        type="checkbox" />
                <Field component={FieldInput} name="active"
                        label="Aktivno"
                        type="checkbox" />
                
                <Field component={FieldInput} name="price_algorithm_max"
                        label="Računaj cijenu najskupljeg dijela"
                        type="checkbox" />
                <Field component={FieldInput} name="visible_online"
                        label="Vidljivo online"
                        type="checkbox" />
                <Field component={FieldInput} name="number_of_pieces"
                        parse={value => Number(value)}
                        label="Broj dijelova:"
                        type="number" />

                <Field component={FieldInput} name="img_url"
                        label="URL slike:" 
                        type="string"
                        glyphIcon="home"/>
                <Field component={FieldInput} name="default_item_id"
                        parse={value => Number(value)}
                        label="Default ID:" 
                        type="number"
                        glyphIcon="home"/>

                <Button 
                    type="button" 
                    disabled={submitting} 
                    onClick={this.handleFormCancel}
                    bsStyle="info"
                > PONIŠTI </Button>
                <Button type="submit"
                    disabled={pristine || submitting} 
                    bsStyle="success"
                > {this.props.initData.id ? 'SNIMI' : 'DODAJ'} </Button>
            </form>
        )
    }

    handleFormCancel = (e) => {
        e.preventDefault();

        this.props.reset();
        // go back only if we're editing existing group - just seams more logical
        if (this.isNewGroup()) {
            this.props.history.goBack();
        }
    }

    isNewGroup = () => {
        return this.props.initData == null || this.props.initData.id == null;
    }
}

export default reduxForm({
    form: 'menu_group_form',
    destroyOnUnmount: false,
    validate
})(withRouter(MenuGroupForm));
