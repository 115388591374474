import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// UI components
import { Table, Glyphicon } from 'react-bootstrap'
import MenuItemUI from './MenuItemUI'

const MenuItemList = props => {
    if (props.items === undefined) {
        return 'Loading...'
    } 

    const { items } = props;
    items.sort(function(item1, item2) {
        return item1.sort_id - item2.sort_id
    })
    const item_list = _.map(items, item => {
            return <Link  key={item.id} to={`/menu/item/edit/${item.sort_id}`}>
                        <div>
                            {item.name}
                        </div>
                    </Link>
    })

    return (<ul>
            {item_list}
        </ul>)
}

MenuItemList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired, 
        short_name: PropTypes.string,
        description: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        active: PropTypes.bool.isRequired,
        combo: PropTypes.bool.isRequired,
        option: PropTypes.bool.isRequired,
        visible_online: PropTypes.bool.isRequired,
        ui_color: PropTypes.number.isRequired,
    })).isRequired,
}

export default MenuItemList