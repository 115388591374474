import moment from 'moment';
import _ from 'lodash'

import { getDeliveries } from "./delivery";
import { getCheckins } from "./checkin";
import { getDeliveryman } from './deliveryman';


/** Return stored selected handoff location. */
export function getHandoffSelectedLocation(state) {
	return state.viewUI.handoffLocation
}

/** Returns locations availbale in checkin map. */
export function getHandoffLocations(state) {
	const checkinMap = getCheckins(state);

	const chekinLocationIds = Object.values(checkinMap);
	return chekinLocationIds.map((checkin) => {
		return state.Locations[checkin.location];
	});
}

/** Return handoff current deliverman. */
export function getHandoffDeliveryman(state) {
	const locationId = getHandoffSelectedLocation(state);
	if (locationId == null) {
		return;
	}

	const checkin = getLocationCheckin(state, locationId);
	if (checkin == null) {
		return;
	}

	return getDeliveryman(state, checkin.deliveryman);
}

/**
 * Returns list of handoff deliveries for current location and it's current delivery man.
 * But only those with dispatch time AFTER checkin time.
 */
export function getHandoffDeliveries(state) {
	const locationId = getHandoffSelectedLocation(state);
	if (locationId == null) {
		return;
	}

	const checkin = getLocationCheckin(state, locationId);
	if (checkin == null) {
		return;
	}

	// TODO: check time format
	const checkinTime = moment(checkin.time);
	const deliverymanId = checkin.deliveryman;

	// TODO: filter by deliveryman date
	const deliveries = _.values(getDeliveries(state));
	return deliveries
		.filter((delivery) => {
			// on location
			return delivery.location.id === locationId
			// assigned to current deliverman
			&& delivery.deliveryman != null && delivery.deliveryman === deliverymanId
				// take deliveries checkout after chekin
				&& checkinTime.isBefore(moment(delivery.dispatched_at));
		})
		// sort by delivery time
		.sort((d1, d2) => d1.created_at >= d2.created_at ? 1 : -1);
}



// ----- private

function getLocationCheckin(state, locationId) {
	const checkinMap = getCheckins(state);

	return checkinMap[locationId];
}
