import React from 'react'
import { connect } from 'react-redux'

// Actions
import { loadNextCustomerAddress, loadPreviousCustomerAddress } from '../../actions/UI'

import { 
    Pager,
    Glyphicon
} from 'react-bootstrap'

class CustomerDataNavContainer extends React.Component {
    render() {
        const { address_count, address_index, loadNextCustomerAddress, loadPreviousCustomerAddress} = this.props;
        /*
            If count is less than 2, we don't render
         */
        if (address_count < 2) {
            return (
                <div>
                </div>
            )
        }
        var previous_disabled = false;
        var next_disabled = false;
        if (address_index == address_count) {
            var next_disabled = true;
        }
        if (address_index == 1) {
            var previous_disabled = true
        }
        return (
            <div>
                <Pager>
                    <Pager.Item previous disabled={previous_disabled} onClick={loadPreviousCustomerAddress} href="#">
                        <Glyphicon glyph="chevron-left" /> Prethodna adresa
                    </Pager.Item>
                    <Pager.Item next disabled={next_disabled} onClick={loadNextCustomerAddress} href="#">
                        Sljedeća adresa <Glyphicon glyph="chevron-right" />
                    </Pager.Item>
                </Pager>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        address_count: state.viewUI.loadedCustomerAddressCount,
        address_index: state.viewUI.loadedCustomerAddressIndex
    }
}

export default connect(mapStateToProps, {loadNextCustomerAddress, loadPreviousCustomerAddress})(CustomerDataNavContainer)