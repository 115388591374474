import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'

// UI components
import MenuItemUI from './MenuItemUI'
import { Table, Glyphicon } from 'react-bootstrap'

const MenuGroupTable = props => {
    if (props.groups === undefined || props.items === undefined ) {
        return 'Loading...'
    } 

    const { groups, items } = props;
    const table_body = _.map(groups, group => {
        const {
            id,
            name,
            description,
            base,
            multi,
            default_item_id
            } = group;
        return (
            
            <tr key={id}>
                <td>
                    <Link to={'/menu/group/edit/' + id}>
                        {name}
                    </Link>
                </td>
                <td className="text-center">{base ? <Glyphicon glyph="check" /> : null}</td>
                <td className="text-center">{multi ? <Glyphicon glyph="list-alt" /> : <Glyphicon glyph="record" />}</td>
                <td className="text-center">

                    {default_item_id ?
                        
                    <Link to={'/menu/item/edit/' + default_item_id}> 
                        <div>
                            {items[default_item_id].name}
                        </div>

                    </Link>
                        : 
                        console.warn('Missing name for item id ', default_item_id)
                        }
                </td>
            </tr>
            
        )
    })

    return (
        <Table striped bordered condensed hover responsive>
            <thead>
                <tr>
                    <th>Naziv grupe</th>
                    <th className="text-center">Osnovna grupa</th>
                    <th className="text-center">Odabir jedan ili više</th>
                    <th className="text-center">Default stavka</th>
                </tr>
            </thead>
            <tbody>
                {table_body}
            </tbody>
        </Table>
    );
}

PropTypes.MenuGroupTable = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired, 
        short_name: PropTypes.string,
        description: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        active: PropTypes.bool.isRequired,
        combo: PropTypes.bool.isRequired,
        option: PropTypes.bool.isRequired,
        visible_online: PropTypes.bool.isRequired,
        ui_color: PropTypes.number.isRequired,
    })).isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired, 
        description: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        multi: PropTypes.bool.isRequired,
        base: PropTypes.bool.isRequired,
        img_url: PropTypes.string.isRequired,
    })).isRequired,
}

export default MenuGroupTable