import { OPEN_MODAL, CLOSE_MODAL } from "../actions/modal"

 
export default function(state = {'showModal' : false }, action) {
    switch(action.type) {
        case OPEN_MODAL:
            return { 'showModal': true }
        case CLOSE_MODAL:
            return { 'showModal': false }
        default:
            return state;
    }
}