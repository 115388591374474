import { createSelector } from 'reselect'
import _ from 'lodash'

const getGroupComplementList = (state, props) => state.MenuGroupGroupComplements[props.match.params.menu_group_id]

const getGroups = (state, props) => state.MenuGroups

const getGroupGroupComplements = createSelector(
    [getGroupComplementList, getGroups], 
    (groupComplementList, groups) => {
        return _.filter(
            groups,
            group => _.includes(groupComplementList, group.id)
        )
    }

)

export default getGroupGroupComplements
