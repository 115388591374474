import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../actions/currentUser';
import HcfImage from './common/HcfImage';
import { getCurrentUser } from '../selectors/currentUser';

function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
        token: state.auth.token,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}


class ProtectedView extends Component {
    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const token = this.props.token;
        this.props.fetchCurrentUser(token);
    }

    render() {
        return (
            <div className="text-center">
                <p className="hcf-welcomePage__image">
                    <HcfImage path="hallochef.svg" />
                </p>
                <h3>Dobrodošli <span className="hcf-homePage__username">{this.props.currentUser && this.props.currentUser.email}</span>!</h3>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedView)