import _ from 'lodash';

import {
    PUSH_MENU_GROUP,
    GET_MENU_GROUP, } from '../../actions/websocket'

export default function(state = {}, action) {
  switch (action.type) {
    case PUSH_MENU_GROUP:
        return { ...state, [action.payload.data.id]: action.payload.data };

    case GET_MENU_GROUP:
        return _.mapKeys(action.payload.data, 'id');

    default:
        return state;
  }
}