export const UI_VIEW_CALL_RECORDS_FROM = "[UI] Call Records change date"

export const UI_VIEW_DELIVERIES_FROM = "[UI] Deliveries change date"

export const UI_VIEW_STORE_DELIVERY_ADDRESS_DISTANCE = "[UI] Store delivery address distance";
export const UI_VIEW_CLEAR_DELIVERY_ADDRESS_DISTANCE = "[UI] Clear delivery address distance";

export const UI_VISIBLE_COMPONENTS = "[UI] User Visible UI settings update"

export const UI_AGENT_HANDLING_CALL = "[UI] Received Call data for call in progress"
export const UI_AGENT_TALKING_WITH_CUSTOMER = "[UI] Received Customer data for call in progress"

export const UI_FETCH_CUSTOMER_DATA = "[WS] Get customer data"

export const UI_LOAD_CUSTOMER_DATA = "[UI] Load customer data"
export const UI_MODAL_LOAD_NEW = "[UI] Modal decided to load new data"

export const UI_OPEN_MODAL_CUSTOMER_DATA_LOAD = "[UI] Open modal with Customer data load question"
export const UI_CLOSE_MODAL_CUSTOMER_DATA_LOAD = "[UI] Close modal with Customer data load question"

export const UI_LOAD_NEXT_CUSTOMER_ADDRESS = "[UI] Load next Customer Address"
export const UI_LOAD_PREVIOUS_CUSTOMER_ADDRESS = "[UI] Load previous Customer Address"

export const UI_LOAD_CUSTOMER_ADDRESS_IDX = "[UI] Load customer address at index"
export const UI_LOADED_CUSTOMER_ADDRESS_COUNT = "[UI] Set loaded customer address count"
export const UI_RESET_CUSTOMER = "[UI] Reset Customer data in store"

export const UI_LOAD_ACTIVE_CALL_DATA = "[UI] Load active call+customer data"

export const UI_SELECT_ORDER_LOCATION = "[UI] Select order location";
export const UI_SELECT_DELIVERY_TYPE = "[UI] Select delivery type";
export const UI_SELECT_PAYMENT_TYPE = "[UI] Select payment type";
export const UI_SELECT_DISCOUNT_VALUE = "[UI] Select discount value";

export const UI_HANDOFF_LOCATION = "[UI] Store handoff selected location";

export const UI_TOGGLE_COMBO = "[UI] Toggle combo checkbox";
export const UI_TOGGLE_GRATIS = "[UI] Toggle gratis checkbox";
export const UI_STORE_ORDER_DELAY_TIME = "[UI] Store order delay";


export const PAYMENT_TYPE = {
	CACHE: 'cache',
	CC: 'cc',
};


export function viewUIcallRecordsFrom(date) {
	return {
		type: UI_VIEW_CALL_RECORDS_FROM,
		payload: date
	}
}

export function viewUIdeliveriesFrom(date) {
	return {
		type: UI_VIEW_DELIVERIES_FROM,
		payload: date
	}
}

export function openModalCustomerDataLoad() {
	return {
		type: UI_OPEN_MODAL_CUSTOMER_DATA_LOAD
	}
}

export function closeModalCustomerDataLoad() {
	return {
		type: UI_CLOSE_MODAL_CUSTOMER_DATA_LOAD
	}
}

export function fetchCustomerData() {
	return {
		type: UI_MODAL_LOAD_NEW
	}
}

export function loadNextCustomerAddress() {
	return {
		type: UI_LOAD_NEXT_CUSTOMER_ADDRESS
	}
}

export function loadPreviousCustomerAddress() {
	return {
		type: UI_LOAD_PREVIOUS_CUSTOMER_ADDRESS
	}
}

export function selectOrderLocation(data) {
	return {
		type: UI_SELECT_ORDER_LOCATION,
		payload: data
	}
}

export function selectDeliveryType(data) {
	return {
		type: UI_SELECT_DELIVERY_TYPE,
		payload: data
	}
}

export function selectPaymentType(data) {
	return {
		type: UI_SELECT_PAYMENT_TYPE,
		payload: data
	}
}

export function selectDiscountValue(data) {
	return {
		type: UI_SELECT_DISCOUNT_VALUE,
		payload: data
	}
}

export function storeHandoffLocation(data) {
	return {
		type: UI_HANDOFF_LOCATION,
		payload: data
	};
}

export function toggleCombo() {
	return {
		type: UI_TOGGLE_COMBO
	}
}

export function toggleGratis() {
	return {
		type: UI_TOGGLE_GRATIS
	}
}

export function storeOrderDelayTime(delayTime) {
	return {
		type: UI_STORE_ORDER_DELAY_TIME,
		payload: delayTime
	};
}
